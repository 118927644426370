<template>
  <v-menu
    ref="MenuData"
    v-model="MenuData"
    :close-on-content-click="false"
    :return-value.sync="giorno"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedGiorno"
        outlined
        hide-details
        class="centered-input text-h6 text-typo font-weight-bolder mb-0"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="giorno"
      no-title
      :color="color"
      scrollable
      locale="it-IT"
      :min="limited ? min : null"
      :max="limited ? max : null"
      @input="save"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="MenuData = false">
        Annulla
      </v-btn>
      <v-btn text color="primary" @click="$refs.MenuData.save(giorno)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDateIta } from "@/utilities/converters.js";
export default {
  name: "BaseDatePicker",
  data() {
    return {
      MenuData: false,
      giorno: this.date,
    };
  },
  computed: {
    formattedGiorno() {
      return this.giorno ? formatDateIta(this.giorno) : "";
    },
  },
  methods: {
    save() {
      this.$refs.MenuData.save(this.giorno);
      this.$emit("update", this.giorno);
    },
  },
  props: {
    label: {
      type: [String, null],
      default: "Data",
    },
    date: {
      type: [String, null],
      default: null,
    },
    limited: {
      type: [Boolean],
      default: false,
    },
    min: {
      type: [String, null],
      default: "2024-04-26",
    },
    max: {
      type: [String, null],
      default: "2024-05-03",
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    color: {
      type: [String, null],
      default: 'primary',
    },
  },
};
</script>
