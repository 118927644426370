<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <perfect-scrollbar>
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="title d-flex align-center mb-0">
            <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
              <span class="ms-2 font-weight-bold text-sm"
                >MEDIOLANUM WINNERS 2024</span
              >
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <hr
        class="horizontal mb-0"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
      />

      <v-list nav dense>
        <v-list-group
          :ripple="false"
          append-icon="mdi-chevron-down"
          class="pb-0 mx-2"
          active-class="item-active"
        >
          <template v-slot:activator>
            <v-avatar size="30" class="my-3 ms-2">
              {{ initials }}
            </v-avatar>
            <v-list-item-content>
              <v-list-item-subtitle
                class="ps-1 mb-0 font-weight-light white--text text-center text-caption text-uppercase"
              >
                {{ role }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover py-2"
            @click="navbarFixedToggle"
          >
            <v-list-item-icon class="me-2 align-center">
              <v-icon size="24" color="white">{{
                navbarFixed ? "mdi-pin-off" : "mdi-pin"
              }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="ms-2 ps-1">
              <v-list-item-title>{{
                navbarFixed ? "Sblocca bar" : "Blocca bar"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            link
            @click="logout"
            class="mb-1 no-default-hover py-2"
          >
            <v-list-item-icon class="me-2 align-center">
              <v-icon size="24" color="white">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ms-2 ps-1">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <hr
        class="horizontal mb-3"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
      />
      <v-list nav dense>
        <div v-if="isFinance">
          <v-list-group
            :ripple="false"
            v-for="item in itemsFinance"
            :key="item.title"
            v-model="item.active"
            append-icon="mdi-chevron-down"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <v-icon size="24" color="white">{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0"
              :class="child.items ? 'has-children' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              @click="closeFromMobile"
            >
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <span class="v-list-item-mini" v-text="child.prefix"></span>

                <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                  <v-list-item-title @click="listClose($event)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="mdi-chevron-down"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator>
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{
                                child.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child2 in child.items"
                      :ripple="false"
                      :key="child2.title"
                      :to="child2.link"
                      @click="listClose($event)"
                    >
                      <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                      ></span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          child2.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
        <v-list-group
          :ripple="false"
          v-for="item in itemsReports"
          :key="item.title"
          v-model="item.active"
          append-icon="mdi-chevron-down"
          class="pb-1 mx-2"
          active-class="item-active"
        >
          <template v-slot:activator>
            <v-list-item-icon class="me-2 align-center">
              <v-icon size="24" color="white">{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
            @click="closeFromMobile"
          >
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
              <span class="v-list-item-mini" v-text="child.prefix"></span>

              <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                <v-list-item-title @click="listClose($event)">{{
                  child.title
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                <v-list-group
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        append-icon="mdi-chevron-down"
                        active-class="item-active"
                        class="mb-0"
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="py-0">
                            <v-list-item-title>{{
                              child.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <span
                      class="v-list-item-mini"
                      v-text="child2.prefix"
                    ></span>
                    <v-list-item-content>
                      <v-list-item-title>{{ child2.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :ripple="false"
          v-for="item in itemsAdActivities"
          :key="item.title"
          v-model="item.active"
          append-icon="mdi-chevron-down"
          class="pb-1 mx-2"
          active-class="item-active"
        >
          <template v-slot:activator>
            <v-list-item-icon class="me-2 align-center">
              <v-icon size="24" color="white">{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
            @click="closeFromMobile"
          >
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
              <span class="v-list-item-mini" v-text="child.prefix"></span>

              <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                <v-list-item-title @click="listClose($event)">{{
                  child.title
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                <v-list-group
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        append-icon="mdi-chevron-down"
                        active-class="item-active"
                        class="mb-0"
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="py-0">
                            <v-list-item-title>{{
                              child.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <span
                      class="v-list-item-mini"
                      v-text="child2.prefix"
                    ></span>
                    <v-list-item-content>
                      <v-list-item-title>{{ child2.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
        <div v-if="false">
          <v-list-group
            :ripple="false"
            v-for="item in itemsMorePeople"
            :key="item.title"
            v-model="item.active"
            append-icon="mdi-chevron-down"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <v-icon size="24" color="white">{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0"
              :class="child.items ? 'has-children' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              @click="closeFromMobile"
            >
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <span class="v-list-item-mini" v-text="child.prefix"></span>

                <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                  <v-list-item-title @click="listClose($event)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="mdi-chevron-down"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator>
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{
                                child.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child2 in child.items"
                      :ripple="false"
                      :key="child2.title"
                      :to="child2.link"
                      @click="listClose($event)"
                    >
                      <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                      ></span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          child2.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-if="isFinance">
          <v-list-group
            :ripple="false"
            v-for="item in itemsRooming"
            :key="item.title"
            v-model="item.active"
            append-icon="mdi-chevron-down"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <v-icon size="24" color="white">{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0"
              :class="child.items ? 'has-children' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              @click="closeFromMobile"
            >
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <span class="v-list-item-mini" v-text="child.prefix"></span>

                <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                  <v-list-item-title @click="listClose($event)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="mdi-chevron-down"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator>
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{
                                child.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child2 in child.items"
                      :ripple="false"
                      :key="child2.title"
                      :to="child2.link"
                      @click="listClose($event)"
                    >
                      <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                      ></span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          child2.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-if="isFinance">
          <v-list-group
            :ripple="false"
            v-for="item in itemsFlights"
            :key="item.title"
            v-model="item.active"
            append-icon="mdi-chevron-down"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <v-icon size="24" color="white">{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0"
              :class="child.items ? 'has-children' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              @click="closeFromMobile"
            >
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <span class="v-list-item-mini" v-text="child.prefix"></span>

                <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                  <v-list-item-title @click="listClose($event)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="mdi-chevron-down"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator>
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{
                                child.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child2 in child.items"
                      :ripple="false"
                      :key="child2.title"
                      :to="child2.link"
                      @click="listClose($event)"
                    >
                      <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                      ></span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          child2.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-if="role === 'admin'">
          <v-list-group
            :ripple="false"
            v-for="item in itemsHotels"
            :key="item.title"
            v-model="item.active"
            append-icon="mdi-chevron-down"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <v-icon size="24" color="white">{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0"
              :class="child.items ? 'has-children' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              @click="closeFromMobile"
            >
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <span class="v-list-item-mini" v-text="child.prefix"></span>

                <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                  <v-list-item-title @click="listClose($event)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="mdi-chevron-down"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator>
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{
                                child.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child2 in child.items"
                      :ripple="false"
                      :key="child2.title"
                      :to="child2.link"
                      @click="listClose($event)"
                    >
                      <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                      ></span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          child2.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-if="role === 'admin'">
          <v-list-group
            :ripple="false"
            v-for="item in itemsSettings"
            :key="item.title"
            v-model="item.active"
            append-icon="mdi-chevron-down"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <v-icon size="24" color="white">{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0"
              :class="child.items ? 'has-children' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              @click="closeFromMobile"
            >
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <span class="v-list-item-mini" v-text="child.prefix"></span>

                <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                  <v-list-item-title @click="listClose($event)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                  <v-list-group
                    prepend-icon=""
                    :ripple="false"
                    sub-group
                    no-action
                    v-model="child.active"
                  >
                    <template v-slot:activator>
                      <v-list nav dense class="pa-0">
                        <v-list-group
                          :ripple="false"
                          append-icon="mdi-chevron-down"
                          active-class="item-active"
                          class="mb-0"
                        >
                          <template v-slot:activator>
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{
                                child.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-group>
                      </v-list>
                    </template>

                    <v-list-item
                      v-for="child2 in child.items"
                      :ripple="false"
                      :key="child2.title"
                      :to="child2.link"
                      @click="listClose($event)"
                    >
                      <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                      ></span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          child2.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
        <hr
          class="horizontal my-4"
          :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
        />
      </v-list>

      <v-card
        class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
        :style="`background-image: url(${require('@/assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
      >
        <span
          class="mask opacity-8 border-radius-lg"
          :class="`bg-gradient-` + sidebarColor"
        ></span>
      </v-card>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
    nominativo: {
      type: String,
      default: "nome",
    },
    initials: {
      type: String,
      default: "ND",
    },
    role: {
      type: String,
      default: "supervisor",
    },
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFinance() {
      return this.$store.getters["user/isFinance"];
    },
    isGiovanni() {
      return this.$store.getters["user/isGiovanni"];
    },
    itemsReports() {
      if (this.isFinance || this.role === "admin") {
        return [
          {
            action: "mdi-view-dashboard",
            active: this.isFinance
              ? this.role === "admin"
                ? true
                : false
              : true,
            title: "REPORTS",
            items: [
              {
                title: "Reports",
                prefix: "R",
                link: "/admin/reports",
              },
              {
                title: "People",
                prefix: "P",
                link: "/admin/people",
              },
              {
                title: "Downloads",
                prefix: "D",
                link: "/admin/stats",
              },
              {
                title: "Notifiche",
                prefix: "N",
                link: "/admin/notifiche",
              },
            ],
          },
        ];
      } else {
        return [
          {
            action: "mdi-view-dashboard",
            active: this.isFinance
              ? this.role === "admin"
                ? true
                : false
              : true,
            title: "REPORTS",
            items: [
              {
                title: "Reports",
                prefix: "R",
                link: "/admin/reports",
              },
              {
                title: "Downloads",
                prefix: "D",
                link: "/admin/stats",
              },
              {
                title: "Notifiche",
                prefix: "N",
                link: "/admin/notifiche",
              },
            ],
          },
        ];
      }
    },
    itemsFinance() {
      return [
        {
          action: "mdi-finance",
          active: this.isFinance
            ? this.role === "admin"
              ? false
              : true
            : false,
          title: "FINANCE",
          items: [
            {
              title: "Pagamenti carta",
              prefix: "P",
              link: "/admin/invialink",
            },
            {
              title: "Gestione pagamenti",
              prefix: "G",
              link: "/admin/gestionepagamenti",
            },
            {
              title: "Assicurazioni da rilasciare",
              prefix: "A",
              link: "/admin/stipulaassicurazione",
            },
            {
              title: "Downloads",
              prefix: "D",
              link: "/admin/financedload",
            },
          ],
        },
      ];
    },
    itemsAdActivities() {
      return this.role === "admin"
        ? [
            {
              action: "mdi-ticket",
              active: false,
              title: "ATTIVITÀ",
              items: [
                {
                  title: "Attività",
                  prefix: "H",
                  link: "/admin/activities",
                },
                {
                  title: "Agenda",
                  prefix: "A",
                  link: "/admin/calendar",
                },
                  {
                    title: "White Dinner",
                    prefix: "W",
                    link: "/admin/whitedinners",
                  },
                {
                  title: "Download",
                  prefix: "D",
                  link: "/admin/activitiesdload",
                },
                {
                  title: "Nuova",
                  prefix: "N",
                  link: "/admin/nuovaattivita",
                },
              ],
            },
          ]
        : [
            {
              action: "mdi-ticket",
              active: false,
              title: "ATTIVITÀ",
              items: [
                {
                  title: "Attività",
                  prefix: "H",
                  link: "/admin/activities",
                },
                {
                  title: "Agenda",
                  prefix: "A",
                  link: "/admin/calendar",
                },
                {
                  title: "White Dinner",
                  prefix: "W",
                  link: "/admin/whitedinners",
                },
                {
                  title: "Download",
                  prefix: "D",
                  link: "/admin/activitiesdload",
                },
              ],
            },
          ];
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    // itemsReports: [
    //   {
    //     action: "mdi-view-dashboard",
    //     active: true,
    //     title: "REPORTS",
    //     items: [
    //       {
    //         title: "Reports",
    //         prefix: "R",
    //         link: "/admin/reports",
    //       },
    //       {
    //         title: "People",
    //         prefix: "P",
    //         link: "/admin/people",
    //       },
    //     ],
    //   },
    // ],
    // itemsFinance: [
    //   {
    //     action: "mdi-finance",
    //     active: true,
    //     title: "FINANCE",
    //     items: [
    //       {
    //         title: "Pagamenti carta",
    //         prefix: "P",
    //         link: "/admin/invialink",
    //       },
    //       {
    //         title: "Gestione pagamenti",
    //         prefix: "G",
    //         link: "/admin/gestionepagamenti",
    //       },
    //     ],
    //   },
    // ],
    itemsRooming: [
      {
        action: "mdi-office-building-cog-outline",
        active: false,
        title: "ROOMING",
        items: [
          {
            title: "Rooming",
            prefix: "R",
            link: "/admin/rooming",
          },
        ],
      },
    ],
    itemsMorePeople: [
      {
        action: "mdi-account-group",
        active: false,
        title: "OTHER",
        items: [
          {
            title: "More People",
            prefix: "M",
            link: "/admin/morepeople",
          },
        ],
      },
    ],
    itemsFlights: [
      {
        action: "mdi-airplane",
        active: false,
        title: "FLIGHTS",
        items: [
          {
            title: "Voli",
            prefix: "V",
            link: "/admin/flights",
          },
        ],
      },
    ],
    itemsHotels: [
      {
        action: "mdi-office-building-cog-outline",
        active: false,
        title: "HOTELS",
        items: [
          {
            title: "Hotels",
            prefix: "H",
            link: "/admin/hotellerie",
          },
        ],
      },
    ],
    itemsSettings: [
      {
        action: "mdi-cog",
        active: false,
        title: "SETTINGS",
        items: [
          {
            title: "Winners",
            prefix: "W",
            link: "/admin/gestionewinners",
          },
          {
            title: "Settings",
            prefix: "S",
            link: "/admin/settings",
          },
          {
            title: "Quote",
            prefix: "Q",
            link: "/admin/setquote",
          },
          {
            title: "Testi",
            prefix: "T",
            link: "/admin/settesti",
          },
          {
            title: "Privilegi",
            prefix: "P",
            link: "/admin/privilegi",
          },
        ],
      },
    ],
    // itemsAdActivities: [
    //   {
    //     action: "mdi-ticket",
    //     active: false,
    //     title: "ATTIVITÀ",
    //     items: [
    //       {
    //         title: "Attività",
    //         prefix: "H",
    //         link: "/admin/activities",
    //       },
    //       {
    //         title: "Agenda",
    //         prefix: "A",
    //         link: "/admin/calendar",
    //       },
    //       {
    //         title: "Nuova",
    //         prefix: "N",
    //         link: "/admin/nuovaattivita",
    //       },
    //     ],
    //   },
    // ],
  }),
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      // this.$swal.fire({
      //   title: "A presto!",
      //   timer: 2000,
      //   timerProgressBar: true,
      // });
    },
    navbarFixedToggle() {
      this.$store.dispatch("userInterface/toggleEditorNavBarFixed");
    },
    closeFromMobile() {
      if (this.$vuetify.breakpoint.mobile) this.$emit("close-from-mobile");
      else return;
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>
<style scoped>
.ps {
  height: 100%;
}
</style>
