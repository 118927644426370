<template>
  <v-card class="rounded-0">
    <v-app-bar dense dark :class="tableBarColor">
      <v-app-bar-title class="font-weight-light text-h5"
        >{{ title }} - {{ items.length }}</v-app-bar-title
      >
      <div class="ml-8" v-if="download && ready">
        <xlsx-workbook>
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download :filename="calcDate + '.xlsx'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon large v-on="on" v-bind="attrs">
                  <v-icon size="30" :color="iconColor">mdi-download</v-icon>
                </v-btn>
              </template>
              <span>SCARICA XLSX {{ sottotitolo_scheda }}</span>
            </v-tooltip>
          </xlsx-download>
        </xlsx-workbook>
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        autocomplete="off"
        hide-details
        clearable
        color="white"
        outlined
        dense
        single-line
        placeholder="Cerca"
        v-model="search"
      >
        <template slot="prepend">
          <v-icon color="white" size="30">mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <span v-if="search" class="ml-4 text-body-1">{{ finds }} risultati</span>
      <v-spacer></v-spacer>
      <v-btn outlined tile class="mr-4" color="white" @click="chiudi">
        chiudi
      </v-btn>
    </v-app-bar>
    <v-data-table
      ref="datatablerooming"
      :headers="headers"
      :items="items"
      dense
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
      :height="height"
      :search="search"
      fixed-header
      :showGroupBy="showGroupBy"
    >
      <template v-slot:[`item.title`]="{ item }">
        <div :class="getTypeClass(item.title)">{{ item.title }}</div>
      </template>
      <template v-slot:[`item.hotel`]="{ item }">
        <v-select
          v-model="item.hotel"
          :items="hotels"
          chips
          @change="save(item.email, 'hotel', item.hotel)"
          dense
        >
          <template v-slot:selection="{ item }">
            <v-chip
              v-if="item"
              :color="
                item === 'Al Bandar'
                  ? 'primary'
                  : item === 'Al Husn'
                  ? 'secondary'
                  : 'grey'
              "
              label
              small
              class="white--text"
              >{{ item }}</v-chip
            >
          </template>
        </v-select>
      </template>
      <template v-slot:[`item.room_type`]="{ item }">
        <v-select
          v-model="item.room_type"
          :disabled="!item.hotel"
          :items="room_types[item.hotel]"
          item-value="value"
          item-text="title"
          @change="save(item.email, 'room_type', item.room_type)"
          dense
        >
        </v-select>
      </template>
      <template v-slot:[`item.room_count`]="{ item }">
        <v-select
          v-model="item.room_count"
          :disabled="!item.hotel || !item.room_type"
          :items="[1, 2, 3, 4]"
          @change="save(item.email, 'room_count', item.room_count)"
          dense
        ></v-select>
      </template>
      <template v-slot:[`item.remarks`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.remarks"
          large
          persistent
          @save="save(props.item.email, 'remarks', props.item.remarks)"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          <div>{{ props.item.remarks }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update remarks</div>
            <v-text-field
              v-model="props.item.remarks"
              :label="props.item.nome + ' ' + props.item.cognome"
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { calcDate } from "@/utilities/helpers.js";
export default {
  name: "RoomingFamiliesWorkTable",
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    showGroupBy: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    iconColor: {
      type: String,
      default: "white",
    },
    headers: {
      type: Array,
      default: () => [
        { text: "regional", value: "regional", groupable: true, divider: true },
        { text: "title", value: "title", groupable: true, divider: true },
        { text: "nomw", value: "nome", groupable: false, divider: true },
        { text: "cognome", value: "cognome", groupable: false, divider: true },
        { text: "pax", value: "pax", groupable: false, divider: true },
        { text: "adults", value: "adults", groupable: false, divider: true },
        { text: "kids", value: "kids", groupable: false, divider: true },
        {
          text: "children",
          value: "children",
          groupable: false,
          divider: true,
        },
        { text: "infants", value: "infants", groupable: false, divider: true },
        {
          text: "hotel",
          value: "hotel",
          groupable: true,
          divider: true,
          width: "150px",
        },
        {
          text: "room_type",
          value: "room_type",
          groupable: false,
          divider: true,
          width: "220px",
        },
        {
          text: "room_count",
          value: "room_count",
          groupable: false,
          divider: true,
        },
        {
          text: "room_number",
          value: "room_number",
          groupable: false,
          divider: true,
        },
        { text: "remarks", value: "remarks", groupable: false, divider: true },
        { text: "note", value: "note", groupable: false, divider: true },
        { text: "email", value: "email", groupable: false, divider: true },
        { text: "code", value: "code", groupable: false, divider: true },
      ],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tableBarColor: {
      type: String,
      default: "bg-gradient-info",
    },
    turno: {
      type: String,
      reqired: true,
    },
    ready: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    sheets: {
      type: Array,
      default: () => [
        {
          name: "sheet1",
          data: [],
        },
      ],
    },
  },
  computed: {
    height() {
      const height = "100vh";
      return `calc(${height} - 48px)`;
    },
    calcDate() {
      return calcDate(this.title);
    },
    sottotitolo_scheda() {
      return this.title.toLowerCase().replace(/ /g, "_");
    },
    finds() {
      return this.$refs.datatablerooming.$children[0].filteredItems.length;
    },
    hotels() {
      return ["Al Bandar", "Al Husn"];
    },
    counts() {
      return [1, 2, 3, 4];
    },
    roomingCount() {
      return this.$store.getters[`participants/roomingCount`][
        `roomingCount${this.turno}`
      ];
    },
    room_types() {
      let hotels = this.$store.getters[`settings/getHotels`];
      let obj = {};
      hotels.forEach((hotel) => {
        obj[hotel.nome] = hotel.rooms.map((room) => {
          let count =
            this.roomingCount[hotel.nome] === undefined
              ? 0
              : this.roomingCount[hotel.nome][room.cat] === undefined
              ? 0
              : this.roomingCount[hotel.nome][room.cat];
          return { title: room.cat + " " + count, value: room.cat };
        });
      });
      return obj;
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
    };
  },
  methods: {
    chiudi() {
      this.$emit("chiudi");
    },
    getTypeClass(title) {
      return title === "Wealth Advisor" ? "green white--text" : "";
    },
    save(email, field, value) {
      let payload = {
        email: email,
        content: { [field]: value },
      };
      this.$store.dispatch("participants/updateWinnerFromAdmin", payload);
      console.log("Dialog saved " + email + " - " + field + ": " + value);
    },
    cancel() {
      console.log("Dialog cancelled");
    },
    open() {
      console.log("Dialog opened");
    },
    close() {
      console.log("Dialog closed");
    },
  },
};
</script>
<style scoped>
.first-button-container {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 16px; */
}
</style>
