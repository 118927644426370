<template>
  <v-card :color="color" elevation="12" outlined dense>
    <v-card-title v-if="nameBar" dense>
      <v-app-bar absolute elevation="1" dense :color="barColor">
        <v-row align="center" no-gutters v-if="centerName">
          <v-col cols="12" justify="center" align="center">
            <p class="mb-0 text-h6 text-center font-weight-bold" :class="barTextColor">
              {{ name }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center" no-gutters v-else>
          <v-col cols="12" sm="6" justify="center" align="center">
            <p class="mb-0 text-h6 font-weight-bold" :class="barTextColor">
              {{ name }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" justify="center" align="center">
            <p class="mb-0" :class="barTextColor">{{ subtitle }}</p>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-card-title>
    <v-card-text class="pa-0 px-2" :class="innerClass">
      <perfect-scrollbar>
        <v-card flat outlined tile class="pt-4">
          <slot></slot>
        </v-card>
      </perfect-scrollbar>
    </v-card-text>
    <v-card-actions v-if="downBtns">
      <v-row no-gutters justify="center">
        <v-col cols="10" sm="6" justify="center" align="center">
          <base-btn
            v-if="annulla"
            :block="$vuetify.breakpoint.xs"
            outlined
            @click="abort"
          >
            {{ annullaTxt }}
          </base-btn>
        </v-col>
        <v-col
          cols="10"
          sm="6"
          justify="center"
          align="center"
          class="mt-6 mt-sm-0"
        >
          <base-btn v-if="salva" :block="$vuetify.breakpoint.xs" @click="save">
            {{ salvaTxt }}
          </base-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BaseDialogWrapper",
  components: {},
  provide: {
    heading: { align: "center" },
  },
  computed: {
    color() {
      return this.$vuetify.isDark ? "white" : "grey lighten-5";
    },
    contentTopMargin() {
      return this.nameBar ? "mt-8" : "mt-0";
    },
    innerClass() {
      return this.nameBar
        ? this.downBtns
          ? ""
          : "mb-2"
        : this.downBtns
        ? "mt-2"
        : "my-2";
    },
  },

  data: () => ({}),
  methods: {
    save() {
      this.$emit("salva");
    },
    abort() {
      this.$emit("annulla");
    },
    close() {
      this.$emit("chiudi");
    },
  },
  props: {
    titleBar: { type: Boolean, default: false },
    nameBar: { type: Boolean, default: false },
    titolo: { type: String, requred: true, default: "Titolo" },
    name: { type: String, requred: true, default: "Nome" },
    subtitle: { type: String, requred: true, default: "" },
    topBtns: { type: Boolean, default: false },
    downBtns: { type: Boolean, default: false },
    barColor: { type: String, default: "tramsparent" },
    barTextColor: { type: String, default: "primary--text" },
    topBtnsColor: { type: String, default: "white" },
    downBtnsColor: { type: String, default: "primary" },
    salva: { type: Boolean, default: false },
    annulla: { type: Boolean, default: false },
    leftChiudi: { type: Boolean, default: false },
    rightChiudi: { type: Boolean, default: false },
    topSalva: { type: Boolean, default: false },
    topAnnulla: { type: Boolean, default: false },
    topChiudi: { type: Boolean, default: false },
    salvaTxt: { type: String, default: "Salva" },
    annullaTxt: { type: String, default: "Annulla" },
    chiudiTxt: { type: String, default: "Chiudi" },
    chiudiColor: { type: String, default: "" },
    salvaColor: { type: String, default: "" },
    annullaColor: { type: String, default: "" },
    darkAnnulla: { type: Boolean, default: false },
    darkSalva: { type: Boolean, default: false },
    darkChiudi: { type: Boolean, default: false },
    centerName: { type: Boolean, default: false },
  },
};
</script>
<style scoped>
.ps {
  height: 100%;
}
</style>
