<template>
  <v-app-bar
    absolute
    :color="background"
    class="py-0 px-2 position-sticky"
    elevate-on-scroll
    elevation="1"
    app
  >
    <v-container fluid class="px-4">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center justify-space-between">
          <v-img
            :src="require(`@/assets/mediolanumwinners2024.png`)"
            max-width="196"
            contain
          ></v-img>
          <v-menu offset-y offset-x v-if="false">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-on="{ ...tooltip, ...menu }"
                    v-bind="attrs"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    elevation="0"
                    color="secondary"
                    class="ml-4"
                    ><v-icon color="white" size="24"
                      >mdi-restart-alert</v-icon
                    ></v-btn
                  >
                </template>
                <span>Resetta il tester</span>
              </v-tooltip>
            </template>
            <v-card color="secondary" class="pa-2">
              <v-list>
                <v-list-item @click="resetIscrizioni">
                  <v-list-item-title class="font-weight-bold"
                    >Elimina le iscrizioni</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="resetConfirmParty">
                  <v-list-item-title class="font-weight-bold"
                    >Annulla conferma partecipanti</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="resetConfirmViaggio">
                  <v-list-item-title class="font-weight-bold"
                    >Annulla conferma viaggio</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="resetTester">
                  <v-list-item-title class="font-weight-bold"
                    >Resetta il tester</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <div>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-tabs background-color="transparent" centered center-active>
              <v-tab
                v-for="item in links"
                :key="item.name"
                :to="item.link"
                :exact="item.exact"
                class="font-weight-bold px-0"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
          </template>
        </div>

        <div>
          <base-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            elevation="0"
            color="#fff"
            secondary
            min-width="100"
            @click="logout"
            class="text-h6"
          >
            Esci
          </base-btn>
          <v-btn
            v-show="$vuetify.breakpoint.smAndDown"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize drawer-toggler btn-toggler-hover py-3 px-6 rounded-sm"
            color="transparent"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i
                class="drawer-toggler-line"
                :class="{ 'bg-default': hasBg }"
              ></i>
              <i
                class="drawer-toggler-line"
                :class="{ 'bg-default': hasBg }"
              ></i>
              <i
                class="drawer-toggler-line"
                :class="{ 'bg-default': hasBg }"
              ></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                class="pa-0 card-padding text-caption font-weight-bold text-typo justify-space-between border-bottom"
              >
                MEDIOLANUM WINNERS 2024
                <v-btn icon @click="dialog = false">
                  <v-icon size="18" class="text-typo">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <div class="card-padding pa-0 d-flex flex-column">
                <router-link
                  v-for="item in links"
                  :key="item.name"
                  :to="item.link"
                  class="mb-4 text-decoration-none"
                >
                  <v-btn
                    :ripple="false"
                    outlined
                    primary
                    class="uppercase-text secondary--text text-h5"
                    block
                    large
                    color="secondary"
                    @click="dialog = false"
                  >
                    {{ item.label }}
                    <v-spacer></v-spacer>
                  </v-btn>
                </router-link>
                <v-divider color="secondary" class="mx-4"></v-divider>
                <div class="w-100 d-flex justify-end mt-4">
                  <v-btn
                    :ripple="false"
                    style="min-width: 100px"
                    class="uppercase-text text-right white--text text-h5"
                    large
                    color="secondary"
                    @click="logout"
                  >
                    Esci
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppBarUser",
  mounted() {
    // window.addEventListener("beforeunload", function (e) {
    //   // Cancel the event
    //   e.preventDefault();
    //   // Chrome requires returnValue to be set
    //   e.returnValue = "";
    // });
    // window.onbeforeunload = function () {
    //   return "Navigating away will lose the changes you've made to your code.";
    // };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {},
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      links: [
        {
          name: "Home",
          label: "Home",
          exact: true,
          link: "/user/home",
        },
        {
          name: "Registrazione",
          label: "Registrazione",
          exact: false,
          link: "/user/registrazione",
        },
        {
          name: "Activities",
          label: "Attività",
          exact: false,
          link: "/user/activities",
        },
        {
          name: "Agenda",
          label: "Agenda",
          exact: false,
          link: "/user/agenda",
        },
        {
          name: "Destinazione",
          label: "Oman",
          exact: false,
          link: "/user/destinazione",
        },
        {
          name: "Informazioni",
          label: "Info Utili",
          exact: false,
          link: "/user/informazioni",
        },
        {
          name: "Conferma",
          label: "Conferma",
          exact: false,
          link: "/user/conferma",
        },
      ],
    };
  },
  methods: {
    showExtents() {
      console.log(this.$vuetify.application.top);
      console.log(this.$vuetify.application.footer);
    },
    resetTester() {
      this.$swal
        .fire({
          title: "Reset?",
          text: "Con questa azione, riporti il tester alla versione iniziale (verranno mantenuti i dati personali ma cancellati gli accompagnatori e le conferme inserite)!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, elimina!",
          cancelButtonText: "Annulla",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("user/resetTester");
            this.$swal.fire(
              "Resettato!",
              "Il tester è stato riportato alla versione iniziale.",
              "success"
            );
          }
        });
    },
    resetConfirmViaggio() {
      this.$swal
        .fire({
          title: "Reset?",
          text: "Con questa azione, annulli la conferma del viaggio!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, resetta conferma!",
          cancelButtonText: "Annulla",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("user/resetConfirmViaggio");
            this.$swal.fire(
              "Resettato!",
              "La conferma viaggio è stata annullata.",
              "success"
            );
          }
        });
    },
    resetConfirmParty() {
      this.$swal
        .fire({
          title: "Reset?",
          text: "Con questa azione, annulli la conferma dei partecipanti ma non cancellerai i loro dati, elimina anche le iscrizione alle attività",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, resetta conferma partecipanti!",
          cancelButtonText: "Annulla",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("agenda/forceUnbookAll");
            this.$store.dispatch("user/resetConfirmParty");
            this.$swal.fire(
              "Resettato!",
              "La conferma partecipanti è stata resettata.",
              "success"
            );
          }
        });
    },
    resetIscrizioni() {
      this.$swal
        .fire({
          title: "Elimina iscrizioni?",
          text: "Con questa azione, elimini tutte le iscrizioni di winner e accompagnatori alle varie attività",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, elimina iscriioni!",
          cancelButtonText: "Annulla",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("agenda/forceUnbookAll");
            this.$swal.fire(
              "Fatto!",
              "Tutte le iscrizioni sono state rimosse",
              "success"
            );
          }
        });
    },
    logout() {
      if (!this.user.viaggio_confermato) {
        this.$swal
          .fire({
            title: "Non hai ancora confermato!",
            html: `Per poter partecipare al viaggio, ricorda di completare il processo con la conferma finale<br>Troverai il pulsante nella pagina "CONFERMA"!`,
            confirmButtonText: "Vai alla conferma",
            cancelButtonText: "Esci senza confermare",
            showCancelButton: true,
            cancelButtonColor: "#d33",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/user/conferma");
            } else {
              this.$store.dispatch("user/logout");
            }
          });
      } else this.$store.dispatch("user/logout");
    },
  },
};
</script>
