<template>
  <v-card
    height="100%"
    color="transparent"
    :tile="this.$vuetify.breakpoint.smAndUp ? false : true"
    class="pa-0 pa-sm-12"
    :class="this.$vuetify.breakpoint.smAndUp ? 'border-radius-xl' : ''"
  >
    <v-img :src="imageUrl" min-height="100%">
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "BaseCardImg",
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
  },
};
</script>
