<template>
  <v-card class="rounded-0">
    <v-app-bar dense dark :class="tableBarColor">
      <v-app-bar-title class="font-weight-light text-h5"
        >{{ title }} - {{ items.length }}</v-app-bar-title
      >
      <v-spacer></v-spacer>
      <v-text-field
        autocomplete="off"
        hide-details
        clearable
        color="white"
        outlined
        dense
        single-line
        placeholder="Cerca"
        v-model="search"
      >
        <template slot="prepend">
          <v-icon color="white" size="30">mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <span v-if="search" class="ml-4 text-body-1">{{ finds }} risultati</span>
      <v-spacer></v-spacer>
      <v-btn outlined tile class="mr-4" color="white" @click="chiudi">
        chiudi
      </v-btn>
    </v-app-bar>
    <v-data-table
      ref="datatablemorepeople"
      :headers="headers"
      :items="items"
      dense
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
      :height="height"
      :search="search"
      fixed-header
      :showGroupBy="showGroupBy"
    >
      <template v-slot:top>
        <v-toolbar elevation="2">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :color="tableBarColor" tile dark class="mb-2" v-bind="attrs" v-on="on">
                aggiungi nuovo ospite
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.nome"
                        label="Nome"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.cognome"
                        label="Cognome"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.id"
                        label="ID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.check_in"
                        label="Check in"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.check_out"
                        label="Check out"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.volo_andata"
                        label="Volo andata"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.volo_ritorno"
                        label="Volo ritorno"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="editedItem.hotel"
                        :items="hotels"
                        label="Hotel"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="editedItem.room_type"
                        :items="room_types"
                        item-value="value"
                        item-text="title"
                        label="Room type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.room_count"
                        label="Room count"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.room_number"
                        label="Room number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.remarks"
                        label="Remarks"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.note"
                        label="Note"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Annulla
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Salva </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Sei sicuro di voler cancellare questo ospite?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Annulla</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  name: "StaffWorkTable",
  components: {},
  props: {
    title: {
      type: String,
      default: "Title",
    },
    showGroupBy: {
      type: Boolean,
      default: true,
    },
    gruppo: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    headers: {
      type: Array,
      default: () => [
        {
          text: "mod",
          value: "actions",
          groupable: false,
          divider: true,
          width: "80px",
        },
        { text: "title", value: "title", groupable: false, divider: true },
        { text: "nomw", value: "nome", groupable: false, divider: true },
        { text: "cognome", value: "cognome", groupable: false, divider: true },
        {
          text: "check_in",
          value: "check_in",
          groupable: false,
          divider: true,
        },
        {
          text: "check_out",
          value: "check_out",
          groupable: false,
          divider: true,
        },
        {
          text: "volo_andata",
          value: "volo_andata",
          groupable: false,
          divider: true,
        },
        {
          text: "volo_ritorno",
          value: "volo_ritorno",
          groupable: false,
          divider: true,
        },
        {
          text: "hotel",
          value: "hotel",
          groupable: true,
          divider: true,
          width: "150px",
        },
        {
          text: "room_type",
          value: "room_type",
          groupable: false,
          divider: true,
          width: "220px",
        },
        {
          text: "room_count",
          value: "room_count",
          groupable: false,
          divider: true,
        },
        {
          text: "room_number",
          value: "room_number",
          groupable: false,
          divider: true,
        },
        { text: "remarks", value: "remarks", groupable: false, divider: true },
        { text: "note", value: "note", groupable: false, divider: true },
        { text: "email", value: "email", groupable: false, divider: true },
        { text: "id", value: "id", groupable: false, divider: true },
      ],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tableBarColor: {
      type: String,
      default: "bg-gradient-info",
    },
  },
  computed: {
    height() {
      const height = "100vh";
      return `calc(${height} - 112px)`;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo ospite" : "Modifica ospite";
    },
    sottotitolo_scheda() {
      return this.title.toLowerCase().replace(/ /g, "_");
    },
    finds() {
      return this.$refs.datatablemorepeople.$children[0].filteredItems.length;
    },
    hotels() {
      return ["Al Bandar", "Al Husn"];
    },
    morePeople: {
      get() {
        return this.$store.getters["participants/getMorePeople"];
      },
      set(newValue) {
        return this.$store.dispatch("participants/setMorePeople", newValue);
      },
    },
    items() {
      if (this.morePeople[this.gruppo] === undefined) {
        return [];
      }
      return this.morePeople[this.gruppo];
    },
    room_types() {
      return [
        { value: "Deluxe King", title: "Deluxe King" },
        { value: "Deluxe Twin", title: "Deluxe Twin" },
        { value: "Deluxe Connecting", title: "Deluxe Connecting" },
        { value: "Premier King", title: "Premier King" },
        { value: "Panoramic King", title: "Panoramic King" },
        { value: "One Bedroom Suite King", title: "One Bedroom Suite King" },
        {
          value: "One Bedroom Suite Connecting",
          title: "One Bedroom Suite Connecting",
        },
        { value: "Speciality Suite", title: "Speciality Suite" },
        { value: "Royal Suite", title: "Royal Suite" },
      ];
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
      editedIndex: -1,
      editedItem: {
        nome: "",
        cognome: "",
        email: "",
        id: "",
        title: "",
        check_in: "",
        check_out: "",
        volo_andata: "",
        volo_ritorno: "",
        hotel: "",
        room_type: "",
        room_count: "",
        room_number: "",
        remarks: "",
        note: "",
      },
      defaultItem: {
        nome: "",
        cognome: "",
        email: "",
        id: "",
        title: "",
        check_in: "",
        check_out: "",
        volo_andata: "",
        volo_ritorno: "",
        hotel: "",
        room_type: "",
        room_count: "",
        room_number: "",
        remarks: "",
        note: "",
      },
      dialogDelete: false,
    };
  },
  methods: {
    chiudi() {
      this.$emit("chiudi");
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.$store.dispatch("participants/updateMorePeopleFromState");
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$store.dispatch("participants/updateMorePeopleFromState");
      } else {
        this.items.push(this.editedItem);
        this.$store.dispatch("participants/updateMorePeopleFromState");
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.first-button-container {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 16px; */
}
</style>
