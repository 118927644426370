import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";

// Config
const firebaseConfig = {
  apiKey: "AIzaSyBJ7yNB7M586r-iICkTdOnN50koAp1mIP4",
  authDomain: "mutika-rt.firebaseapp.com",
  projectId: "mutika-rt",
  storageBucket: "mutika-rt.appspot.com",
  messagingSenderId: "705524333340",
  appId: "1:705524333340:web:4788d2421e81ab893f18d9"
};

firebase.initializeApp(firebaseConfig);

var functions = firebase.app().functions("europe-west1");
const elevateAdmin = functions.httpsCallable("aggiungiAdmin");
const elevateEditor = functions.httpsCallable("aggiungiEditor");
const assignRole = functions.httpsCallable("aggiungiRuolo");
const sendTestEmail = functions.httpsCallable("sendTestMail");
const sendTestFormattedMail = functions.httpsCallable("sendTestFormattedMail");
const sendHtmlEmail = functions.httpsCallable("sendHtmlEmail");
const creaUser = functions.httpsCallable("creaUser");
const rimuoviUser = functions.httpsCallable("rimuoviUser");
const generatePdf = functions.httpsCallable("generatePdf");
const prenotaGala = functions.httpsCallable("prenotaGala");
const prenotaCena = functions.httpsCallable("prenotaCena");
// PRENOTA CENA CHIEDE let table, emails, project, subject, text, html, sendMail

const union = firebase.firestore.FieldValue.arrayUnion;

const remarr = firebase.firestore.FieldValue.arrayRemove;
const fv = firebase.firestore.FieldValue;

var auth = firebase.auth();
var db = firebase.firestore();
var fba = firebase.auth;
var storage = firebase.storage();

auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

export {
  auth,
  db,
  fba,
  storage,
  elevateAdmin,
  elevateEditor,
  assignRole,
  sendTestEmail,
  sendTestFormattedMail,
  sendHtmlEmail,
  creaUser,
  rimuoviUser,
  fv,
  union,
  remarr,
  generatePdf,
  prenotaGala,
  prenotaCena,
};
