import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/firebase/init";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import UserLayout from "../views/Layout/UserLayout.vue";
import AuthLayout from "../views/Layout/AuthBasicLayout.vue";

Vue.use(VueRouter);

const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Reports.vue");
const People = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/People.vue");
const Notifiche = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Notifiche.vue");
const Statistiche = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Statistiche.vue");
// const Finance = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Finance.vue");
const ConfermaPagamento = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/FinanceConfermaPagamenti.vue"
  );
const LinkPagamenti = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/FinanceInvioLink.vue"
  );
const RileascioAssicurazione = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/FinanceRilasciaAssicurazione.vue"
  );
const FinanceDownload = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/FinanceDownload.vue"
  );
const Hotellerie = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Hotellerie.vue");
const Rooming = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Rooming.vue");
const MorePeople = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/MorePeople.vue");
const Flights = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Flights.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Settings.vue");
const SetQuote = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/SetQuote.vue");
const SetTesti = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/SetTesti.vue");
const AdActivities = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/AdActivities.vue"
  );
const ActivitiesDownloads = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/ActivitiesDownloads.vue"
  );
const WhiteDinners = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/WhiteDinners.vue"
  );
const AgendaCalendar = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/AgendaCalendar.vue"
  );
const NewActivity = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/NewActivity.vue");
const Privilegi = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/Privilegi.vue");
const GestioneWinners = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/GestioneWinners.vue"
  );
const Home = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Home.vue");
const Registrazione = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/User/Registrazione.vue"
  );
const Oman = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Oman.vue");
const InfoUtili = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/InfoUtili.vue");
const Conferma = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/User/ConfermaViaggio.vue"
  );
const Profile = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Profile.vue");
const Activities = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Activities.vue");
const Agenda = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/User/Agenda.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/LoginBasic.vue");
const About = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/About.vue");

let adminPages = {
  path: "/admin",
  component: DashboardLayout,
  redirect: "/admin/reports",
  name: "Admin",
  meta: {
    groupName: "admin",
    requiresEditor: true,
  },
  children: [
    {
      path: "/admin/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/people",
      name: "People",
      component: People,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/notifiche",
      name: "Notifiche",
      component: Notifiche,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/stats",
      name: "Statistiche",
      component: Statistiche,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    // {
    //   path: "/admin/finance",
    //   name: "Finance",
    //   component: Finance,
    //   meta: {
    //     groupName: "admin",
    //     requiresFinance: true,
    //   },
    // },
    {
      path: "/admin/invialink",
      name: "LinkPagamenti",
      component: LinkPagamenti,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/stipulaassicurazione",
      name: "RileascioAssicurazione",
      component: RileascioAssicurazione,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/financedload",
      name: "FinanceDownload",
      component: FinanceDownload,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/gestionepagamenti",
      name: "ConfermaPagamento",
      component: ConfermaPagamento,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/privilegi",
      name: "privilegi",
      component: Privilegi,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/gestionewinners",
      name: "GestioneWinners",
      component: GestioneWinners,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/hotellerie",
      name: "hotellerie",
      component: Hotellerie,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/rooming",
      name: "Rooming",
      component: Rooming,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/morePeople",
      name: "MorePeople",
      component: MorePeople,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/flights",
      name: "Flights",
      component: Flights,
      meta: {
        groupName: "admin",
        requiresFinance: true,
      },
    },
    {
      path: "/admin/settings",
      name: "settings",
      component: Settings,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/setquote",
      name: "setquote",
      component: SetQuote,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/settesti",
      name: "settesti",
      component: SetTesti,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/activities",
      name: "adactivities",
      component: AdActivities,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/activitiesdload",
      name: "activitiesdload",
      component: ActivitiesDownloads,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/whitedinners",
      name: "whitedinners",
      component: WhiteDinners,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/calendar",
      name: "Agendadmin",
      component: AgendaCalendar,
      meta: {
        groupName: "admin",
        requiresEditor: true,
      },
    },
    {
      path: "/admin/nuovaattivita",
      name: "NewActivity",
      component: NewActivity,
      meta: {
        groupName: "admin",
        requiresAdmin: true,
      },
    },
  ],
};
let userPages = {
  path: "/user",
  redirect: "/user/home",
  component: UserLayout,
  name: "User",
  children: [
    {
      path: "/user/home",
      name: "Home",
      component: Home,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/profile",
      name: "Profile",
      component: Profile,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/destinazione",
      name: "Destinazione",
      component: Oman,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/registrazione",
      name: "Registrazione",
      component: Registrazione,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/activities",
      name: "Activities",
      component: Activities,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/agenda",
      name: "Agenda",
      component: Agenda,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/informazioni",
      name: "InfoUtili",
      component: InfoUtili,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
    {
      path: "/user/conferma",
      name: "Conferma",
      component: Conferma,
      meta: {
        groupName: "Userpages",
        requiresAuth: true,
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/about",
        name: "About",
        component: About,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  adminPages,
  userPages,
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //controllo se la route chiede autenticazione
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    //controllo se user è autenticato
    let user = auth.currentUser;
    if (user) {
      //se user esiste sono autenticato quindi posso andare a next
      return next();
    } else {
      //se falso non sono autenticato quindi vengo reindirizzato a Home
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
    // come prima ma qui viene richiesto che user sia anche Admin
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          if (to.name === "Login") {
            //se è admin MA CHIEDE LOGIN deve andare a Reports
            return next({ name: "Reports" });
          }
          //se è admin può andare a next
          else return next();
        } else if (idTokenResult.claims.editor) {
          //se è editor deve andare a People
          return next({ name: "Reports" });
        } else {
          //redirect a Profilo perché ho già controllato che user esista
          return next({ name: "Home" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresFinance)) {
    // come prima ma qui viene richiesto che user sia editor
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (
          // (user.email.includes("@adisanto.com")) ||
          (idTokenResult.claims.editor && (user.email.includes("@mutika.it") || user.email==="supervisor@winners2024-mutika.com")) ||
          idTokenResult.claims.admin
        ) {
          //se è admin o editor mutika può andare a next
          return next();
        } else if (idTokenResult.claims.editor) {
          //se è editor non mutika deve andare a People
          return next({ name: "Reports" });
        } else {
          //redirect a Home perché ho già controllato che user esista
          return next({ name: "Home" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresEditor)) {
    // come prima ma qui viene richiesto che user sia editor
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.editor || idTokenResult.claims.admin) {
          //se è admin o editor può andare a next
          if (to.name === "Login") {
            if (idTokenResult.claims.admin) {
              return next({ name: "Reports" });
            } else return next({ name: "Reports" });
          } else return next();
        } else {
          //redirect a Home perché ho già controllato che user esista
          return next({ name: "Home" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else {
    //questa route non chiede autenticazione, ma se user è autenticato non può andare a Login
    let user = auth.currentUser;
    if (user && to.name === "Login") {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          return next({ name: "Reports" });
        } else if (idTokenResult.claims.editor) {
          return next({ name: "Reports" });
        } else return next({ name: "Home" });
      });
    } else {
      return next();
    }
  }
});

export default router;
