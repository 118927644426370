<template>
  <v-card class="card-shadow border-radius-xl">
    <base-btn v-if="$route.name !== 'Login'" @click="goLogin">Login</base-btn>
    <base-btn v-if="$route.name !== 'About'" @click="goAbout">About</base-btn>
  </v-card>
</template>

<script>
export default {
  name: "Menuauth",
  props: {
    cardClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    goLogin() {
      if (this.$route.name != "Login") {
        this.$router.push({ name: "Login" }).catch((err) => {
          console.log(err);
        });
      }
    },
    goAbout() {
      if (this.$route.name != "About") {
        this.$router.push({ name: "About" }).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
</script>
