<template>
  <v-app>
    <base-working></base-working>
    <app-bar-user background="white" has-bg linkColor="default"> </app-bar-user>
    <v-main class="auth-pages pa-0">
      <v-container fluid class="pa-0 ma-0">
        <v-sheet :min-height="height" color="transparent" class="pa-0">
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-sheet>
      </v-container>
      <v-footer v-if="false">
        <span class="text-center">&copy; 2023</span>
      </v-footer>
    </v-main>
  </v-app>
</template>
<script>
import AppBarUser from "@/components/AppBarUser";
export default {
  name: "UserLayout",
  components: {
    AppBarUser,
  },
  computed: {
    height() {
      const height = "100vh";
      return `calc(${height} - ${this.$vuetify.application.top}px - 24px)`;
    },
    marginBottom() {
      return `${this.$vuetify.application.bottom}px`;
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss"></style>
