<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
    app
    v-if="$vuetify.breakpoint.smAndUp"
  >
    <v-container>
      <v-row v-if="false">
        <v-col cols="12" class="d-flex align-center">
          <p class="font-weight-bolder ms-lg-0 ms-4 mb-0 text-white text-sm">
            LOGO
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  components: {},
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      links: [
        {
          name: "Pages",
        },
        {
          name: "Authentication",
        },
        {
          name: "About",
          link: "/about",
        },
      ],
    };
  },
};
</script>
