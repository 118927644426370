<template>
  <v-app>
    <base-working></base-working>
    <drawer
      v-if="nominativo"
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
      :nominativo="nominativo"
      :role="role"
      :navbarFixed="editorNavBarFixed"
      :initials="initials"
      @close-from-mobile="drawer = false"
    >
    </drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        @appbar-toggle="navbarFixed = $event"
        :toggle-active="drawer"
        :navbarFixed="editorNavBarFixed"
      ></app-bar>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import Drawer from "@/components/admin/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
export default {
  name: "DashboardLayout",
  components: {
    Drawer,
    AppBar,
  },
  computed: {
    nominativo() {
      return this.$store.getters["user/nominativo"];
    },
    initials() {
      return this.$store.getters["user/initials"];
    },
    role() {
      return this.$store.getters["user/role"];
    },
    editorNavBarFixed() {
      return this.$store.getters["userInterface/editorNavBarFixed"];
    },
  },
  data() {
    return {
      drawer: null,
      sidebarColor: "info",
      sidebarTheme: "dark",
      navbarFixed: true,
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
