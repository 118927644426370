<template>
  <v-app>
    <base-working></base-working>
    <app-bar-auth background="transparent" has-bg linkColor="white">
    </app-bar-auth>
    <v-main class="auth-pages">
      <div
        class="position-relative min-vh-100"
        :style="`background-image:  url(${require('@/assets/bgrounds/beach001.jpg')}); background-size: cover; background-position: 50%;`"
      >
        <span class="mask bg-gradient-default opacity-1" v-if="false"></span>
        <v-container class="my-auto">
          <v-row class="min-vh-100">
            <v-fade-transition mode="out-in">
              <router-view></router-view>
            </v-fade-transition>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
export default {
  name: "AuthLayout",
  components: {
    AppBarAuth,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
