import { firestoreAction } from "vuexfire";
import { db, union, remarr } from "@/firebase/init";
import { dynamicSort } from "@/utilities/helpers";
import Swal from "sweetalert2/dist/sweetalert2";
import router from "./../../router";

const namespaced = true;
const state = {
  eventi: [],
  orderedEvents: false,
};
const mutations = {
  SET_ORDERED_EVENTS(state, payload) {
    state.orderedEvents = payload;
  },
};
const actions = {
  bindEventi: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "eventi",
      db.collection("projects").doc("winners24").collection("eventi")
    );
  }),
  unbindEventi: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("eventi");
  }),
  setOrderedEvents: ({ commit }, payload) => {
    commit("SET_ORDERED_EVENTS", payload);
  },
  addActivity: async ({ dispatch }, payload) => {
    //mi aspetto un oggetto con le info dell'attività da aggiungere e un booleano che mi dice se fare il redirect alla pagina delle attività
    dispatch("userInterface/startWorking", null, { root: true });
    const docRef = db.collection("projects/winners24/eventi").doc();
    payload.activity.start =
      payload.activity.startDate + " " + payload.activity.startTime;
    payload.activity.end =
      payload.activity.endDate + " " + payload.activity.endTime;
    try {
      await docRef
        .set({
          ...payload.activity,
          iscritti: [],
          iscrittiNoPlace: [],
          id: docRef.id,
        })
        .then(() => {
          dispatch("userInterface/stopWorking", null, { root: true });
          if (payload.route) {
            router.push("/admin/activities");
          }
        });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  modActivity: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const docRef = db.collection("projects/winners24/eventi").doc(payload.id);
    payload.start = payload.startDate + " " + payload.startTime;
    payload.end = payload.endDate + " " + payload.endTime;
    try {
      await docRef
        .set({
          ...payload,
        })
        .then(() => {
          dispatch("userInterface/stopWorking", null, { root: true });
        });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  delActivity: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    //elimina attività, si aspetta String id attività da eliminare
    try {
      await db
        .collection("projects")
        .doc("winners24")
        .collection("eventi")
        .doc(payload)
        .delete();
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  bookEvent: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const eventToBook = state.eventi.find(
      (event) => event.id === payload.idEvento
    );
    // function calcolaQuota(data) {
    //   let quota = eventToBook.prezzo;
    //   if (eventToBook.fascePrezzo.length > 0) {
    //     eventToBook.fascePrezzo.forEach((fascia) => {
    //       if (data >= fascia.dataMax && data <= fascia.dataMin) {
    //         quota = Number(fascia.prezzo);
    //       }
    //     });
    //   }
    //   return quota;
    // }
    // function doesTakePlace(data) {
    //   let place = true;
    //   if (eventToBook.fascePrezzo.length > 0) {
    //     eventToBook.fascePrezzo.forEach((fascia) => {
    //       if (data >= fascia.dataMax && data <= fascia.dataMin) {
    //         place = fascia.takesPlace;
    //       }
    //     });
    //   }
    //   return place;
    // }
    const docRef = db
      .collection("projects/winners24/eventi")
      .doc(payload.idEvento);
    if (eventToBook.iscritti.length < eventToBook.limite || !payload.takesPlace)
      try {
        if (payload.takesPlace) {
          await docRef.update({
            iscritti: union(payload.guest),
          });
        } else {
          await docRef.update({
            iscrittiNoPlace: union(payload.guest),
          });
        }
        dispatch("user/updateUser", null, { root: true });
        dispatch("userInterface/stopWorking", null, { root: true });
      } catch (error) {
        console.log(error);
        dispatch("user/resetUser", null, { root: true });
        dispatch("userInterface/stopWorking", null, { root: true });
      }
    else {
      dispatch("userInterface/stopWorking", null, { root: true });
      dispatch("user/resetUser", null, { root: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ci dispiace, ma non ci sono più posti disponibili per questo evento!",
        confirmButtonText: "Chiudi",
        footer:
          "Prova a scegliere un'altra attività o a contattarci per maggiori informazioni",
      });
    }
  },

  bookEventSquadre: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const squadra = payload.guest.squadra;
    const eventToBook = state.eventi.find(
      (event) => event.id === payload.idEvento
    );
    db.runTransaction((transaction) => {
      return transaction
        .get(db.doc("projects/winners24/eventi/" + payload.idEvento))
        .then((evento) => {
          if (!evento.exists) {
            throw "Errore di connessione al database!";
          }
          let iscritti = evento.data().iscritti;
          if (!iscritti) iscritti = [];
          if (iscritti.length >= eventToBook.limite)
            throw "Ci dispiace, ma non ci sono più posti disponibili per questa attività!";
          let iscrittiSquadra = iscritti.filter(
            (iscritto) => iscritto.squadra === squadra
          );
          if (iscrittiSquadra.length < eventToBook.paxPerTeam) {
            transaction.update(
              db.doc("projects/winners24/eventi/" + payload.idEvento),
              {
                iscritti: union(payload.guest),
              }
            );
            dispatch("user/updateUser", null, { root: true });
            dispatch("userInterface/stopWorking", null, { root: true });
            Swal.fire({
              icon: "success",
              // title: "Fatto",
              title: payload.guest.nome + " " + payload.guest.cognome,
              showConfirmButton: false,
              timer: 2000,
              position: "top-start",
              toast: true,
              allowOutsideClick: false,
            });
          } else {
            dispatch("userInterface/stopWorking", null, { root: true });
            dispatch("user/resetUser", null, { root: true });
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                eventToBook.squadre === 1
                  ? "Ci dispiace, ma non ci sono più slot disponibili"
                  : "Ci dispiace, ma non ci sono più posti disponibili in questa squadra!",
              confirmButtonText: "Chiudi",
              footer:
                eventToBook.squadre === 1
                  ? "Prova a scegliere un'altra squadra!"
                  : "",
            });
          }
        });
    })
      .then(() => {
        dispatch("userInterface/stopWorking", null, { root: true });
      })
      .catch((err) => {
        dispatch("userInterface/stopWorking", null, { root: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err,
          allowOutsideClick: false,
        });
      });
  },
  modbookingEventSquadre: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const eventToBook = state.eventi.find(
      (event) => event.id === payload.idEvento
    );
    db.runTransaction((transaction) => {
      return transaction
        .get(db.doc("projects/winners24/eventi/" + payload.idEvento))
        .then((evento) => {
          if (!evento.exists) {
            throw "Errore di connessione al database!";
          }
          let iscritti = evento.data().iscritti;
          let oldIscrizione = iscritti.find(
            (iscritto) => iscritto.id === payload.guest.id
          );
          if (oldIscrizione) {
            transaction.update(
              db.doc("projects/winners24/eventi/" + payload.idEvento),
              {
                iscritti: union(payload.guest),
              }
            );
            transaction.update(
              db.doc("projects/winners24/eventi/" + payload.idEvento),
              {
                iscritti: remarr(oldIscrizione),
              }
            );
            dispatch("user/updateUser", null, { root: true });
            dispatch("userInterface/stopWorking", null, { root: true });
            Swal.fire({
              icon: "success",
              // title: "Fatto",
              title: payload.guest.nome + " " + payload.guest.cognome,
              showConfirmButton: false,
              timer: 2000,
              position: "top-start",
              toast: true,
              allowOutsideClick: false,
            });
          } else {
            dispatch("userInterface/stopWorking", null, { root: true });
            dispatch("user/resetUser", null, { root: true });
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                eventToBook.squadre === 1
                  ? "Ci dispiace, ma non ci sono più slot disponibili"
                  : "Ci dispiace, ma non ci sono più posti disponibili in questa squadra!",
              confirmButtonText: "Chiudi",
              footer:
                eventToBook.squadre === 1
                  ? "Prova a scegliere un'altra squadra!"
                  : "",
            });
          }
        });
    })
      .then(() => {
        dispatch("userInterface/stopWorking", null, { root: true });
      })
      .catch((err) => {
        dispatch("userInterface/stopWorking", null, { root: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err,
          allowOutsideClick: false,
        });
      });
  },
  unbookEvent: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const docRef = db
      .collection("projects/winners24/eventi")
      .doc(payload.idEvento);
    try {
      await docRef.update({
        iscritti: remarr(payload.guest),
        iscrittiNoPlace: remarr(payload.guest),
      });
      dispatch("user/updateUser", null, { root: true });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("user/resetUser", null, { root: true });
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  forceUnbookEvent: async ({ dispatch, state }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const eventDocRef = db
      .collection("projects/winners24/eventi")
      .doc(payload.idEvento);
    const evento = state.eventi.find(
      (evento) => evento.id === payload.idEvento
    );
    const guestToRemove =
      evento.iscritti.find((guest) => guest.id === payload.idGuest) ||
      evento.iscrittiNoPlace.find((guest) => guest.id === payload.idGuest);
    if (guestToRemove) {
      try {
        await eventDocRef.update({
          iscritti: remarr(guestToRemove),
          iscrittiNoPlace: remarr(guestToRemove),
        });
        const toRemoveFromAgenda = {
          id: guestToRemove.id,
          evento: payload.idEvento,
        };
        dispatch("user/forceRemoveFromAgenda", toRemoveFromAgenda, {
          root: true,
        });
        dispatch("userInterface/stopWorking", null, { root: true });
      } catch (error) {
        console.error(error);
      }
    }
  },
  forceUnbookAll: async ({ dispatch, rootGetters }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const user = rootGetters["user/user"];
    dispatch("clearAgenda", { idGuest: user.id });
    user.lista_acc.forEach((acc) => {
      dispatch("clearAgenda", { idGuest: acc.id });
    });
    dispatch("userInterface/stopWorking", null, { root: true });
  },
  clearAgenda: async ({ dispatch, state }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    const events = state.eventi.filter(
      (evento) =>
        evento.iscritti.find((guest) => guest.id === payload.idGuest) ||
        evento.iscrittiNoPlace.find((guest) => guest.id === payload.idGuest)
    );
    const batch = db.batch();
    events.forEach((evento) => {
      const eventDocRef = db
        .collection("projects/winners24/eventi")
        .doc(evento.id);
      const guestToRemove =
        evento.iscritti.find((guest) => guest.id === payload.idGuest) ||
        evento.iscrittiNoPlace.find((guest) => guest.id === payload.idGuest);
      batch.update(eventDocRef, {
        iscritti: remarr(guestToRemove),
        iscrittiNoPlace: remarr(guestToRemove),
      });
    });
    try {
      await batch.commit();
      const toRemoveFromAgenda = {
        id: payload.idGuest,
      };
      dispatch("user/forceClearAgenda", toRemoveFromAgenda, {
        root: true,
      });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  orderedEvents: (state) => {
    return state.orderedEvents;
  },
  checkPartyAgendaByEventId:
    // ritorna un array di oggetti guest con le info relative all'evento passato come argomento.
    // se l'utente è iscritto all'evento, alreadyBooked = true
    // se l'utente è iscritto ad un altro evento nello stesso orario, currentEventName = nome evento

    (state, getters, rootState, rootGetters) => (eventId) => {
      const party = rootGetters["user/getParty"];
      const event = getters.getEventiById(eventId);
      party.forEach((guest) => {
        if (event.minimumNascita && guest.nascita_data > event.minimumNascita) {
          guest.underage = true;
        } else guest.underage = false;
        guest.currentEventName = "";
        guest.iscrizioni = guest.iscrizioni ? guest.iscrizioni : [];
        guest.iscrizioni.includes(eventId)
          ? (guest.alreadyBooked = true)
          : (guest.alreadyBooked = false);
        guest.iscrizioni.forEach((iscrizione) => {
          let booked = getters.getEventiById(iscrizione);
          if (
            (booked.start >= event.start && booked.start <= event.end) ||
            (booked.end >= event.start && booked.end <= event.end) ||
            (booked.start <= event.start && event.end <= booked.end)
          ) {
            guest.currentEventName = booked.name;
          }
        });
      });
      return party;
    },
  getEventi: (state, getters, rootState, rootGetters) => {
    let eventi = JSON.parse(JSON.stringify(state.eventi)).sort(
      dynamicSort("start")
    );
    let guests = rootGetters["participants/guestConfermati"];
    eventi.forEach((evento) => {
      evento.iscritti = evento.iscritti
        ? evento.iscritti.map((iscritto) => {
            let guest = guests.find((guest) => guest.id === iscritto.id);
            return guest
              ? { ...iscritto, cellulare: guest.cellulare }
              : iscritto;
          })
        : [];
      evento.iscrittiNoPlace = evento.iscrittiNoPlace
        ? evento.iscrittiNoPlace.map((iscritto) => {
            let guest = guests.find((guest) => guest.id === iscritto.id);
            return guest
              ? { ...iscritto, cellulare: guest.cellulare }
              : iscritto;
          })
        : [];
    });
    return eventi;
  },
  getEventiById: (state) => (id) => {
    return state.eventi.find((evento) => evento.id === id);
  },
  getIscrittiSquadra: (state) => (payload) => {
    if (state.eventi.length === 0) return [];
    let iscritti = state.eventi.find((evento) => evento.id === payload.id)
      .iscritti
      ? state.eventi.find((evento) => evento.id === payload.id).iscritti
      : [];
    return iscritti.filter((iscritto) => iscritto.squadra === payload.squadra);
  },
  getEventiByTurno: (state, getters) => (turno) => {
    if (getters.getEventi.length === 0) return [];
    return getters.getEventi.filter((evento) => evento.turni.includes(turno));
  },
  free: (state, getters) => {
    return getters.getEventi.filter((evento) => !evento.prezzo);
  },
  pay: (state, getters) => {
    return getters.getEventi.filter((evento) => evento.prezzo);
  },
  talks: (state, getters) => {
    return getters.getEventi.filter(
      (evento) => evento.tipologia === "networking"
    );
  },
  contest: (state, getters) => {
    return getters.getEventi.filter((evento) => evento.tipologia === "contest");
  },
  sport: (state, getters) => {
    return getters.getEventi.filter(
      (evento) =>
        evento.tipologia === "sport" ||
        evento.tipologia === "torneo" ||
        evento.tipologia === "ciclismo"
    );
  },
  special: (state, getters) => {
    return getters.getEventi.filter(
      (evento) =>
        evento.tipologia === "convention" || evento.tipologia === "gala"
    );
  },
  social: (state, getters) => {
    return getters.getEventi.filter(
      (evento) =>
        evento.tipologia === "contest" ||
        evento.tipologia === "show" ||
        evento.tipologia === "spettacolo" ||
        evento.tipologia === "masterclass" ||
        evento.tipologia === "networking" ||
        evento.tipologia === "cena" ||
        evento.tipologia === "party"
    );
  },
  concurrentEvents: state => {
    const concurrentEvents = [];
    state.eventi.forEach((event, index) => {
      event.iscritti.forEach(iscritto => {
        const concurrentEvent = state.eventi.find((otherEvent, otherIndex) => {
          if (otherIndex === index) return false; // Skip the same event
          const isConcurrent = 
            (otherEvent.startDate <= event.endDate && otherEvent.endDate >= event.startDate) &&
            (otherEvent.startTime < event.endTime && otherEvent.endTime > event.startTime) &&
            otherEvent.iscritti.some(otherIscritto => otherIscritto.id === iscritto.id);
          return isConcurrent;
        });
        if (concurrentEvent) {
          concurrentEvents.push({
            iscrittoId: iscritto.id,
            eventId: event.id,
            concurrentEventId: concurrentEvent.id
          });
        }
      });
    });
    return concurrentEvents;
  },
  // concurrentEventsV2: state => {
  //   const concurrentEvents = [];
  //   for (let i = 0; i < state.eventi.length; i++) {
  //     const event = state.eventi[i];
  //     for (const iscritto of event.iscritti) {
  //       for (let j = 0; j < state.eventi.length; j++) {
  //         if (i === j) continue; // Skip the same event
  //         const otherEvent = state.eventi[j];
  //         const isConcurrent = 
  //           (otherEvent.startDate <= event.endDate && otherEvent.endDate >= event.startDate) &&
  //           (otherEvent.startTime < event.endTime && otherEvent.endTime > event.startTime) &&
  //           otherEvent.iscritti.some(otherIscritto => otherIscritto.id === iscritto.id);
  //         if (isConcurrent) {
  //           concurrentEvents.push({
  //             iscrittoId: iscritto.id,
  //             eventId: event.id,
  //             concurrentEventId: otherEvent.id
  //           });
  //         }
  //       }
  //     }
  //   }
  //   return concurrentEvents;
  // },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
