<template>
  <v-btn
    :elevation="elevation"
    :min-width="minWidth"
    :height="height"
    :class="btnClass"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="off"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseBtn",
  computed: {
    btnClass() {
      if (this.outlined)
        return this.off
          ? "font-weight-bold text-uppercase btn-disabled shadow-none grey--text text--lighten-2 bg-gradient-disabled py-2 px-6 me-2 text-xs"
          : this.secondary
          ? "font-weight-bold text-uppercase btn-secondary btn-outline-secondary shadow-none  py-2 px-6 me-2 text-xs"
          : "font-weight-bold text-uppercase btn-primary btn-outline-primary shadow-none py-2 px-6 me-2 text-xs";
      return this.off
        ? "font-weight-bold text-uppercase btn-disabled shadow-none white--text bg-gradient-disabled py-2 px-6 me-2 text-xs"
        : this.secondary
        ? "font-weight-bold text-uppercase btn-secondary shadow-secondary white--text bg-gradient-secondary py-2 px-6 me-2 text-xs"
        : "font-weight-bold text-uppercase btn-primary shadow-primary white--text bg-gradient-primary py-2 px-6 me-2 text-xs";
    },
  },
  props: {
    minWidth: {
      type: [Number, String],
      default: 164,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    elevation: {
      type: [Number, String],
      default: 0,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    off: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
