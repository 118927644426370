<template>
  <v-card class="card-shadow border-radius-xl py-4">
    <v-row no-gutters class="px-4">
      <v-col sm="4">
        <p class="text-lg mb-1 text-capitalize text-body font-weight-bold">
          {{ item.aeroporto }}
        </p>
      </v-col>
      <v-col sm="8" class="text-end">
        <p class="text-lg mb-1 text-body font-weight-bold">
          {{ item.tratta }} - turno {{ item.turno }}
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters class="px-4" align="end">
      <v-col cols="6">
        <h5 class="text-h5 text-typo text-left font-weight-bolder mb-0">
          adults: {{ item.adults }}<br />
          infants: {{ item.infants }}
        </h5>
      </v-col>
      <v-col cols="6">
        <h5 class="text-h5 text-typo text-center font-weight-bolder mb-0">
          totale: {{ item.adults + item.infants }}
        </h5>
        <h6 class="text-h5 red--text text-center font-weight-bolder mb-0">
          max: {{ item.max + item.adults + item.infants}}
        </h6>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ReportVoli",
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          aeroporto: "aeroporto",
          tratta: "tratta",
          turno: "turno",
          adults: 0,
          infants: 0,
        };
      },
    },
  },
};
</script>
<style scoped>
.first-button-container {
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 16px; */
}
</style>
