import { firestoreAction } from "vuexfire";
import { db } from "@/firebase/init";
import dayjs from "dayjs";

import {
  convertWinner,
  toWinCount,
  toWinTutto,
  toGuest,
  toStdPlus,
  toConferma,
  toConfermaMed,
  toWinners,
  // toRoomingWorkFamily,
  // toRoomingAll,
  toRoomingAllFlights,
  toFlights,
  toGalaList,
  toGalaSeated,
} from "@/utilities/converters.js";

const namespaced = true;
const state = {
  winners: [],
  morePeople: {},
};

const mutations = {
  SET_MORE_PEOPLE: (state, payload) => {
    state.morePeople = payload;
  },
};
const actions = {
  bindParticipantsData: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "winners",
      db.collection("projects").doc("winners24").collection("users")
    );
  }),
  unbindParticipantsData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("winners");
  }),
  bindMorePeople: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "morePeople",
      db
        .collection("projects")
        .doc("winners24")
        .collection("settings")
        .doc("morePeople")
    );
  }),
  unbindMorePeople: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("morePeople");
  }),
  updateWinnerFromAdmin: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db
        .doc(`projects/winners24/users/${payload.email}`)
        .set(payload.content, { merge: true });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  updateMorePeopleFromState: async ({ dispatch, state }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db
        .doc(`projects/winners24/settings/morePeople`)
        .set(state.morePeople, { merge: true });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  saveMorePeopleFromPayload: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db
        .doc(`projects/winners24/settings/morePeople`)
        .set(payload, { merge: true });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  setMorePeople: ({ commit }, payload) => {
    commit("SET_MORE_PEOPLE", payload);
  },
  addWinner: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db.doc(`projects/winners24/users/${payload.email}`).set(payload);
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  removeWinner: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db.doc(`projects/winners24/users/${payload}`).delete();
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
};

const getters = {
  allUsers: (state) => {
    return state.winners;
  },
  testUsers: (state) => {
    return state.winners.filter(
      (winner) =>
        winner.email.includes("winners24") ||
        winner.email.includes("winners2024")
    );
  },
  realUsers: (state) => {
    // return state.winners;
    return state.winners.filter(
      (winner) =>
        !winner.email.includes("winners24") &&
        !winner.email.includes("adisanto.com") &&
        !winner.email.includes("winners2024")
    );
  },
  findWinner: (state) => (email) => {
    return state.winners.find((winner) => winner.email === email);
  },
  fullWinners: (state, getters) => {
    let winnersList = [];
    getters.realUsers.forEach((winner) => {
      winnersList.push(convertWinner(winner));
    });
    return winnersList;
  },
  convertedConfirmed: (state, getters) => {
    return getters.fullWinners.filter(
      (winner) => winner.conferma != "non confermato"
    );
  },
  getGalaGuestsA: (state, getters) => {
    let users = getters.convertedConfirmed.filter(
      (user) => user.turno === "A" || user.turno === "C"
    );
    let parties = [];
    users.forEach((user) => {
      let guests = toGalaList(user);
      parties.push({
        email: user.email,
        nome: user.nome,
        nominativo: user.nome.toUpperCase() + " " + user.cognome.toUpperCase(),
        pax: guests.length,
        lista: guests,
      });
    });
    return parties;
  },
  getGalaSeatedA: (state, getters) => {
    let users = getters.convertedConfirmed.filter(
      (user) =>
        (user.turno === "A" || user.turno === "C") &&
        !user.rinuncia &&
        !user.nogala
    );
    let parties = [];
    users.forEach((user) => {
      let guests = toGalaSeated(user);
      if (user.email === "federico.mantero@bancamediolanum.it") {
        guests.forEach((guest) => (guest.tavolo = "26"));
      }
      guests.forEach((guest) => parties.push(guest));
    });
    return parties;
  },
  getGalaSeatedB: (state, getters) => {
    let users = getters.convertedConfirmed.filter(
      (user) =>
        (user.turno === "B" || user.turno === "C") &&
        !user.rinuncia &&
        !user.nogala
    );
    let parties = [];
    users.forEach((user) => {
      let guests = toGalaSeated(user);
      guests.forEach((guest) => parties.push(guest));
    });
    return parties;
  },
  getGalaGuestsB: (state, getters) => {
    let users = getters.convertedConfirmed.filter(
      (user) => (user.turno === "B" || user.turno === "C") && !user.rinuncia
    );
    let parties = [];
    users.forEach((user) => {
      let guests = toGalaList(user);
      parties.push({
        email: user.email,
        nome: user.nome,
        nominativo: user.nome.toUpperCase() + " " + user.cognome.toUpperCase(),
        pax: guests.length,
        lista: guests,
      });
    });
    return parties;
  },
  winnersCount: (state, getters) => {
    return getters.fullWinners.length;
  },
  winCount: (state, getters) => {
    return getters.fullWinners.map((winner) => toWinCount(winner));
  },
  winnersStats: (state, getters) => {
    return getters.fullWinners.map((winner) => toWinners(winner));
  },
  stdplus: (state, getters) => {
    let users = getters.fullWinners.filter(
      (winner) => winner.premio.toLowerCase() === "standard + 1"
    );
    return users.map((winner) => toStdPlus(winner));
  },
  winTutto: (state, getters) => {
    let winners = getters.fullWinners.filter((winner) => {
      if (winner.conferma === "non confermato") return false;
      if (winner.rinuncia) return false;
      return true;
    });
    return winners.map((winner) => toWinTutto(winner));
  },
  // roomingAll: (state, getters) => {
  //   let winners = getters.fullWinners.filter((winner) => {
  //     if (winner.conferma === "non confermato") return false;
  //     if (winner.rinuncia) return false;
  //     return winner;
  //   });
  //   let rooming = [];
  //   winners.forEach((winner) => {
  //     let guest = toRoomingAll(winner);
  //     guest.forEach((g) => rooming.push(g));
  //   });
  //   return rooming;
  // },
  notReadYet: (state, getters) => {
    let notReaders = getters.realUsers.filter((winner) => {
      if (!winner.viaggio_confermato) return false;
      if (winner.rinuncia) return false;
      if (winner.readNews === undefined) return true;
      if (winner.readNews.length === 0) return true;
      return false;
    });
    return notReaders.map((winner) => {
      return {
        email: winner.email,
        codice_agente: winner.codice_agente,
        turno: winner.turno,
        nome: winner.nome,
        cognome: winner.cognome,
        cellulare: winner.cellulare,
        regional: winner.regional,
      };
    });
  },
  roomingAll: (state, getters, rootState, rootGetters) => {
    let winners = getters.fullWinners.filter((winner) => {
      if (winner.conferma === "non confermato") return false;
      if (winner.rinuncia) return false;
      return winner;
    });
    let flights = rootGetters["settings/getFlights"];
    let rooming = [];
    winners.forEach((winner) => {
      let guest = toRoomingAllFlights(winner, flights);
      guest.forEach((g) => rooming.push(g));
    });
    return rooming;
  },
  flightLists: (state, getters, rootState, rootGetters) => {
    let winners = getters.fullWinners.filter((winner) => {
      if (winner.conferma === "non confermato") return false;
      if (winner.rinuncia) return false;
      return winner;
    });
    let winnersA = winners.filter((winner) => winner.turno === "A");
    let winnersB = winners.filter((winner) => winner.turno === "B");
    let winnersC = winners.filter((winner) => winner.turno === "C");
    let flights = rootGetters["settings/getFlights"];
    let listAA = [];
    winnersA.forEach((winner) => {
      let guests = toFlights(winner, flights);
      guests.forEach((g) => listAA.push(g));
    });
    let listBB = [];
    winnersB.forEach((winner) => {
      let guests = toFlights(winner, flights);
      guests.forEach((g) => listBB.push(g));
    });
    let listCC = [];
    winnersC.forEach((winner) => {
      let guests = toFlights(winner, flights);
      guests.forEach((g) => listCC.push(g));
    });
    let flAList = listAA.reduce(
      (acc, flight) => {
        if (acc.andata[flight.arrival_flight] === undefined) {
          acc.andata[flight.arrival_flight] = [flight];
        } else acc.andata[flight.arrival_flight].push(flight);
        if (acc.ritorno[flight.departure_flight] === undefined) {
          acc.ritorno[flight.departure_flight] = [flight];
        } else acc.ritorno[flight.departure_flight].push(flight);
        return acc;
      },
      { andata: {}, ritorno: {} }
    );
    let flBList = listBB.reduce(
      (acc, flight) => {
        if (acc.andata[flight.arrival_flight] === undefined) {
          acc.andata[flight.arrival_flight] = [flight];
        } else acc.andata[flight.arrival_flight].push(flight);
        if (acc.ritorno[flight.departure_flight] === undefined) {
          acc.ritorno[flight.departure_flight] = [flight];
        } else acc.ritorno[flight.departure_flight].push(flight);
        return acc;
      },
      { andata: {}, ritorno: {} }
    );
    let flCList = listCC.reduce(
      (acc, flight) => {
        if (acc.andata[flight.arrival_flight] === undefined) {
          acc.andata[flight.arrival_flight] = [flight];
        } else acc.andata[flight.arrival_flight].push(flight);
        if (acc.ritorno[flight.departure_flight] === undefined) {
          acc.ritorno[flight.departure_flight] = [flight];
        } else acc.ritorno[flight.departure_flight].push(flight);
        return acc;
      },
      { andata: {}, ritorno: {} }
    );
    // flAList.andata.NO9532.push({
    //   title: "MS",
    //   allergies: "NO mushrooms",
    //   first_name: "Lina",
    //   last_name: "Tombolato",
    //   note: "VVIP",
    //   hotel: "Al Husn",
    // });
    // flAList.andata.NO9532.push({
    //   title: "MS",
    //   allergies: "",
    //   first_name: "Serena Maria",
    //   last_name: "Vezzaro",
    //   note: "",
    //   hotel: "Al Husn",
    // });
    return {
      flAList,
      flBList,
      flCList,
    };
  },
  flightListsDate: (state, getters, rootState, rootGetters) => {
    let winners = getters.fullWinners.filter((winner) => {
      if (winner.conferma === "non confermato") return false;
      if (winner.rinuncia) return false;
      return winner;
    });
    let flights = rootGetters["settings/getFlights"];
    let list = [];
    winners.forEach((winner) => {
      let guests = toFlights(winner, flights);
      guests.forEach((g) => list.push(g));
    });
    let flist = {};
    list.forEach((guest) => {
      if (flist[guest.departure_flight_date] === undefined) {
        flist[guest.departure_flight_date] = {
          [guest.departure_flight]: [guest],
        };
      } else {
        if (
          flist[guest.departure_flight_date][guest.departure_flight] ===
          undefined
        ) {
          flist[guest.departure_flight_date][guest.departure_flight] = [guest];
        } else
          flist[guest.departure_flight_date][guest.departure_flight].push(
            guest
          );
      }
      if (flist[guest.arrival_flight_date] === undefined) {
        flist[guest.arrival_flight_date] = {
          [guest.arrival_flight]: [guest],
        };
      } else {
        if (
          flist[guest.arrival_flight_date][guest.arrival_flight] === undefined
        ) {
          flist[guest.arrival_flight_date][guest.arrival_flight] = [guest];
        } else
          flist[guest.arrival_flight_date][guest.arrival_flight].push(guest);
      }
    });
    // let flAList = list.reduce(
    //   (acc, flight) => {
    //     if (acc.andata[flight.arrival_flight] === undefined) {
    //       acc.andata[flight.arrival_flight] = [flight];
    //     } else acc.andata[flight.arrival_flight].push(flight);
    //     if (acc.ritorno[flight.departure_flight] === undefined) {
    //       acc.ritorno[flight.departure_flight] = [flight];
    //     } else acc.ritorno[flight.departure_flight].push(flight);
    //     return acc;
    //   },
    //   { andata: {}, ritorno: {} }
    // );
    flist["2024-04-19"]["NO9532"].push({
      title: "MS",
      allergies: "NO mushrooms",
      first_name: "Lina",
      last_name: "Tombolato",
      note: "VVIP",
      hotel: "Al Husn",
    });
    flist["2024-04-19"]["NO9532"].push({
      title: "MS",
      allergies: "",
      first_name: "Serena Maria",
      last_name: "Vezzaro",
      note: "",
      hotel: "Al Husn",
    });
    return {
      // flAList,
      flist,
    };
  },
  winCountFor: (state, getters) => {
    let winners = getters.fullWinners;
    let winToCount = [];
    winners.forEach((winner) => {
      winToCount.push(toWinCount(winner));
    });
    return winToCount;
  },
  getMorePeople: (state) => {
    return state.morePeople;
  },
  guests: (state, getters) => {
    let guests = [];
    getters.fullWinners.forEach((winner) => {
      guests.push(toGuest(winner));
      if (winner.acc) {
        winner.lista_acc.forEach((acc) => {
          guests.push(toGuest(acc));
        });
      }
    });
    return guests;
  },
  guestConfermati: (state, getters) => {
    let guests = [];
    getters.convertedConfirmed.forEach((winner) => {
      guests.push(toGuest(winner));
      if (winner.acc) {
        let listaccs = winner.lista_acc.filter((acc) => !acc.rinuncia);
        listaccs.forEach((acc) => {
          guests.push(toGuest(acc));
        });
      }
    });
    return guests;
  },
  guestsCount: (state, getters) => {
    return getters.guests.length;
  },
  rinunce: (state, getters) => {
    return getters.realUsers.filter((winner) => winner.rinuncia);
  },
  nonRegistrati: (state, getters) => {
    return getters.realUsers.filter(
      (winner) => !winner.prima_scelta && !winner.rinuncia
    );
  },
  potenzialiNonRegistrati: (state, getters) => {
    let potenziali = 0;
    getters.nonRegistrati.forEach((winner) => {
      potenziali += 1 + winner.max_accompagnatori;
    });
    return potenziali;
  },
  potenziali: (state, getters) => {
    let potenziali = {
      MXPA: 0,
      MXPB: 0,
      FCOA: 0,
      FCOB: 0,
    };
    let pool = getters.realUsers.filter(
      (winner) => !winner.rinuncia && !winner.viaggio_confermato
    );
    pool.forEach((winner) => {
      potenziali[`${winner.sigla_aeroporto}${winner.turno}`] +=
        1 + winner.max_accompagnatori;
    });
    return potenziali;
  },
  workInProgress: (state, getters) => {
    return getters.realUsers.filter(
      (winner) =>
        winner.prima_scelta && !winner.viaggio_confermato && !winner.rinuncia
    );
  },
  conferme: (state, getters) => {
    return getters.realUsers.filter((winner) => winner.viaggio_confermato);
  },
  getCardPayments: (state, getters, rootState, rootGetters) => {
    let cardPayments = getters.conferme.filter(
      (guest) => guest.pagamento === "carta"
    );
    function formatDateISO(data) {
      return new Date(data).toISOString().slice(0, 10);
    }
    let cardConQuotaAdmin = cardPayments.map((guest) => {
      guest.quotaAdmin = rootGetters["user/getQuotaAdmin"](guest);
      guest.assicurato = guest.assicurazione ? "si" : "no";
      // let modificatore1 = guest.modificatore_quota
      //   ? guest.modificatore_quota
      //   : 0;
      // let modificatore2 = guest.modificatore_quota_out_ass
      //   ? guest.modificatore_quota_out_ass
      //   : 0;
      guest.warningPagamento = guest.warning ? guest.warning : false;

      // guest.warningPagamento =
      //   guest.warning ||
      //   guest.quotaAdmin !==
      //     guest.quota +
      //       guest.quotaAss +
      //       guest.quotaFeeCard +
      //       modificatore1 +
      //       modificatore2;
      guest.submit = formatDateISO(guest.viaggio_confermato);
      guest.linkSent = guest.link_pagamento
        ? formatDateISO(guest.link_pagamento)
        : "";
      return guest;
    });
    return cardConQuotaAdmin;
  },
  getAllPaganti: (state, getters, rootState, rootGetters) => {
    let pagatori = getters.conferme;
    function formatDateISO(data) {
      return new Date(data).toISOString().slice(0, 10);
    }
    let cardConQuotaAdmin = pagatori.map((guest) => {
      guest.quotaAdmin = rootGetters["user/getQuotaAdmin"](guest);
      let statoAssicurazione = "non assicurato";
      if (guest.assicurazione) {
        if (guest.assicurazione_rilasciata) {
          statoAssicurazione = "rilasciata";
        } else {
          statoAssicurazione = "da rilasciare";
        }
      }
      // let modificatore1 = guest.modificatore_quota
      //   ? guest.modificatore_quota
      //   : 0;
      // let modificatore2 = guest.modificatore_quota_out_ass
      //   ? guest.modificatore_quota_out_ass
      //   : 0;
      guest.pagamento_confermato = guest.conferma_di_pagamento
        ? "pagamento confermato"
        : "ancora da confermare";
      guest.statoAssicurazione = statoAssicurazione;
      guest.warningPagamento = guest.warning ? guest.warning : false;
      // guest.warningPagamento =
      //   guest.warning ||
      //   guest.quotaAdmin !==
      //     guest.quota +
      //       guest.quotaAss +
      //       guest.quotaFeeCard +
      //       modificatore1 +
      //       modificatore2;
      guest.submit = formatDateISO(guest.viaggio_confermato);
      guest.linkSent = guest.link_pagamento
        ? formatDateISO(guest.link_pagamento)
        : "";
      guest.code = guest.sigla_agente ? guest.sigla_agente : "";
      return guest;
    });
    return cardConQuotaAdmin.filter((guest) => guest.quotaAdmin !== 0);
  },
  // difference: (state, getters) => {
  //   let conpagamento = getters.getAllPaganti;
  //   let conquota = getters.getListaPaganti;
  //   let mappa = conpagamento.map((el) => el.email);
  //   return conquota.filter((element) => !mappa.includes(element.email));
  // },
  getListaPaganti: (state, getters, rootState, rootGetters) => {
    let pagatori = getters.conferme;
    function formatDateISO(data) {
      return new Date(data).toISOString().slice(0, 10);
    }
    let cardConQuotaAdmin = pagatori.map((guest) => {
      let quotaSeparata =
        rootGetters["user/getQuotaAdminAccompagnatori"](guest);
      guest.quota_accompagnatori = quotaSeparata.quota;
      guest.quota_tour = quotaSeparata.quotaTour;
      guest.quota_assicurazione_accompagnatori = quotaSeparata.quotaAccAss;
      guest.quota_assicurazione_tour = quotaSeparata.quotaTourAss;
      guest.fee_carta_accompagnatori = quotaSeparata.feeCardAcc;
      guest.fee_carta_tour = quotaSeparata.feeCardTour;
      guest.modificatore_quota = guest.modificatore_quota
        ? guest.modificatore_quota
        : 0;
      guest.modificatore_quota_out_ass = guest.modificatore_quota_out_ass
        ? guest.modificatore_quota_out_ass
        : 0;
      let statoAssicurazione = "non assicurato";
      if (guest.assicurazione) {
        if (guest.assicurazione_rilasciata) {
          statoAssicurazione = "rilasciata";
        } else {
          statoAssicurazione = "da rilasciare";
        }
      }
      guest.pagamento_confermato = guest.conferma_di_pagamento
        ? "pagamento confermato"
        : "ancora da confermare";
      guest.statoAssicurazione = statoAssicurazione;
      guest.submit = formatDateISO(guest.viaggio_confermato);
      guest.code = guest.sigla_agente ? guest.sigla_agente : "";
      return {
        email: guest.email,
        codice_agente: guest.codice_agente,
        nome: guest.nome,
        cognome: guest.cognome,
        quota_totale: guest.quotaTotale,
        pagamento: guest.pagamento_confermato,
        quota_accompagnatori: guest.quota_accompagnatori,
        quota_tour: guest.quota_tour,
        assicurazione_accompagnatori: guest.quota_assicurazione_accompagnatori,
        assicurazione_tour: guest.quota_assicurazione_tour,
        fee_carta_accompagnatori: guest.fee_carta_accompagnatori,
        fee_carta_tour: guest.fee_carta_tour,
        modificatore_quota: guest.modificatore_quota,
        modificatore_quota_out_ass: guest.modificatore_quota_out_ass,
        submit: guest.submit,
        statoAssicurazione: guest.statoAssicurazione,
      };
    });
    return cardConQuotaAdmin.filter((guest) => guest.quota_totale !== 0);
  },
  getFatturazione: (state, getters, rootState, rootGetters) => {
    let pagatori = getters.realUsers;
    function formatDateISO(data) {
      return new Date(data).toISOString().slice(0, 10);
    }
    let cardConQuotaAdmin = pagatori.map((guest) => {
      let quotaSeparata = rootGetters["user/getQuotaAdminSeparata"](guest);
      guest.totale = quotaSeparata.totale;
      guest.quota_accompagnatori = quotaSeparata.qAccompagnatore;
      guest.quota_tour = quotaSeparata.qActivities;
      guest.quota_assicurazione = quotaSeparata.assicurazione;
      guest.feeCard = quotaSeparata.feeCard;
      guest.sconti = quotaSeparata.sconti;
      let note_quota = "";
      if (guest.modificatore_quota_descrizione && guest.modificatore_quota) {
        note_quota +=
          "applicata modifica quota di " +
          guest.modificatore_quota +
          "€ per " +
          guest.modificatore_quota_descrizione +
          ". ";
      }
      if (
        guest.modificatore_quota_descrizione &&
        guest.modificatore_quota_out_ass
      ) {
        note_quota +=
          "applicata modifica quota di " +
          guest.modificatore_quota_out_ass +
          "€ per " +
          guest.modificatore_quota_descrizione +
          ". ";
      }
      let statoAssicurazione = "non assicurato";
      if (guest.assicurazione) {
        if (guest.assicurazione_rilasciata) {
          statoAssicurazione = "rilasciata";
        } else {
          statoAssicurazione = "da rilasciare";
        }
      }
      guest.pagamento_confermato = guest.conferma_di_pagamento
        ? "pagamento confermato"
        : "ancora da confermare";
      guest.statoAssicurazione = statoAssicurazione;
      guest.submit = guest.viaggio_confermato
        ? formatDateISO(guest.viaggio_confermato)
        : "non confermato";
      guest.rinungia = guest.rinuncia ? formatDateISO(guest.rinuncia) : "";
      guest.code = guest.sigla_agente ? guest.sigla_agente : "";
      return {
        "codice agente": guest.codice_agente,
        "cognome e nome": guest.cognome + " " + guest.nome,
        "codice fiscale": guest.codice_fiscale ? guest.codice_fiscale : "",
        "partita iva": guest.partita_iva ? guest.partita_iva : "",
        "codice SDI": guest.fatt_sdi ? guest.fatt_sdi : "",
        "indirizzo di fatturazione": guest.fatt_indirizzo
          ? guest.fatt_indirizzo
          : "",
        cap: guest.fatt_cap ? guest.fatt_cap : "",
        comune: guest.fatt_citta ? guest.fatt_citta : "",
        provincia: guest.fatt_prov ? guest.fatt_prov : "",
        email: guest.email,
        pec: guest.fatt_pec ? guest.fatt_pec : "",
        turno: guest.turno,
        premio: guest.premio,
        "numero accompagnatori": guest.lista_acc ? guest.lista_acc.length : 0,
        "quota accompagnatore": guest.quota_accompagnatori
          ? guest.quota_accompagnatori
          : 0,
        "quota tour": guest.quota_tour ? guest.quota_tour : 0,
        "quota assicurazione": guest.quota_assicurazione
          ? guest.quota_assicurazione
          : 0,
        "fee carta": guest.feeCard ? Math.round(guest.feeCard * Math.pow(10,2)) / Math.pow(10,2) : 0,
        "note quota": note_quota,
      };
    });
    return cardConQuotaAdmin;
  },
  assicurazioniDaRilasciare: (state, getters) => {
    return getters.getAllPaganti.filter(
      (guest) =>
        guest.statoAssicurazione === "da rilasciare" &&
        guest.pagamento_confermato === "pagamento confermato"
    );
  },
  confermatiTab: (state, getters) => {
    function formatDate(data) {
      return new Date(data).toLocaleDateString("it-IT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
    return getters.conferme.map((winner) => {
      let winnerTab = {
        ...winner,
        rinuncia: winner.rinuncia ? "SI" : "NO",
        conferma: winner.viaggio_confermato
          ? formatDate(winner.viaggio_confermato)
          : "",
        pag_ricevuto: winner.conferma_di_pagamento
          ? formatDate(winner.conferma_di_pagamento)
          : winner.quota
          ? "no"
          : "non pagante",
      };
      return winnerTab;
    });
  },
  confermatiConPagamento: (state, getters) => {
    return getters.confermatiTab.filter(
      (winner) =>
        winner.pag_ricevuto &&
        winner.pag_ricevuto !== "no" &&
        winner.pag_ricevuto !== "non pagante"
    ).length;
  },
  confermatiConQuota: (state, getters) => {
    return getters.confermatiTab.filter((winner) => winner.quota > 0).length;
  },
  // confermatiConQuotaCheck: (state, getters) => {
  //   let lista = getters.confermatiTab.filter((winner) => winner.quota > 0);
  //   let mappa = lista.map((el) => el.email);
  //   return mappa;
  // },
  // confermatiConPagamentoCheck: (state, getters) => {
  //   let lista = getters.confermatiTab.filter(
  //     (winner) =>
  //       winner.pag_ricevuto &&
  //       winner.pag_ricevuto !== "no" &&
  //       winner.pag_ricevuto !== "non pagante"
  //   );
  //   let mappa = lista.map((el) => el.email);
  //   return mappa;
  // },
  // difference: (state, getters) => {
  //   let conquota = getters.confermatiConQuotaCheck;
  //   let conpagamento = getters.confermatiConPagamentoCheck;
  //   return conquota.filter((element) => !conpagamento.includes(element));
  // },
  confermeReqDus: (state, getters) => {
    return getters.conferme.filter((winner) => winner.req_dus);
  },
  expectedGuests: (state, getters) => {
    let confermati = getters.conferme;
    let expectedGuests = {
      MXPAExp: 0,
      MXPARea: 0,
      MXPBExp: 0,
      MXPBRea: 0,
      FCOAExp: 0,
      FCOARea: 0,
      FCOBExp: 0,
      FCOBRea: 0,
      MXPAadultA: 0,
      MXPAinfantA: 0,
      MXPBadultA: 0,
      MXPBinfantA: 0,
      MXPAadultR: 0,
      MXPAinfantR: 0,
      MXPBadultR: 0,
      MXPBinfantR: 0,
      FCOAadultA: 0,
      FCOAinfantA: 0,
      FCOBadultA: 0,
      FCOBinfantA: 0,
      FCOAadultR: 0,
      FCOAinfantR: 0,
      FCOBadultR: 0,
      FCOBinfantR: 0,
    };
    confermati.forEach((winner) => {
      if (winner.sigla_aeroporto) {
        let pax_exp = 1 + winner.max_accompagnatori;
        let pax_reali = 1 + winner.lista_acc.length;
        if (
          dayjs(
            winner.turno === "A"
              ? "2024-04-19"
              : winner.turno === "B"
              ? "2024-04-26"
              : "2024-04-22"
          ).diff(dayjs(winner.nascita_data), "year") < 2
        ) {
          expectedGuests[
            `${winner.sigla_aeroporto}${winner.turno}infantA`
          ] += 1;
        } else
          expectedGuests[`${winner.sigla_aeroporto}${winner.turno}adultA`] += 1;
        if (
          dayjs(
            winner.turno === "A"
              ? "2024-04-26"
              : winner.turno === "B"
              ? "2024-05-03"
              : "2024-04-29"
          ).diff(dayjs(winner.nascita_data), "year") < 2
        ) {
          expectedGuests[
            `${winner.sigla_aeroporto}${winner.turno}infantR`
          ] += 1;
        } else
          expectedGuests[`${winner.sigla_aeroporto}${winner.turno}adultR`] += 1;
        if (winner.lista_acc && winner.lista_acc.length > 0) {
          winner.lista_acc.forEach((acc) => {
            if (
              dayjs(
                winner.turno === "A"
                  ? "2024-04-19"
                  : winner.turno === "B"
                  ? "2024-04-26"
                  : "2024-04-22"
              ).diff(dayjs(acc.nascita_data), "year") < 2
            ) {
              expectedGuests[
                `${winner.sigla_aeroporto}${winner.turno}infantA`
              ] += 1;
            } else
              expectedGuests[
                `${winner.sigla_aeroporto}${winner.turno}adultA`
              ] += 1;
            if (
              dayjs(
                winner.turno === "A"
                  ? "2024-04-26"
                  : winner.turno === "B"
                  ? "2024-05-03"
                  : "2024-04-29"
              ).diff(dayjs(acc.nascita_data), "year") < 2
            ) {
              expectedGuests[
                `${winner.sigla_aeroporto}${winner.turno}infantR`
              ] += 1;
            } else
              expectedGuests[
                `${winner.sigla_aeroporto}${winner.turno}adultR`
              ] += 1;
          });
        }
        expectedGuests[`${winner.sigla_aeroporto}${winner.turno}Exp`] +=
          pax_exp;
        expectedGuests[`${winner.sigla_aeroporto}${winner.turno}Rea`] +=
          pax_reali;
      }
    });
    return expectedGuests;
  },
  confermati: (state, getters) => {
    let confermati = getters.conferme;
    return confermati.map((winner) => toConferma(winner));
  },
  confermatiMed: (state, getters) => {
    let confermati = getters.conferme;
    return confermati.map((winner) => toConfermaMed(winner));
  },
  confermatiAges: (state, getters) => {
    let confermati = getters.conferme;
    let limitsA = {
      infant2: "2022-04-19",
      child6: "2018-04-19",
      kid12: "2012-04-19",
      teen18: "2006-04-19",
    };
    let limitsB = {
      infant2: "2022-04-26",
      child6: "2018-04-26",
      kid12: "2012-04-26",
      teen18: "2006-04-26",
    };
    let limitsC = {
      infant2: "2022-04-22",
      child6: "2018-04-22",
      kid12: "2012-04-22",
      teen18: "2006-04-22",
    };
    let agesA = {
      infant2: 0,
      child6: 0,
      kid612: 0,
      teen18: 0,
      adult: 0,
    };
    let agesB = {
      infant2: 0,
      child6: 0,
      kid612: 0,
      teen18: 0,
      adult: 0,
    };
    let agesC = {
      infant2: 0,
      child6: 0,
      kid612: 0,
      teen18: 0,
      adult: 0,
    };

    confermati.forEach((winner) => {
      if (winner.turno === "A") {
        agesA.adult += 1;
        if (winner.lista_acc && winner.lista_acc.length > 0) {
          winner.lista_acc.forEach((acc) => {
            if (acc.nascita_data > limitsA.infant2) {
              agesA.infant2 += 1;
            } else if (acc.nascita_data > limitsA.child6) {
              agesA.child6 += 1;
            } else if (acc.nascita_data > limitsA.kid12) {
              agesA.kid612 += 1;
            } else if (acc.nascita_data > limitsA.teen18) {
              agesA.teen18 += 1;
            } else {
              agesA.adult += 1;
            }
          });
        }
      } else if (winner.turno === "B") {
        agesB.adult += 1;
        if (winner.lista_acc && winner.lista_acc.length > 0) {
          winner.lista_acc.forEach((acc) => {
            if (acc.nascita_data > limitsB.infant2) {
              agesB.infant2 += 1;
            } else if (acc.nascita_data > limitsB.child6) {
              agesB.child6 += 1;
            } else if (acc.nascita_data > limitsB.kid12) {
              agesB.kid612 += 1;
            } else if (acc.nascita_data > limitsB.teen18) {
              agesB.teen18 += 1;
            } else {
              agesB.adult += 1;
            }
          });
        }
      } else if (winner.turno === "C") {
        agesC.adult += 1;
        if (winner.lista_acc && winner.lista_acc.length > 0) {
          winner.lista_acc.forEach((acc) => {
            if (acc.nascita_data > limitsC.infant2) {
              agesC.infant2 += 1;
            } else if (acc.nascita_data > limitsC.child6) {
              agesC.child6 += 1;
            } else if (acc.nascita_data > limitsC.kid12) {
              agesC.kid612 += 1;
            } else if (acc.nascita_data > limitsC.teen18) {
              agesC.teen18 += 1;
            } else {
              agesC.adult += 1;
            }
          });
        }
      }
    });
    return { agesA, agesB, agesC };
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
