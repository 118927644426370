<template>
  <v-menu
    ref="menuTime"
    v-model="menuTime"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="orario"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="orario"
        :label="label"
        outlined
        hide-details
        class="centered-input text-h6 text-typo font-weight-bolder mb-0"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="orario"
      full-width
      format="24hr"
      @click:minute="save"
      :color="color"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "BaseTimePicker",
  data() {
    return {
      menuTime: null,
      orario: this.ora,
    };
  },
  computed: {
  },
  methods: {
    save() {
      this.$refs.menuTime.save(this.orario);
      this.$emit("update", this.orario);
    },
  },
  props: {
    label: {
      type: [String, null],
      default: "Ora",
    },
    ora: {
      type: [String, null],
      default: null,
    },
    color: {
      type: [String, null],
      default: null,
    },
  },
};
</script>
