<template>
  <v-sheet
    :height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
    tile
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      height="100%"
      width="100%"
      color="transparent"
      tile
      class="d-flex flex-column flex-md-row align-center justify-space-between align-md-center justify-md-space-between"
    >
      <v-card
        color="transparent"
        :width="cardWidth"
        :height="cardHeight"
        tile
        :class="classLeft"
        class="d-flex flex-column align-center justify-center"
        ><slot name="left">test card 1</slot></v-card
      >
      <v-card
        color="transparent"
        :width="cardWidth"
        :height="cardHeight"
        tile
        :class="classRight"
        class="d-flex flex-column align-center justify-center"
      >
        <slot name="right">test card 2</slot>
      </v-card>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "BaseSectionHalves",
  computed: {
    cardWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? "50%" : "100%";
    },
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? "100%" : "50%";
    },
    classLeft() {
      return this.reverse ? "order-last order-md-first" : "order-first";
    },
    classRight() {
      return this.reverse ? "order-first order-md-last" : "order-last";
    },
  },
  data: () => ({}),
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
