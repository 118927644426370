import { firestoreAction } from "vuexfire";
import { db, prenotaCena } from "@/firebase/init";
import Swal from "sweetalert2/dist/sweetalert2";
const namespaced = true;
const state = {
  posti: 10,
  alreadySeated: {},
  galaWA: {},
  galaWB: {},
  galaRA: {},
  galaRB: {},
  galaTWA: {},
  galaTWB: {},
  galaLWA: {},
  galaLWB: {},
  bookTableParty: {
    canBook: false,
    list: [],
  },
};
const mutations = {
  INVIA_BOOKTABLE_PARTY(state, payload) {
    state.bookTableParty.canBook = true;
    state.bookTableParty.list = [...payload];
  },
  REFRESH_BOOKTABLE_PARTY(state, payload) {
    state.bookTableParty.list = [...payload];
  },
  RESET_BOOKTABLE_PARTY(state) {
    state.bookTableParty.canBook = false;
    state.bookTableParty.list = [];
  },
};
const actions = {
  inviaBookTable({ commit }, payload) {
    commit("INVIA_BOOKTABLE_PARTY", payload);
  },
  refreshBookTable({ commit }, payload) {
    commit("REFRESH_BOOKTABLE_PARTY", payload);
  },
  resetBookTable({ commit }) {
    commit("RESET_BOOKTABLE_PARTY");
  },
  bindalreadySeated: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "alreadySeated",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("alreadySeated")
    );
  }),
  bindgalaWA: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaWA",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaWA")
    );
  }),
  bindgalaWB: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaWB",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaWB")
    );
  }),
  bindgalaRA: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaRA",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaRA")
    );
  }),
  bindgalaRB: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaRB",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaRB")
    );
  }),
  bindgalaTWA: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaTWA",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaTWA")
    );
  }),
  bindgalaTWB: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaTWB",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaTWB")
    );
  }),
  bindgalaLWA: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaLWA",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaLWA")
    );
  }),
  bindgalaLWB: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "galaLWB",
      db
        .collection("projects")
        .doc("winners24")
        .collection("gala")
        .doc("galaLWB")
    );
  }),
  unbindGala: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("galaWA");
    unbindFirestoreRef("galaRA");
    unbindFirestoreRef("galaWB");
    unbindFirestoreRef("galaRB");
    unbindFirestoreRef("galaTWA");
    unbindFirestoreRef("galaTWB");
    unbindFirestoreRef("galaLWA");
    unbindFirestoreRef("galaLWB");
    unbindFirestoreRef("alreadySeated");
  }),
  createTableList: async (context, payload) => {
    const tableListRef = db
      .collection("projects")
      .doc("winners24")
      .collection("gala")
      .doc(payload.listName);
    await tableListRef.set(payload.tableList);
  },
  createLWA: async (context, payload) => {
    const tableListRef = db
      .collection("projects")
      .doc("winners24")
      .collection("gala")
      .doc("galaLWA");
    await tableListRef.set({ winners: payload });
  },
  createLWB: async (context, payload) => {
    const tableListRef = db
      .collection("projects")
      .doc("winners24")
      .collection("gala")
      .doc("galaLWB");
    await tableListRef.set({ winners: payload });
  },
  async saveTable(context, payload) {
    const t = payload.turno;
    const alreadySeatedList = "list" + t;
    const tableNumber = payload.tableNumber;
    const list = payload.list;
    const winnerEmails = payload.winnerEmails;
    const galaTWRef = db
      .collection("projects")
      .doc("winners24")
      .collection("gala")
      .doc(`galaTW${t}`);
    const alreadySeatedRef = db
      .collection("projects")
      .doc("winners24")
      .collection("gala")
      .doc("alreadySeated");
    const galawRef = db
      .collection("projects")
      .doc("winners24")
      .collection("gala")
      .doc(`galaW${t}`);

    try {
      await db.runTransaction(async (transaction) => {
        const [galaTWDoc, alreadySeatedDoc, galaWDoc] = await Promise.all([
          transaction.get(galaTWRef),
          transaction.get(alreadySeatedRef),
          transaction.get(galawRef),
        ]);

        const galaTWData = galaTWDoc.data();
        const alreadySeatedData = alreadySeatedDoc.data();
        const galaWData = galaWDoc.data();

        winnerEmails.forEach((email, ind) => {
          if (alreadySeatedData[alreadySeatedList].includes(email)) {
            throw ind === 0
              ? "HAI GIÀ UN TAVOLO!"
              : "UNO DEI TUOI OSPITI È GIÀ AD UN TAVOLO!";
          }
        });
        if (galaWData[tableNumber]["seating"].length + list.length > 12) {
          throw "NON CI SONO PIÙ POSTI SUFFICIENTI A QUESTO TAVOLO!";
        }
        // Update the documents based on the payload
        let moodGalaTW = galaTWData["seated"].concat(payload.winnerNames);
        let modAlreadySeated =
          alreadySeatedData[alreadySeatedList].concat(winnerEmails);
        let modGalaWSeat = galaWData[tableNumber]["seating"].concat(list);
        galaWData[tableNumber]["seating"] = modGalaWSeat;
        transaction.update(galaTWRef, { seated: [...moodGalaTW] });
        transaction.update(alreadySeatedRef, {
          [alreadySeatedList]: [...modAlreadySeated],
        });
        transaction.update(galawRef, galaWData);
      });
      Swal.fire({
        icon: "success",
        title: "Salvato!",
        text: `Il tuo tavolo numero ${tableNumber.slice(1)} è stato salvato!`,
        confirmButtonText: "Chiudi",
      });
      context.dispatch("resetBookTable");
      const funcPayload = {
        table: tableNumber,
        emails: winnerEmails,
        project: "winners24",
        sendMail: true,
        subject: "Prenotazione tavolo WHITE DINNER OMAN 2024",
        text: `Il tuo tavolo ${tableNumber} è stato salvato con successo!`,
        html: `<b>White dinner Oman 2024:</b><br>Gentile partecipante, abbiamo riservato per te il tavolo <b>numero ${tableNumber}</b>.<br>Ti ricordiamo il <u>dress code</u> <i>TOTAL WHITE</i> per la serata speciale!<br><br><i>Per qualunque comunicazione, ti preghiamo di non rispondere a questa email, ma di utilizzare esclusivamente l'indirizzo <a href="mailto:viaggi@mediolanum.it?subject=Tavolo%20white%20dinner%20Winners2024">viaggi@mediolanum.it</a></i>`,
      };
      prenotaCena(funcPayload);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
        confirmButtonText: "Chiudi",
      });
    }
  },
};
const getters = {
  canBookNow: (state) => {
    return state.bookTableParty.canBook;
  },
  getBookTableList: (state) => {
    return state.bookTableParty.list;
  },
  getIsReadyToBook: (state) => {
    return state.bookTableParty;
  },
  getPartyToSave: (state, getters) => {
    let winnerEmails = [];
    let winnerNames = [];
    let salvataggio = {};
    getters.getBookTableList.forEach((element) => {
      if (element.type === "win") {
        winnerEmails.push(element.email);
        winnerNames.push({ n: element.nominativo, t: "" });
      }
    });
    if (getters.canBookNow) {
      salvataggio = {
        callerEmail: getters.getBookTableList[0].email,
        callerName: getters.getBookTableList[0].nominativo,
        winnerEmails: winnerEmails,
        winnerNames: winnerNames,
        list: getters.getBookTableList,
      };
    }
    return salvataggio;
  },
  getGalaWA: (state) => {
    return state.galaWA;
  },
  getGalaWB: (state) => {
    return state.galaWB;
  },
  getGalaRA: (state) => {
    return state.galaRA;
  },
  getGalaRB: (state) => {
    return state.galaRB;
  },
  getGalaLWA: (state) => {
    return state.galaLWA.winners;
  },
  getGalaLWB: (state) => {
    return state.galaLWB.winners;
  },
  getGalaTWA: (state) => {
    return state.galaTWA.seated;
  },
  getGalaTWB: (state) => {
    return state.galaTWB.seated;
  },
  getAlreadySeatedA: (state) => {
    return state.alreadySeated.listA;
  },
  getAlreadySeatedB: (state) => {
    return state.alreadySeated.listB;
  },
  getFreeSeatsA: (state) => {
    let tavoli = state.galaWA;
    let freeSeats = {
      free0: [],
      free1: [],
      free2: [],
      free3: [],
      free4: [],
      free5: [],
      free6: [],
      free7: [],
      free8: [],
      free9: [],
      free10: [],
    };
    let tables = Object.keys(tavoli);
    tables.forEach((table) => {
      let freeCount = 10 - tavoli[table].seating.length;
      freeSeats[`free${freeCount}`].push(tavoli[table].idTavolo);
    });
    return freeSeats;
  },
  getFreeSeatsB: (state) => {
    let tavoli = state.galaWB;
    let freeSeats = {
      free0: [],
      free1: [],
      free2: [],
      free3: [],
      free4: [],
      free5: [],
      free6: [],
      free7: [],
      free8: [],
      free9: [],
      free10: [],
    };
    let tables = Object.keys(tavoli);
    tables.forEach((table) => {
      let freeCount = 10 - tavoli[table].seating.length;
      freeSeats[`free${freeCount}`].push(tavoli[table].idTavolo);
    });
    return freeSeats;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
