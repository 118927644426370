<template>
  <v-row no-gutters>
    <v-col>
      <p
        v-if="titolo"
        class="text-sm mb-1 text-capitalize text-body font-weight-bold"
      >
        {{ titolo }}
      </p>
      <v-autocomplete
        v-bind="$attrs"
        v-on="$listeners"
        :label="label"
        :items="allergeni"
        :rules="[
          (v) =>
            !!(v && v.length) ||
            `Scegli 'Nessuna allergia / altro' se non presenti allergie o se fuori da questa lista (in questo caso, usa il campo 'Note')`,
        ]"
        outlined
        clearable
        dense
        :hide-details="hide ? true : bhint ? true : false"
        class="text-h6 text-typo font-weight-bolder mb-0"
      >
        <template #item="data">
          <v-menu
            open-on-hover
            :close-on-click="false"
            max-width="400px"
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-layout wrap v-on="on" v-bind="attrs">
                <v-list-item-action>
                  <v-checkbox v-model="data.attrs.inputValue" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-layout>
            </template>
            <v-card color="secondary">
              <v-card-text>
                <p class="text-h6 white--text">
                  {{ data.item.text }}
                </p>
                <p class="text-body-2 white--text">
                  {{ data.item.descrizione }}
                </p>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-autocomplete>

      <div
        v-if="bhint"
        class="text-end text-capitalize text-xs text-secondary font-weight-light"
      >
        {{ bhint }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import alimentari from "@/mixins/alimentari.js";
export default {
  name: "AutocompleteFieldLineAlimentari",
  mixins: [alimentari],
  props: {
    titolo: {
      type: [String, null],
      default: null,
    },
    label: {
      type: [String, null],
      default: null,
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    bhint: {
      type: [String, null],
      default: null,
    },
    hide: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {},
  mounted() {},
  data: () => ({
    text: "",
  }),
  methods: {
    bubbleUp(data) {
      this.$emit("update", data);
    },
  },
};
</script>
