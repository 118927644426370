function calcDate(titolo) {
  let options = {
    year: "2-digit",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  let called = new Date().toLocaleString("it-IT", options);
  return `WINNERS2024-${titolo} - ` + called;
}
function dynamicSort(property) {
  // ordino in modo decrescente, ho invertito i valori di sortOrder
  var sortOrder = -1;
  if (property[0] === "-") {
    sortOrder = 1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
    return result * sortOrder;
  };
}

function ageClass(born, turno) {
  let limitsA = {
    infant2: "2022-04-19",
    child6: "2018-04-19",
    kid12: "2012-04-19",
    teen18: "2006-04-19",
  };
  let limitsB = {
    infant2: "2022-04-26",
    child6: "2018-04-26",
    kid12: "2012-04-26",
    teen18: "2006-04-26",
  };
  let limitsC = {
    infant2: "2022-04-22",
    child6: "2018-04-22",
    kid12: "2012-04-22",
    teen18: "2006-04-22",
  };
  let limits = turno === "A" ? limitsA : turno === "B" ? limitsB : limitsC;
  let ageClass = "adult";
  if (born > limits.infant2) {
    ageClass = "infant 0 < 2";
  } else if (born > limits.child6) {
    ageClass = "child 2 < 6";
  } else if (born > limits.kid12) {
    ageClass = "kid 6 < 12";
  } else if (born > limits.teen18) {
    ageClass = "teen 12 < 18";
  }
  return ageClass;
}
export { calcDate, dynamicSort, ageClass };
