<template>
  <v-sheet
    :height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
    tile
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-container fluid fill-height class="pa-0">
      <slot />
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "BaseSectionFullHeight",
  computed: {},
  data: () => ({}),
  props: {},
};
</script>
