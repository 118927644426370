<template>
  <v-row no-gutters>
    <v-col>
      <p
        v-if="titolo"
        class="text-sm mb-1 text-capitalize text-body font-weight-bold"
      >
        {{ titolo }}
      </p>
      <base-birth-date-picker
        :label="label"
        :date="date"
        :limited="limited"
        :color="color"
        :min="min"
        :max="max"
        @update="bubbleUp($event)"
      />

      <div
        v-if="bhint"
        class="text-end text-capitalize text-xs text-secondary font-weight-light"
      >
        {{ bhint }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BithDatePickerLine",
  props: {
    titolo: {
      type: [String, null],
      default: null,
    },
    label: {
      type: [String, null],
      default: null,
    },
    date: {
      type: [String, null],
      default: null,
    },
    limited: {
      type: [Boolean],
      default: false,
    },
    min: {
      type: [String, null],
      default: "2024-04-26",
    },
    max: {
      type: [String, null],
      default: "2024-05-03",
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    color: {
      type: [String, null],
      default: null,
    },
    bhint: {
      type: [String, null],
      default: null,
    },
  },
  computed: {},
  data: () => ({}),
  methods: {
    bubbleUp(data) {
      this.$emit("update", data);
    },
  },
};
</script>
