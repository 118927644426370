<template>
  <p
    class="text-sm mb-1 text-capitalize text-body font-weight-bold"
    :class="spacing"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  name: "CardTitolo",
  props: {
    text: {
      type: String,
      default: "",
    },
    spacing: {
      type: String,
      default: "px-4",
    },
  },
};
</script>
