<template>
  <v-menu
    ref="menudata"
    v-model="menudata"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedGiorno"
        outlined
        hide-details
        class="centered-input text-h6 text-typo font-weight-bolder mb-0"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      :color="color"
      v-model="giorno"
      scrollable
      :active-picker.sync="datePicker"
      locale="it-IT"
      :min="limited ? min : null"
      :max="limited ? max : null"
      @input="save"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDateIta } from "@/utilities/converters.js";
export default {
  name: "BirthDatePicker",
  data() {
    return {
      menudata: false,
      giorno: this.date,
      datePicker: null,
    };
  },
  computed: {
    formattedGiorno() {
      return this.giorno ? formatDateIta(this.giorno) : "";
    },
  },
  watch: {
    menudata(val) {
      val && setTimeout(() => (this.datePicker = "YEAR"));
    },
  },
  methods: {
    save() {
      // this.$refs.menudata.save(this.giorno);
      this.menudata = false;
      this.$emit("update", this.giorno);
    },
  },
  props: {
    label: {
      type: [String, null],
      default: "Data",
    },
    date: {
      type: [String, null],
      default: null,
    },
    limited: {
      type: [Boolean],
      default: false,
    },
    min: {
      type: [String, null],
      default: "2024-04-26",
    },
    max: {
      type: [String, null],
      default: "2024-05-03",
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    color: {
      type: [String, null],
      default: "primary",
    },
  },
};
</script>
