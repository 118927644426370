<template>
  <v-overlay opacity="0.8" :value="active">
    <v-progress-circular
      indeterminate
      size="80"
      width="10"
      color="primary"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "Working",
  computed: {
    active() {
      return this.$store.getters["userInterface/isWorking"];
    },
  },
  data: () => ({}),
  props: {
  },
};
</script>
