<template>
  <v-fade-transition mode="out-in">
    <router-view></router-view>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {},

  data: () => ({}),
  computed: {
    ...mapGetters({
      isLogged: "user/isLogged",
    }),
    mainClass() {
      return this.isLogged ? "" : "auth-pages";
    },
  },
  created() {
    this.checkAuth();
    this.readSettings();
    this.readAllowed();
  },
  methods: {
    ...mapActions({
      checkAuth: "user/authAction",
      readSettings: "settings/bindSettings",
      readAllowed: "settings/bindAllowed",
    }),
  },
};
</script>
<style>
.centered-input input {
  text-align: center;
}
.right-input input {
  text-align: right;
  margin-right: 12px;
}
.centered-select .v-select__selection {
  width: 100%;
  justify-content: center;
  text-align: center;
}

</style>
<style lang="scss">
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
.v-input--expand .v-input__slot {
  //justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}
</style>