<template>
  <v-card class="rounded-0">
    <v-app-bar dense dark :class="tableBarColor">
      <v-app-bar-title class="font-weight-light text-h5"
        >{{ title }} - {{ items.length }}</v-app-bar-title
      >
      <v-spacer></v-spacer>
      <div class="first-button-container" v-if="download && ready">
        <xlsx-workbook>
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download :filename="calcDate + '.xlsx'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon large v-on="on" v-bind="attrs">
                  <v-icon size="40" :color="iconColor">mdi-download</v-icon>
                </v-btn>
              </template>
              <span>SCARICA XLSX {{ sottotitolo_scheda }}</span>
            </v-tooltip>
          </xlsx-download>
        </xlsx-workbook>
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        autocomplete="off"
        hide-details
        clearable
        color="white"
        outlined
        dense
        single-line
        placeholder="Cerca"
        v-model="search"
      >
        <template slot="prepend">
          <v-icon color="white" size="30">mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <v-spacer></v-spacer>
      <v-btn outlined tile class="mr-4" color="white" @click="chiudi">
        chiudi
      </v-btn>
    </v-app-bar>
    <v-data-table
      :headers="headers"
      :items="items"
      :dense="dense"
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
      :height="height"
      :search="search"
      fixed-header
      :showGroupBy="showGroupBy"
    >
    </v-data-table>
  </v-card>
</template>
<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { calcDate } from "@/utilities/helpers.js";
export default {
  name: "DatatableFullDwonload",
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    showGroupBy: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tableBarColor: {
      type: String,
      default: "bg-gradient-info",
    },
    ready: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    sheets: {
      type: Array,
      default: () => [
        {
          name: "sheet1",
          data: [],
        },
      ],
    },
  },
  computed: {
    height() {
      const height = "100vh";
      return `calc(${height} - 48px)`;
    },
    calcDate() {
      return calcDate(this.title);
    },
    sottotitolo_scheda() {
      return this.title.toLowerCase().replace(/ /g, "_");
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
    };
  },
  methods: {
    chiudi() {
      this.$emit("chiudi");
    },
  },
};
</script>
<style scoped>
.first-button-container {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 16px; */
}
</style>
