<template>
  <v-card
    class="card-shadow border-radius-xl"
    :class="cardClass"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
    <base-card-bottom :titolo="titolo" :dettagli="dettagli" />
  </v-card>
</template>

<script>
export default {
  name: "BaseInfoCard",
  props: {
    cardClass: {
      type: String,
      default: "",
    },
    titolo: {
      type: String,
      default: "",
    },
    dettagli: {
      type: String,
      default: "",
    },
  },
};
</script>
