const namespaced = true;
const state = {
  logged: false,
  editorNavBarFixed: false,
  isWorking: false,
  dialogPrivacyGrant: false,
};

const mutations = {
  LOGIN: (state) => {
    state.logged = true;
  },
  LOGOUT: (state) => {
    state.logged = false;
  },
  TOGGLE_EDITOR_NAVBAR_FIXED: (state) => {
    state.editorNavBarFixed = !state.editorNavBarFixed;
  },
  SET_IS_WORKING: (state) => {
    state.isWorking = true;
  },
  SET_IS_NOT_WORKING: (state) => {
    state.isWorking = false;
  },
  SET_DIALOG_PRIVACY_GRANT: (state, value) => {
    state.dialogPrivacyGrant = value;
  },
};
const actions = {
  login: ({ commit }) => {
    commit("LOGIN");
  },
  logout: ({ commit }) => {
    commit("LOGOUT");
  },
  toggleEditorNavBarFixed: ({ commit }) => {
    commit("TOGGLE_EDITOR_NAVBAR_FIXED");
  },
  toggleAuth: ({ commit, state }) => {
    if (state.logged) {
      commit("LOGOUT");
    } else {
      commit("LOGIN");
    }
  },
  startWorking: ({ commit }) => {
    commit("SET_IS_WORKING");
  },
  stopWorking: ({ commit }) => {
    commit("SET_IS_NOT_WORKING");
  },
  closeDialogPrivacyGrant: ({ commit }) => {
    commit("SET_DIALOG_PRIVACY_GRANT", false);
  },
  openDialogPrivacyGrant: ({ commit }) => {
    commit("SET_DIALOG_PRIVACY_GRANT", true);
  },
};
const getters = {
  isLogged: (state) => {
    return state.logged;
  },
  editorNavBarFixed: (state) => {
    return state.editorNavBarFixed;
  },
  isWorking: (state) => {
    return state.isWorking;
  },
  dialogPrivacyGrant: (state) => {
    return state.dialogPrivacyGrant;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
