import dayjs from "dayjs";

function creaSiglaDaCodice(stringaDiCifre) {
  let stringaDiLettere = "mw24";
  for (let i = 0; i < stringaDiCifre.length; i++) {
    const cifra = parseInt(stringaDiCifre[i]);
    let lettera = String.fromCharCode(cifra + 105);
    stringaDiLettere += lettera;
  }
  return stringaDiLettere;
}

function formatDateIta(data) {
  if (data === "") return "";
  if (data === "nndd") return "nndd";
  return new Date(data).toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function formatDateEnShort(data) {
  if (data === "") return "";
  if (data === "nndd") return "nndd";
  return new Date(data).toLocaleDateString("en-EN", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
}

function arrToStringa(arr) {
  let stringa = "";
  if (arr.length > 0) {
    arr.forEach((allergia, index) => {
      stringa += allergia;
      if (index !== arr.length - 1) {
        stringa += " ";
      }
    });
  }
  return stringa;
}

function convertWinner(obj) {
  let mancante = "nndd";
  let quota = obj.quota ? Number(obj.quota) : 0;
  let listaccs = obj.lista_acc
    ? obj.lista_acc.filter((acc) => !acc.rinuncia)
    : [];
  let accompagnatori = listaccs.length
    ? obj.lista_acc.map((acc) => convertAcc(acc, obj))
    : [];
  let preferenze_camera = obj.preferenze_camera
    ? obj.preferenze_camera.join(", ")
    : "";
  let sigla = obj.codice_agente
    ? creaSiglaDaCodice(obj.codice_agente)
    : "no-sigla";
  let allergie = obj.alimentari_allergie
    ? obj.alimentari_allergie.join(", ")
    : "";
  let alimentari_preferenze = obj.alimentari_preferenze
    ? obj.alimentari_preferenze
    : "";
  let acc = listaccs.length;
  return {
    codice_agente: obj.codice_agente ? obj.codice_agente : mancante,
    email: obj.email ? obj.email : mancante,
    id: obj.id ? obj.id : obj.email ? obj.email : "no-id",
    type: "winner",
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    genere: obj.genere ? obj.genere : mancante,
    turno: obj.turno ? obj.turno : mancante,
    pax: 1 + acc,
    premio: obj.premio ? obj.premio : "no-premio",
    acc: acc,
    maxAcc: obj.max_accompagnatori ? obj.max_accompagnatori : 0,
    volo_andata: obj.volo_andata ? obj.volo_andata : "",
    volo_ritorno: obj.volo_ritorno ? obj.volo_ritorno : "",
    aeroporto: obj.sigla_aeroporto ? obj.sigla_aeroporto : "",
    regional: obj.regional ? obj.regional : "",
    wa: obj.wa ? obj.wa : "",
    vip: obj.vip ? obj.vip : "",
    hotel: obj.hotel ? obj.hotel : "",
    room_count: obj.room_count ? obj.room_count : "",
    room_number: obj.room_number ? obj.room_number : "",
    room_type: obj.room_type ? obj.room_type : "",
    nascita_data: obj.nascita_data ? obj.nascita_data : mancante,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : mancante,
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : mancante,
    nascita_stato: obj.nascita_stato ? obj.nascita_stato : mancante,
    cellulare: obj.cellulare ? obj.cellulare : mancante,
    doc: obj.doc_tipo ? obj.doc_tipo : mancante,
    doc_stato: obj.doc_stato ? obj.doc_stato : mancante,
    doc_num: obj.doc_numero ? obj.doc_numero : mancante,
    doc_rilas: obj.doc_luogo ? obj.doc_luogo : mancante,
    doc_data_ril: obj.doc_data ? obj.doc_data : mancante,
    doc_scad: obj.doc_scad ? obj.doc_scad : mancante,
    cod_fiscale: obj.codice_fiscale ? obj.codice_fiscale : mancante,
    alimentari_allergie: allergie === "no allergy" ? "" : allergie,
    alimentari_preferenze: alimentari_preferenze,
    alimentari_note: obj.alimentari_note ? obj.alimentari_note : "",
    fatt_indirizzo: obj.fatt_indirizzo ? obj.fatt_indirizzo : mancante,
    fatt_cap: obj.fatt_cap ? obj.fatt_cap : mancante,
    fatt_citta: obj.fatt_citta ? obj.fatt_citta : mancante,
    fatt_prov: obj.fatt_prov ? obj.fatt_prov : mancante,
    fatt_sdi: obj.fatt_sdi ? obj.fatt_sdi : mancante,
    fatt_pec: obj.fatt_pec ? obj.fatt_pec : mancante,
    partita_iva: obj.partita_iva ? obj.partita_iva : mancante,
    lista_acc: accompagnatori,
    gala: obj.galaTable ? obj.galaTable : "",
    agenda: obj.agenda ? obj.agenda : [],
    rinuncia: obj.rinuncia ? obj.rinuncia : "",
    registrato: obj.prima_scelta ? "SI" : "NO",
    conferma: obj.viaggio_confermato
      ? obj.viaggio_confermato
      : "non confermato",
    req_dus: obj.req_dus ? obj.req_dus : "",
    quota: quota,
    quotaAss: obj.quotaAss ? Number(obj.quotaAss) : 0,
    quotaFeeCard: obj.quotaFeeCard ? Number(obj.quotaFeeCard) : 0,
    modificatore_quota: obj.modificatore_quota
      ? Number(obj.modificatore_quota)
      : 0,
    modificatore_quota_out_ass: obj.modificatore_quota_out_ass
      ? Number(obj.modificatore_quota_out_ass)
      : 0,
    noshow: obj.noshow ? obj.noshow : "",
    nogala: obj.nogala ? obj.nogala : "",
    sigla_agente: sigla,
    pagamento: obj.pagamento ? obj.pagamento : "",
    pag_ricevuto: obj.conferma_di_pagamento
      ? obj.conferma_di_pagamento
      : obj.quota
      ? "no"
      : "non pagante",
    preferenze_camera: preferenze_camera,
    note: obj.note ? obj.note : "",
    remarks: obj.remarks ? obj.remarks : "",
    note_mediolanum: obj.note_mediolanum ? obj.note_mediolanum : "",
    check_in: obj.check_in ? obj.check_in : "",
    check_out: obj.check_out ? obj.check_out : "",
    nights: obj.nights ? obj.nights : "",
    ageA: obj.nascita_data
      ? dayjs(
          obj.turno === "A"
            ? "2024-04-19"
            : obj.turno === "B"
            ? "2024-04-26"
            : "2024-04-22"
        ).diff(obj.nascita_data, "year")
      : "nn",
    ageR: obj.nascita_data
      ? dayjs(
          obj.turno === "A"
            ? "2024-04-26"
            : obj.turno === "B"
            ? "2024-05-03"
            : "2024-04-29"
        ).diff(obj.nascita_data, "year")
      : "nn",
  };
}
function convertAcc(obj, win) {
  let hotel = obj.hotel ? obj.hotel : win.hotel ? win.hotel : "";
  let room_type = obj.room_type
    ? obj.room_type
    : win.room_type
    ? win.room_type
    : "";
  let room_number = obj.room_number
    ? obj.room_number
    : win.room_number
    ? win.room_number
    : "";
  let volo_andata = obj.volo_andata
    ? obj.volo_andata
    : win.volo_A
    ? win.volo_A
    : win.volo_andata
    ? win.volo_andata
    : "";
  let volo_ritorno = obj.volo_ritorno
    ? obj.volo_ritorno
    : win.volo_R
    ? win.volo_R
    : win.volo_ritorno
    ? win.volo_ritorno
    : "";
  let turno = obj.turno ? obj.turno : win.turno ? win.turno : "";
  let mancante = "acc-nndd";
  let bloccante = "acc-blocc";
  let allergie = obj.alimentari_allergie
    ? obj.alimentari_allergie.join(", ")
    : [];
  let alimentari_preferenze = obj.alimentari_preferenze
    ? obj.alimentari_preferenze
    : [];
  return {
    id: obj.id ? obj.id : "no-id",
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    genere: obj.genere ? obj.genere : mancante,
    nascita_data: obj.nascita_data ? obj.nascita_data : bloccante,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : mancante,
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : mancante,
    nascita_stato: obj.nascita_stato ? obj.nascita_stato : mancante,
    cod_fiscale: obj.codice_fiscale ? obj.codice_fiscale : mancante,
    email: obj.email ? obj.email : win.email ? win.email : mancante,
    doc: obj.doc_tipo ? obj.doc_tipo : mancante,
    doc_stato: obj.doc_stato ? obj.doc_stato : mancante,
    doc_num: obj.doc_numero ? obj.doc_numero : mancante,
    doc_rilas: obj.doc_luogo ? obj.doc_luogo : mancante,
    doc_data_ril: obj.doc_data ? obj.doc_data : mancante,
    doc_scad: obj.doc_scad ? obj.doc_scad : mancante,
    cellulare: obj.cellulare
      ? obj.cellulare
      : win.cellulare
      ? win.cellulare
      : mancante,
    alimentari_allergie: allergie === "no allergy" ? "" : allergie,
    alimentari_preferenze: alimentari_preferenze,
    alimentari_note: obj.alimentari_note ? obj.alimentari_note : "",
    regional: obj.regional ? obj.regional : win.regional ? win.regional : "",
    aeroporto: win.sigla_aeroporto ? win.sigla_aeroporto : "",
    req_dus: win.req_dus ? win.req_dus : "",
    note: obj.note ? obj.note : "",
    remarks: obj.remarks ? obj.remarks : "",
    turno: turno,
    hotel: hotel,
    room_type: room_type,
    room_number: room_number,
    gala: obj.galaTable ? obj.galaTable : win.galaTable ? win.galaTable : "",
    rinuncia: obj.rinuncia ? obj.rinuncia : "",
    noshow: obj.noshow ? obj.noshow : "",
    nogala: obj.nogala ? obj.nogala : "",
    volo_andata: volo_andata,
    volo_ritorno: volo_ritorno,
    prima_scelta: obj.prima_scelta ? obj.prima_scelta : false,
    verifica_dati_personali: obj.verifica_dati_personali
      ? obj.verifica_dati_personali
      : false,
    business: obj.business ? obj.business : false,
    link_pagamento: obj.link_pagamento ? obj.link_pagamento : false,
    assicurazione: obj.assicurazione ? obj.assicurazione : false,
    imposta_camera: obj.imposta_camera ? obj.imposta_camera : false,
    camera_condivisa: obj.camera_condivisa ? obj.camera_condivisa : "",
    camera_singola: obj.camera_singola ? obj.camera_singola : false,
    ageA: obj.nascita_data
      ? dayjs(
          win.turno === "A"
            ? "2024-04-19"
            : win.turno === "B"
            ? "2024-04-26"
            : "2024-04-22"
        ).diff(dayjs(obj.nascita_data), "year")
      : "nn",
    ageR: obj.nascita_data
      ? dayjs(
          win.turno === "A"
            ? "2024-04-26"
            : win.turno === "B"
            ? "2024-05-03"
            : "2024-04-29"
        ).diff(dayjs(obj.nascita_data), "year")
      : "nn",
    check_in: obj.check_in ? obj.check_in : "",
    check_out: obj.check_out ? obj.check_out : "",
    nights: obj.nights ? obj.nights : "",
  };
}
function toStdPlus(obj) {
  let acc = obj.lista_acc.filter((acc) => !acc.rinuncia).length;
  return {
    email: obj.email,
    codice_agente: obj.codice_agente,
    nome: obj.nome,
    cognome: obj.cognome,
    premio: obj.premio,
    pax: 1 + acc,
    quota:
      obj.quota +
      obj.quotaAss +
      obj.quotaFeeCard +
      obj.modificatore_quota +
      obj.modificatore_quota_out_ass,
    turno: obj.turno,
    note: obj.note_mediolanum ? obj.note_mediolanum : "",
    conferma: obj.conferma
      ? obj.conferma === "non confermato"
        ? obj.conferma
        : formatDateIta(obj.conferma)
      : "",
    rinuncia: obj.rinuncia ? formatDateIta(obj.rinuncia) : "",
  };
}
function toGalaSeated(winner) {
  let guests = [];
  guests.push({
    email: winner.email,
    codice_agente: winner.codice_agente,
    nominativo: winner.nome.toUpperCase() + " " + winner.cognome.toUpperCase(),
    nome: winner.nome.toUpperCase(),
    cognome: winner.cognome.toUpperCase(),
    tavolo: winner.gala ? winner.gala.slice(1) : "",
    type: "win",
    age: winner.ageA ? winner.ageA : "",
    allergie: winner.alimentari_allergie,
    preferenze: winner.alimentari_preferenze,
    note: winner.alimentari_note,
    idGuest: winner.email,
  });
  let accompany = winner.lista_acc.filter(
    (acc) => !acc.rinuncia && !acc.nogala
  );
  accompany.forEach((acc) => {
    if (!acc.nogala) {
      guests.push({
        email: winner.email,
        codice_agente: winner.codice_agente,
        nominativo: acc.nome.toUpperCase() + " " + acc.cognome.toUpperCase(),
        nome: acc.nome.toUpperCase(),
        cognome: acc.cognome.toUpperCase(),
        tavolo: acc.gala
          ? acc.gala.slice(1)
          : winner.gala
          ? winner.gala.slice(1)
          : "",
        type: "acc",
        age: acc.ageA,
        allergie: acc.alimentari_allergie,
        preferenze: acc.alimentari_preferenze,
        note: acc.alimentari_note,
        idGuest: acc.id ? acc.id : "",
      });
    }
  });
  return guests;
}

function toGalaList(winner) {
  let guests = [];
  guests.push({
    idGuest: winner.email,
    email: winner.email,
    nominativo: winner.nome.toUpperCase() + " " + winner.cognome.toUpperCase(),
    type: "win",
    age: winner.ageA ? winner.ageA : "",
    allergie: winner.alimentari_allergie,
    preferenze: winner.alimentari_preferenze,
    note: winner.alimentari_note,
  });
  let accompany = winner.lista_acc.filter((acc) => !acc.rinuncia);
  accompany.forEach((acc) => {
    guests.push({
      idGuest: acc.id ? acc.id : "",
      email: winner.email,
      nominativo: acc.nome.toUpperCase() + " " + acc.cognome.toUpperCase(),
      type: "acc",
      age: acc.ageA,
      allergie: acc.alimentari_allergie,
      preferenze: acc.alimentari_preferenze,
      note: acc.alimentari_note,
    });
  });
  return guests;
}

function toGuest(obj) {
  let regionals = [
    "claudio.gilioli@bancamediolanum.it",
    "antonio.cibin@bancamediolanum.it",
    "pierpaolo.zoppi@bancamediolanum.it",
    "costante.turchi@bancamediolanum.it",
    "luca.testolina@bancamediolanum.it",
    "ciro.dellavalle@bancamediolanum.it",
    "valter.schneck@bancamediolanum.it",
    "ugo.lombardi@bancamediolanum.it",
    "nicola.vallardi@bancamediolanum.it",
    "claudio.licheri@bancamediolanum.it",
  ];
  return {
    turno: obj.turno,
    email: obj.email,
    id: obj.id ? obj.id : "",
    type:
      obj.email === obj.id
        ? regionals.includes(obj.email)
          ? "Regional"
          : obj.wa
          ? "Wealth Advisor"
          : "Family Banker"
        : "Accompagnatore",
    nome: obj.nome,
    cognome: obj.cognome,
    nascita_data: formatDateIta(obj.nascita_data),
    ageA: obj.ageA,
    ageR: obj.ageR,
    nascita_stato: obj.nascita_stato,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : "",
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : "",
    doc_stato: obj.doc_stato,
    doc_scad: formatDateIta(obj.doc_scad),
    cellulare: obj.cellulare,
    regional: obj.regional,
    aeroporto: obj.aeroporto,
    req_dus: obj.req_dus,
    note: obj.note,
    noshow: obj.noshow ? obj.noshow : "",
    nogala: obj.nogala ? obj.nogala : "",
  };
}

function toRoomingWorkFamily(winner) {
  let regionals = [
    "claudio.gilioli@bancamediolanum.it",
    "antonio.cibin@bancamediolanum.it",
    "pierpaolo.zoppi@bancamediolanum.it",
    "costante.turchi@bancamediolanum.it",
    "luca.testolina@bancamediolanum.it",
    "ciro.dellavalle@bancamediolanum.it",
    "valter.schneck@bancamediolanum.it",
    "ugo.lombardi@bancamediolanum.it",
    "nicola.vallardi@bancamediolanum.it",
    "claudio.licheri@bancamediolanum.it",
  ];
  let acc_adult = 0;
  let acc_kid = 0;
  let acc_child = 0;
  let acc_infant = 0;
  if (winner.lista_acc.length > 0) {
    winner.lista_acc.forEach((acc) => {
      if (acc.ageA >= 12) {
        acc_adult += 1;
      } else if (acc.ageA >= 6) {
        acc_kid += 1;
      } else if (acc.ageA >= 2) {
        acc_child += 1;
      } else {
        acc_infant += 1;
      }
    });
  }
  return {
    turno: winner.turno,
    email: winner.email,
    code: winner.codice_agente,
    title: regionals.includes(winner.email)
      ? "Regional"
      : winner.wa
      ? "Wealth Advisor"
      : "Family Banker",
    regional: winner.regional,
    nome: winner.nome,
    cognome: winner.cognome,
    pax: 1 + winner.lista_acc.length,
    adults: acc_adult + 1,
    kids: acc_kid,
    children: acc_child,
    infants: acc_infant,
    hotel: winner.hotel ? winner.hotel : "",
    room_count: winner.room_count ? winner.room_count : 0,
    room_number: winner.room_number ? winner.room_number : "",
    room_type: winner.room_type ? winner.room_type : "",
    note: winner.note ? winner.note : "",
    remarks: winner.remarks ? winner.remarks : "",
  };
}
function toRoomingAll(winner) {
  let regionals = [
    "claudio.gilioli@bancamediolanum.it",
    "antonio.cibin@bancamediolanum.it",
    "pierpaolo.zoppi@bancamediolanum.it",
    "costante.turchi@bancamediolanum.it",
    "luca.testolina@bancamediolanum.it",
    "ciro.dellavalle@bancamediolanum.it",
    "valter.schneck@bancamediolanum.it",
    "ugo.lombardi@bancamediolanum.it",
    "nicola.vallardi@bancamediolanum.it",
    "claudio.licheri@bancamediolanum.it",
  ];
  let people = [];
  let listaccs = winner.lista_acc.filter((acc) => !acc.rinuncia);
  people.push({
    room_count: winner.room_count ? winner.room_count : 0,
    family_code: winner.codice_agente,
    title: regionals.includes(winner.email)
      ? "Regional"
      : winner.wa
      ? "Wealth Advisor"
      : "Family Banker",
    regional: winner.regional,
    last_name: winner.cognome,
    first_name: winner.nome,
    pax: 1 + winner.listaccs.length,
    hotel: winner.hotel ? winner.hotel : "",
    room_type: winner.room_type ? winner.room_type : "",
    room_number: winner.room_number ? winner.room_number : "",
    age: winner.ageA,
    age_class: "adult",
    dus: winner.dus ? winner.dus : winner.req_dus ? "richiesta" : "",
    remarks: winner.remarks ? winner.remarks : "",
    check_in: winner.check_in
      ? winner.check_in
      : winner.turno === "A"
      ? "2024-04-19"
      : winner.turno === "B"
      ? "2024-04-26"
      : "2024-04-23",
    check_out: winner.check_out
      ? winner.check_out
      : winner.turno === "A"
      ? "2024-04-26"
      : winner.turno === "B"
      ? "2024-05-03"
      : "2024-04-29",
    nights: winner.nights ? winner.nights : 7,
    arrival_flight: winner.volo_andata ? winner.volo_andata : "",
    ETA_at_hotel: winner.ETA_at_hotel ? winner.ETA_at_hotel : "",
    departure_flight: winner.volo_ritorno ? winner.volo_ritorno : "",
    EDT_from_hotel: winner.EDT_from_hotel ? winner.EDT_from_hotel : "",
    dietary_needs: winner.alimentari_note ? winner.alimentari_note : "",
    dietary_preferences: winner.alimentari_preferenze,
    allergies:
      winner.alimentari_allergie === "no allergy"
        ? ""
        : winner.alimentari_allergie,
    preferenze_camera: winner.preferenze_camera ? winner.preferenze_camera : "",
    note: winner.note ? winner.note : "",
    note_mediolanum: winner.note_mediolanum ? winner.note_mediolanum : "",
    turno: winner.turno,
    email: winner.email,
    id: winner.email,
  });
  if (listaccs.length > 0) {
    listaccs.forEach((acc, index) => {
      people.push({
        room_count: "",
        family_code: winner.codice_agente,
        title: "Accompany " + (index + 1),
        regional: winner.regional,
        last_name: acc.cognome,
        first_name: acc.nome,
        pax: "",
        hotel: acc.hotel ? acc.hotel : winner.hotel ? winner.hotel : "",
        room_type: acc.room_type
          ? acc.room_type
          : winner.room_type
          ? winner.room_type
          : "",
        room_number: acc.room_number
          ? acc.room_number
          : winner.room_number
          ? winner.room_number
          : "",
        age: acc.ageA,
        age_class:
          acc.ageA >= 12
            ? "adult"
            : acc.ageA >= 6
            ? "kid"
            : acc.ageA >= 2
            ? "child"
            : "infant",
        dus: acc.dus ? acc.dus : "",
        remarks: acc.remarks ? acc.remarks : "",
        check_in: acc.check_in
          ? acc.check_in
          : winner.check_in
          ? winner.check_in
          : winner.turno === "A"
          ? "2024-04-19"
          : winner.turno === "B"
          ? "2024-04-26"
          : "2024-04-23",
        check_out: acc.check_out
          ? acc.check_out
          : winner.check_out
          ? winner.check_out
          : winner.turno === "A"
          ? "2024-04-26"
          : winner.turno === "B"
          ? "2024-05-03"
          : "2024-04-29",
        nights: acc.nights ? acc.nights : winner.nights ? winner.nights : 7,
        arrival_flight: acc.volo_andata
          ? acc.volo_andata
          : winner.volo_andata
          ? winner.volo_andata
          : "",
        ETA_at_hotel: acc.ETA_at_hotel
          ? acc.ETA_at_hotel
          : winner.ETA_at_hotel
          ? winner.ETA_at_hotel
          : "",
        departure_flight: acc.volo_ritorno
          ? acc.volo_ritorno
          : winner.volo_ritorno
          ? winner.volo_ritorno
          : "",
        EDT_from_hotel: acc.EDT_from_hotel
          ? acc.EDT_from_hotel
          : winner.EDT_from_hotel
          ? winner.EDT_from_hotel
          : "",
        dietary_needs: acc.alimentari_note ? acc.alimentari_note : "",
        dietary_preferences: acc.alimentari_preferenze,
        allergies:
          acc.alimentari_allergie === "no allergy"
            ? ""
            : acc.alimentari_allergie,
        preferenze_camera: "",
        note: acc.note ? acc.note : "",
        note_mediolanum: acc.note_mediolanum ? acc.note_mediolanum : "",
        turno: winner.turno,
        email: winner.email,
        id: acc.id,
      });
    });
  }
  return people;
}
function toRoomingAllFlights(winner, flights) {
  let regionals = [
    "claudio.gilioli@bancamediolanum.it",
    "antonio.cibin@bancamediolanum.it",
    "pierpaolo.zoppi@bancamediolanum.it",
    "costante.turchi@bancamediolanum.it",
    "luca.testolina@bancamediolanum.it",
    "ciro.dellavalle@bancamediolanum.it",
    "valter.schneck@bancamediolanum.it",
    "ugo.lombardi@bancamediolanum.it",
    "nicola.vallardi@bancamediolanum.it",
    "claudio.licheri@bancamediolanum.it",
  ];
  let listaccs = winner.lista_acc.filter((acc) => !acc.rinuncia);
  let people = [];
  let winVoloAndata = {
    cod_volo: "",
    orig: "",
    dest: "",
    airline: "",
    convoc_apt: "",
    hr_part: "",
    hr_arr: "",
    eta_htl: "",
    data: "",
    pickup_htl: "",
    endTime: "",
    endDate: "",
  };
  let winVoloRitorno = {
    cod_volo: "",
    orig: "",
    dest: "",
    airline: "",
    convoc_apt: "",
    hr_part: "",
    hr_arr: "",
    eta_htl: "",
    data: "",
    pickup_htl: "",
    endTime: "",
    endDate: "",
  };
  if (winner.volo_andata) {
    if (flights[winner.volo_andata] !== undefined) {
      winVoloAndata = flights[winner.volo_andata];
    } else winVoloAndata.cod_volo = winner.volo_andata;
  } else if (winner.aeroporto === "MXP") {
    winVoloAndata = flights[`NO9532${winner.turno}`];
  } else if (winner.aeroporto === "FCO") {
    winVoloAndata = flights[`QS4397${winner.turno}`];
  }
  if (winner.volo_ritorno) {
    if (flights[winner.volo_ritorno] !== undefined) {
      winVoloRitorno = flights[winner.volo_ritorno];
    } else winVoloRitorno.cod_volo = winner.volo_ritorno;
  } else if (winner.aeroporto === "MXP") {
    winVoloRitorno = flights[`NO9533${winner.turno}`];
  } else if (winner.aeroporto === "FCO") {
    winVoloRitorno = flights[`QS4398${winner.turno}`];
  }
  people.push({
    room_count: winner.room_count ? winner.room_count : 0,
    family_code: winner.codice_agente,
    title: regionals.includes(winner.email)
      ? "Regional"
      : winner.wa
      ? "Wealth Advisor"
      : "Family Banker",
    regional: winner.regional,
    last_name: winner.cognome,
    first_name: winner.nome,
    pax: 1 + listaccs.length,
    hotel: winner.hotel ? winner.hotel : "",
    room_type: winner.room_type ? winner.room_type : "",
    room_number: winner.room_number ? winner.room_number : "",
    age: winner.ageA,
    age_class: "adult",
    dus: winner.dus ? winner.dus : winner.req_dus ? "richiesta" : "",
    remarks: winner.remarks ? winner.remarks : "",
    check_in: winner.check_in
      ? winner.check_in
      : winner.turno === "A"
      ? "2024-04-19"
      : winner.turno === "B"
      ? "2024-04-26"
      : "2024-04-23",
    check_out: winner.check_out
      ? winner.check_out
      : winner.turno === "A"
      ? "2024-04-26"
      : winner.turno === "B"
      ? "2024-05-03"
      : "2024-04-29",
    nights: winner.nights ? winner.nights : 7,
    arrival_flight: winVoloAndata.cod_volo,
    ETA_at_hotel: winner.ETA_at_hotel
      ? winner.ETA_at_hotel
      : winVoloAndata.eta_htl,
    departure_flight: winVoloRitorno.cod_volo,
    EDT_from_hotel: winner.EDT_from_hotel
      ? winner.EDT_from_hotel
      : winVoloRitorno.pickup_htl,
    dietary_needs: winner.alimentari_note ? winner.alimentari_note : "",
    dietary_preferences: winner.alimentari_preferenze,
    allergies:
      winner.alimentari_allergie === "no allergy"
        ? ""
        : winner.alimentari_allergie,
    preferenze_camera: winner.preferenze_camera ? winner.preferenze_camera : "",
    note: winner.note ? winner.note : "",
    note_mediolanum: winner.note_mediolanum ? winner.note_mediolanum : "",
    turno: winner.turno,
    email: winner.email,
    id: winner.email,
  });
  if (listaccs.length > 0) {
    listaccs.forEach((acc, index) => {
      let accVoloAndata = {
        cod_volo: "",
        orig: "",
        dest: "",
        airline: "",
        convoc_apt: "",
        hr_part: "",
        hr_arr: "",
        eta_htl: "",
        data: "",
        pickup_htl: "",
      };
      let accVoloRitorno = {
        cod_volo: "",
        orig: "",
        dest: "",
        airline: "",
        convoc_apt: "",
        hr_part: "",
        hr_arr: "",
        eta_htl: "",
        data: "",
        pickup_htl: "",
      };
      if (acc.volo_andata) {
        if (flights[acc.volo_andata] !== undefined) {
          accVoloAndata = flights[acc.volo_andata];
        } else accVoloAndata.cod_volo = acc.volo_andata;
      } else if (winner.aeroporto === "MXP") {
        accVoloAndata = flights[`NO9532${winner.turno}`];
      } else if (winner.aeroporto === "FCO") {
        accVoloAndata = flights[`QS4397${winner.turno}`];
      }
      if (acc.volo_ritorno) {
        if (flights[acc.volo_ritorno] !== undefined) {
          accVoloRitorno = flights[acc.volo_ritorno];
        } else accVoloRitorno.cod_volo = acc.volo_ritorno;
      } else if (winner.aeroporto === "MXP") {
        accVoloRitorno = flights[`NO9533${winner.turno}`];
      } else if (winner.aeroporto === "FCO") {
        accVoloRitorno = flights[`QS4398${winner.turno}`];
      }
      people.push({
        room_count: "",
        family_code: winner.codice_agente,
        title: "Accompany " + (index + 1),
        regional: winner.regional,
        last_name: acc.cognome,
        first_name: acc.nome,
        pax: "",
        hotel: acc.hotel ? acc.hotel : winner.hotel ? winner.hotel : "",
        room_type: acc.room_type
          ? acc.room_type
          : winner.room_type
          ? winner.room_type
          : "",
        room_number: acc.room_number
          ? acc.room_number
          : winner.room_number
          ? winner.room_number
          : "",
        age: acc.ageA,
        age_class:
          acc.ageA >= 12
            ? "adult"
            : acc.ageA >= 6
            ? "kid"
            : acc.ageA >= 2
            ? "child"
            : "infant",
        dus: acc.dus ? acc.dus : "",
        remarks: acc.remarks ? acc.remarks : "",
        check_in: acc.check_in
          ? acc.check_in
          : winner.check_in
          ? winner.check_in
          : winner.turno === "A"
          ? "2024-04-19"
          : winner.turno === "B"
          ? "2024-04-26"
          : "2024-04-23",
        check_out: acc.check_out
          ? acc.check_out
          : winner.check_out
          ? winner.check_out
          : winner.turno === "A"
          ? "2024-04-26"
          : winner.turno === "B"
          ? "2024-05-03"
          : "2024-04-29",
        nights: acc.nights ? acc.nights : winner.nights ? winner.nights : 7,
        arrival_flight: accVoloAndata.cod_volo,
        ETA_at_hotel: accVoloAndata.eta_htl,
        EDT_from_hotel: accVoloRitorno.pickup_htl,
        departure_flight: accVoloRitorno.cod_volo,
        dietary_needs: acc.alimentari_note ? acc.alimentari_note : "",
        dietary_preferences: acc.alimentari_preferenze,
        allergies:
          acc.alimentari_allergie === "no allergy"
            ? ""
            : acc.alimentari_allergie,
        preferenze_camera: "",
        note: acc.note ? acc.note : "",
        note_mediolanum: acc.note_mediolanum ? acc.note_mediolanum : "",
        turno: winner.turno,
        email: winner.email,
        id: acc.id,
      });
    });
  }
  return people;
}
function toFlightList(winner, flights) {
  let regionals = [
    "claudio.gilioli@bancamediolanum.it",
    "antonio.cibin@bancamediolanum.it",
    "pierpaolo.zoppi@bancamediolanum.it",
    "costante.turchi@bancamediolanum.it",
    "luca.testolina@bancamediolanum.it",
    "ciro.dellavalle@bancamediolanum.it",
    "valter.schneck@bancamediolanum.it",
    "ugo.lombardi@bancamediolanum.it",
    "nicola.vallardi@bancamediolanum.it",
    "claudio.licheri@bancamediolanum.it",
  ];
  let listaccs = winner.lista_acc.filter((acc) => !acc.rinuncia);
  let people = [];
  let winVoloAndata = {
    cod_volo: "",
    orig: "",
    dest: "",
    airline: "",
    convoc_apt: "",
    hr_part: "",
    hr_arr: "",
    eta_htl: "",
    data: "",
    pickup_htl: "",
  };
  let winVoloRitorno = {
    cod_volo: "",
    orig: "",
    dest: "",
    airline: "",
    convoc_apt: "",
    hr_part: "",
    hr_arr: "",
    eta_htl: "",
    data: "",
    pickup_htl: "",
  };
  if (winner.volo_andata) {
    if (flights[winner.volo_andata] !== undefined) {
      winVoloAndata = flights[winner.volo_andata];
    } else winVoloAndata.cod_volo = winner.volo_andata;
  } else if (winner.aeroporto === "MXP") {
    winVoloAndata = flights[`NO9532${winner.turno}`];
  } else if (winner.aeroporto === "FCO") {
    winVoloAndata = flights[`QS4397${winner.turno}`];
  }
  if (winner.volo_ritorno) {
    if (flights[winner.volo_ritorno] !== undefined) {
      winVoloRitorno = flights[winner.volo_ritorno];
    } else winVoloRitorno.cod_volo = winner.volo_ritorno;
  } else if (winner.aeroporto === "MXP") {
    winVoloRitorno = flights[`NO9533${winner.turno}`];
  } else if (winner.aeroporto === "FCO") {
    winVoloRitorno = flights[`QS4398${winner.turno}`];
  }
  people.push({
    family_code: winner.codice_agente,
    class: regionals.includes(winner.email)
      ? "Regional"
      : winner.wa
      ? "Wealth Advisor"
      : "Family Banker",
    title: winner.genere === "M" ? "MR" : "MS",
    last_name: winner.cognome,
    first_name: winner.nome,
    ageA: winner.ageA,
    ageR: winner.ageR,
    hotel: winner.hotel ? winner.hotel : "",
    turno: winner.turno,
    arrival_flight: winVoloAndata.cod_volo,
    departure_flight: winVoloRitorno.cod_volo,
    città: winner.fatt_citta ? winner.fatt_citta : "",
    provincia: winner.fatt_prov ? winner.fatt_prov : "",
    dietary_needs: winner.alimentari_note ? winner.alimentari_note : "",
    dietary_preferences: winner.alimentari_preferenze,
    allergies:
      winner.alimentari_allergie === "no allergy"
        ? ""
        : winner.alimentari_allergie,
    note: winner.note ? winner.note : "",
    note_mediolanum: winner.note_mediolanum ? winner.note_mediolanum : "",
    remarks: winner.remarks ? winner.remarks : "",
    email: winner.email,
    check_in: winner.check_in
      ? winner.check_in
      : winner.turno === "A"
      ? "2024-04-19"
      : winner.turno === "B"
      ? "2024-04-26"
      : "2024-04-23",
    check_out: winner.check_out
      ? winner.check_out
      : winner.turno === "A"
      ? "2024-04-26"
      : winner.turno === "B"
      ? "2024-05-03"
      : "2024-04-29",
  });
  if (listaccs.length > 0) {
    listaccs.forEach((acc, index) => {
      let accVoloAndata = {
        cod_volo: "",
        orig: "",
        dest: "",
        airline: "",
        convoc_apt: "",
        hr_part: "",
        hr_arr: "",
        eta_htl: "",
        data: "",
        pickup_htl: "",
      };
      let accVoloRitorno = {
        cod_volo: "",
        orig: "",
        dest: "",
        airline: "",
        convoc_apt: "",
        hr_part: "",
        hr_arr: "",
        eta_htl: "",
        data: "",
        pickup_htl: "",
      };
      if (acc.volo_andata) {
        if (flights[acc.volo_andata] !== undefined) {
          accVoloAndata = flights[acc.volo_andata];
        } else accVoloAndata.cod_volo = acc.volo_andata;
      } else if (winner.aeroporto === "MXP") {
        accVoloAndata = flights[`NO9532${winner.turno}`];
      } else if (winner.aeroporto === "FCO") {
        accVoloAndata = flights[`QS4397${winner.turno}`];
      }
      if (acc.volo_ritorno) {
        if (flights[acc.volo_ritorno] !== undefined) {
          accVoloRitorno = flights[acc.volo_ritorno];
        } else accVoloRitorno.cod_volo = acc.volo_ritorno;
      } else if (winner.aeroporto === "MXP") {
        accVoloRitorno = flights[`NO9533${winner.turno}`];
      } else if (winner.aeroporto === "FCO") {
        accVoloRitorno = flights[`QS4398${winner.turno}`];
      }
      people.push({
        family_code: winner.codice_agente,
        class: "Accompany " + (index + 1),
        title:
          acc.ageR < 2
            ? "INF"
            : acc.ageR < 12
            ? "CHD"
            : acc.genere === "M"
            ? "MR"
            : "MS",
        last_name: acc.cognome,
        first_name: acc.nome,
        ageA: acc.ageA,
        ageR: acc.ageR,
        hotel: acc.hotel ? acc.hotel : winner.hotel ? winner.hotel : "",
        arrival_flight: accVoloAndata.cod_volo,
        departure_flight: accVoloRitorno.cod_volo,
        città: winner.fatt_citta ? winner.fatt_citta : "",
        provincia: winner.fatt_prov ? winner.fatt_prov : "",
        dietary_needs: acc.alimentari_note ? acc.alimentari_note : "",
        dietary_preferences: acc.alimentari_preferenze,
        allergies:
          acc.alimentari_allergie === "no allergy"
            ? ""
            : acc.alimentari_allergie,
        note: acc.note ? acc.note : "",
        note_mediolanum: acc.note_mediolanum ? acc.note_mediolanum : "",
        remarks: acc.remarks ? acc.remarks : "",
        turno: winner.turno,
        email: winner.email,
        check_in: acc.check_in
          ? acc.check_in
          : winner.check_in
          ? winner.check_in
          : winner.turno === "A"
          ? "2024-04-19"
          : winner.turno === "B"
          ? "2024-04-26"
          : "2024-04-23",
        check_out: acc.check_out
          ? acc.check_out
          : winner.check_out
          ? winner.check_out
          : winner.turno === "A"
          ? "2024-04-26"
          : winner.turno === "B"
          ? "2024-05-03"
          : "2024-04-29",
      });
    });
  }
  return people;
}
function toFlights(winner, flights) {
  let regionals = [
    "claudio.gilioli@bancamediolanum.it",
    "antonio.cibin@bancamediolanum.it",
    "pierpaolo.zoppi@bancamediolanum.it",
    "costante.turchi@bancamediolanum.it",
    "luca.testolina@bancamediolanum.it",
    "ciro.dellavalle@bancamediolanum.it",
    "valter.schneck@bancamediolanum.it",
    "ugo.lombardi@bancamediolanum.it",
    "nicola.vallardi@bancamediolanum.it",
    "claudio.licheri@bancamediolanum.it",
  ];
  let listaccs = winner.lista_acc.filter((acc) => !acc.rinuncia);
  let people = [];
  let winVoloAndata = "";
  let winAndataData = "";
  let winVoloRitorno = "";
  let winRitornoData = "";
  if (winner.volo_andata) {
    if (flights[winner.volo_andata] !== undefined) {
      winVoloAndata = flights[winner.volo_andata].cod_volo;
      winAndataData = flights[winner.volo_andata].data;
    } else winVoloAndata = winner.volo_andata;
  } else if (winner.aeroporto === "MXP") {
    winVoloAndata = "NO9532";
    winAndataData =
      winner.turno === "A"
        ? "2024-04-19"
        : winner.turno === "B"
        ? "2024-04-26"
        : "2024-04-23";
  } else if (winner.aeroporto === "FCO") {
    winVoloAndata = "QS4397";
    winAndataData =
      winner.turno === "A"
        ? "2024-04-19"
        : winner.turno === "B"
        ? "2024-04-26"
        : "2024-04-23";
  }
  if (winner.volo_ritorno) {
    if (flights[winner.volo_ritorno] !== undefined) {
      winVoloRitorno = flights[winner.volo_ritorno].cod_volo;
      winRitornoData = flights[winner.volo_ritorno].data;
    } else winVoloRitorno = winner.volo_ritorno;
  } else if (winner.aeroporto === "MXP") {
    winVoloRitorno = "NO9533";
    winRitornoData =
      winner.turno === "A"
        ? "2024-04-26"
        : winner.turno === "B"
        ? "2024-05-03"
        : "2024-04-29";
  } else if (winner.aeroporto === "FCO") {
    winVoloRitorno = "QS4398";
    winRitornoData =
      winner.turno === "A"
        ? "2024-04-26"
        : winner.turno === "B"
        ? "2024-05-03"
        : "2024-04-29";
  }

  people.push({
    family_code: winner.codice_agente,
    email: winner.email,
    class: regionals.includes(winner.email)
      ? "Regional"
      : winner.wa
      ? "Wealth Advisor"
      : "Family Banker",
    title: winner.genere === "M" ? "MR" : "MS",
    last_name: winner.cognome,
    first_name: winner.nome,
    age: winner.ageR,
    hotel: winner.hotel ? winner.hotel : "",
    doc_type: "Passport",
    doc_number: winner.doc_num,
    doc_nationality: winner.doc_stato,
    doc_release: winner.doc_data_ril,
    doc_expiry: winner.doc_scad,
    birth_date: winner.nascita_data,
    città: winner.fatt_citta ? winner.fatt_citta : "",
    provincia: winner.fatt_prov ? winner.fatt_prov : "",
    turno: winner.turno,
    arrival_flight: winVoloAndata,
    arrival_flight_date: winAndataData,
    departure_flight: winVoloRitorno,
    departure_flight_date: winRitornoData,
    dietary_needs: winner.alimentari_note ? winner.alimentari_note : "",
    dietary_preferences: winner.alimentari_preferenze,
    allergies:
      winner.alimentari_allergie === "no allergy"
        ? ""
        : winner.alimentari_allergie,
    note: winner.note ? winner.note : "",
    note_mediolanum: winner.note_mediolanum ? winner.note_mediolanum : "",
    remarks: winner.remarks ? winner.remarks : "",
    check_in: winner.check_in
      ? winner.check_in
      : winner.turno === "A"
      ? "2024-04-19"
      : winner.turno === "B"
      ? "2024-04-26"
      : "2024-04-23",
    check_out: winner.check_out
      ? winner.check_out
      : winner.turno === "A"
      ? "2024-04-26"
      : winner.turno === "B"
      ? "2024-05-03"
      : "2024-04-29",
  });
  if (listaccs.length > 0) {
    listaccs.forEach((acc, index) => {
      let accVoloAndata = "";
      let accVoloAndataData = "";
      let accVoloRitorno = "";
      let accVoloRitornoData = "";
      if (acc.volo_andata) {
        if (flights[acc.volo_andata] !== undefined) {
          accVoloAndata = flights[acc.volo_andata].cod_volo;
          accVoloAndataData = flights[acc.volo_andata].data;
        } else accVoloAndata = acc.volo_andata;
      } else if (winner.aeroporto === "MXP") {
        accVoloAndata = "NO9532";
        accVoloAndataData =
          winner.turno === "A"
            ? "2024-04-19"
            : winner.turno === "B"
            ? "2024-04-26"
            : "2024-04-23";
      } else if (winner.aeroporto === "FCO") {
        accVoloAndata = "QS4397";
        accVoloAndataData =
          winner.turno === "A"
            ? "2024-04-19"
            : winner.turno === "B"
            ? "2024-04-26"
            : "2024-04-23";
      }
      if (acc.volo_ritorno) {
        if (flights[acc.volo_ritorno] !== undefined) {
          accVoloRitorno = flights[acc.volo_ritorno].cod_volo;
          accVoloRitornoData = flights[acc.volo_ritorno].data;
        } else accVoloRitorno = acc.volo_ritorno;
      } else if (winner.aeroporto === "MXP") {
        accVoloRitorno = "NO9533";
        accVoloRitornoData =
          winner.turno === "A"
            ? "2024-04-26"
            : winner.turno === "B"
            ? "2024-05-03"
            : "2024-04-29";
      } else if (winner.aeroporto === "FCO") {
        accVoloRitorno = "QS4398";
        accVoloRitornoData =
          winner.turno === "A"
            ? "2024-04-26"
            : winner.turno === "B"
            ? "2024-05-03"
            : "2024-04-29";
      }
      people.push({
        family_code: winner.codice_agente,
        email: winner.email,
        class: "Accompany " + (index + 1),
        title:
          acc.ageR < 2
            ? "INF"
            : acc.ageR < 12
            ? "CHD"
            : acc.genere === "M"
            ? "MR"
            : "MS",
        last_name: acc.cognome,
        first_name: acc.nome,
        age: acc.ageR,
        hotel: acc.hotel ? acc.hotel : winner.hotel ? winner.hotel : "",
        doc_type: "Passport",
        doc_number: acc.doc_num,
        doc_nationality: acc.doc_stato,
        doc_release: acc.doc_data_ril,
        doc_expiry: acc.doc_scad,
        birth_date: acc.nascita_data,
        città: winner.fatt_citta ? winner.fatt_citta : "",
        provincia: winner.fatt_prov ? winner.fatt_prov : "",
        arrival_flight: accVoloAndata,
        arrival_flight_date: accVoloAndataData,
        departure_flight: accVoloRitorno,
        departure_flight_date: accVoloRitornoData,
        dietary_needs: acc.alimentari_note ? acc.alimentari_note : "",
        dietary_preferences: acc.alimentari_preferenze,
        allergies:
          acc.alimentari_allergie === "no allergy"
            ? ""
            : acc.alimentari_allergie,
        note: acc.note ? acc.note : "",
        note_mediolanum: acc.note_mediolanum ? acc.note_mediolanum : "",
        remarks: acc.remarks ? acc.remarks : "",
        turno: winner.turno,
        check_in: acc.check_in
          ? acc.check_in
          : winner.check_in
          ? winner.check_in
          : winner.turno === "A"
          ? "2024-04-19"
          : winner.turno === "B"
          ? "2024-04-26"
          : "2024-04-23",
        check_out: acc.check_out
          ? acc.check_out
          : winner.check_out
          ? winner.check_out
          : winner.turno === "A"
          ? "2024-04-26"
          : winner.turno === "B"
          ? "2024-05-03"
          : "2024-04-29",
      });
    });
  }
  return people;
}

function toWinCount(obj) {
  return {
    email: obj.email,
    codice_agente: obj.codice_agente.toString(),
    nome: obj.nome,
    cognome: obj.cognome,
    turno: obj.turno,
    maxAcc: obj.maxAcc,
    premio: obj.premio,
    regional: obj.regional,
    aeroporto: obj.aeroporto,
    req_dus: obj.req_dus,
  };
}
function toWinners(obj) {
  return {
    email: obj.email,
    codice_agente: obj.codice_agente.toString(),
    nome: obj.nome,
    cognome: obj.cognome,
    turno: obj.turno,
    pax: 1 + obj.lista_acc.length,
    premio: obj.premio,
    regional: obj.regional,
    aeroporto: obj.aeroporto,
    conferma: obj.conferma
      ? obj.conferma === "non confermato"
        ? ""
        : formatDateIta(obj.conferma)
      : "",
    rinuncia: obj.rinuncia ? formatDateIta(obj.rinuncia) : "",
  };
}
function toWinTutto(obj) {
  let pref_camera = obj.preferenze_camera;
  let acc_adult = 0;
  let acc_child = 0;
  let acc_infant = 0;
  let listaccs = obj.lista_acc
    ? obj.lista_acc.filter((acc) => !acc.rinuncia)
    : [];
  if (listaccs.length > 0) {
    listaccs.forEach((acc) => {
      if (acc.ageA > 11) {
        acc_adult += 1;
      } else if (acc.ageA > 2) {
        acc_child += 1;
      } else {
        acc_infant += 1;
      }
    });
  }
  return {
    email: obj.email,
    codice_agente: obj.codice_agente.toString(),
    nome: obj.nome,
    cognome: obj.cognome,
    genere: obj.genere,
    turno: obj.turno,
    pax: 1 + listaccs.length,
    acc_adult: acc_adult,
    acc_child: acc_child,
    acc_infant: acc_infant,
    cellulare: obj.cellulare,
    regional: obj.regional,
    aeroporto: obj.aeroporto,
    note: obj.note,
    remarks: obj.remarks,
    note_mediolanum: obj.note_mediolanum,
    dus: obj.dus ? obj.dus : obj.req_dus ? "richiesta" : "",
    pref_camera: pref_camera,
    hotel: obj.hotel ? obj.hotel : "",
    room_number: obj.room_number ? obj.room_number : "",
    room_cat: obj.room_cat ? obj.room_cat : "",
    room_count: obj.room_count ? obj.room_count : "",
    pagamento_ricevuto: obj.pag_ricevuto
      ? (obj.pag_ricevuto === "no") | (obj.pag_ricevuto === "non pagante")
        ? obj.pag_ricevuto
        : formatDateIta(obj.pag_ricevuto)
      : "",
  };
}

function toConferma(obj) {
  let mancante = "nndd";
  let quota = obj.quota ? obj.quota : 0;
  let accompagnatori = obj.lista_acc
    ? obj.lista_acc.length
      ? obj.lista_acc.map((acc) => convertAcc(acc, obj))
      : []
    : [];
  let sigla = obj.codice_agente
    ? creaSiglaDaCodice(obj.codice_agente)
    : "no-sigla";
  let allergie = obj.alimentari_allergie
    ? obj.alimentari_allergie.join(", ")
    : [];
  let alimentari_preferenze = obj.alimentari_preferenze
    ? obj.alimentari_preferenze
    : [];
  let listaccs = obj.lista_acc
    ? obj.lista_acc.filter((acc) => !acc.rinuncia)
    : [];
  let acc = listaccs.length;
  return {
    codice_agente: obj.codice_agente ? obj.codice_agente : mancante,
    email: obj.email ? obj.email : mancante,
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    turno: obj.turno ? obj.turno : mancante,
    pax: 1 + acc,
    premio: obj.premio ? obj.premio : "no-premio",
    acc: acc,
    maxAcc: obj.max_accompagnatori ? obj.max_accompagnatori : 0,
    volo_andata: obj.volo_andata ? obj.volo_andata : "",
    volo_ritorno: obj.volo_ritorno ? obj.volo_ritorno : "",
    regional: obj.regional ? obj.regional : "",
    wa: obj.wa ? obj.wa : "",
    vip: obj.vip ? obj.vip : "",
    hotel: obj.hotel ? obj.hotel : "",
    room_count: obj.room_count ? obj.room_count : "",
    room_number: obj.room_number ? obj.room_number : "",
    room_type: obj.room_type ? obj.room_type : "",
    nascita_data: obj.nascita_data ? obj.nascita_data : mancante,
    nascita_comune: obj.nascita_comune ? obj.nascita_comune : mancante,
    nascita_provincia: obj.nascita_provincia ? obj.nascita_provincia : mancante,
    nascita_stato: obj.nascita_stato ? obj.nascita_stato : mancante,
    cellulare: obj.cellulare ? obj.cellulare : mancante,
    doc: obj.doc_tipo ? obj.doc_tipo : mancante,
    doc_stato: obj.doc_stato ? obj.doc_stato : mancante,
    doc_num: obj.doc_numero ? obj.doc_numero : mancante,
    doc_rilas: obj.doc_luogo ? obj.doc_luogo : mancante,
    doc_data_ril: obj.doc_data ? obj.doc_data : mancante,
    doc_scad: obj.doc_scad ? obj.doc_scad : mancante,
    cod_fiscale: obj.codice_fiscale ? obj.codice_fiscale : mancante,
    alimentari_allergie: allergie,
    alimentari_preferenze: alimentari_preferenze,
    alimentari_note: obj.alimentari_note ? obj.alimentari_note : "",
    fatt_indirizzo: obj.fatt_indirizzo ? obj.fatt_indirizzo : mancante,
    fatt_cap: obj.fatt_cap ? obj.fatt_cap : mancante,
    fatt_citta: obj.fatt_citta ? obj.fatt_citta : mancante,
    fatt_prov: obj.fatt_prov ? obj.fatt_prov : mancante,
    fatt_sdi: obj.fatt_sdi ? obj.fatt_sdi : mancante,
    fatt_pec: obj.fatt_pec ? obj.fatt_pec : mancante,
    partita_iva: obj.partita_iva ? obj.partita_iva : mancante,
    lista_acc: accompagnatori,
    agenda: obj.agenda ? obj.agenda : [],
    rinuncia: obj.rinuncia ? obj.rinuncia : "",
    conferma: obj.viaggio_confermato
      ? formatDateIta(obj.viaggio_confermato)
      : "non confermato",
    quota: quota,
    quotaAss: obj.quotaAss ? obj.quotaAss : 0,
    quotaFeeCard: obj.quotaFeeCard ? obj.quotaFeeCard : 0,
    noshow: obj.noshow ? obj.noshow : "",
    nogala: obj.nogala ? obj.nogala : "",
    sigla_agente: sigla,
    pagamento: obj.pagamento ? obj.pagamento : "",
    pag_ricevuto: obj.conferma_di_pagamento
      ? formatDateIta(obj.conferma_di_pagamento)
      : obj.quota
      ? "no"
      : "non pagante",
    note: obj.note ? obj.note : "",
    note_mediolanum: obj.note_mediolanum ? obj.note_mediolanum : "",
  };
}
function toConfermaMed(obj) {
  let mancante = "nndd";
  let listaccs = obj.lista_acc
    ? obj.lista_acc.filter((acc) => !acc.rinuncia)
    : [];
  let acc = listaccs.length;
  return {
    codice_agente: obj.codice_agente ? obj.codice_agente : mancante,
    email: obj.email ? obj.email : mancante,
    nome: obj.nome ? obj.nome : mancante,
    cognome: obj.cognome ? obj.cognome : mancante,
    turno: obj.turno ? obj.turno : mancante,
    pax: 1 + acc,
    // max_accompagnatori: obj.max_accompagnatori ? obj.max_accompagnatori : 0,
    cellulare: obj.cellulare ? obj.cellulare : mancante,
    regional: obj.regional ? obj.regional : mancante,
    conferma: obj.viaggio_confermato
      ? formatDateIta(obj.viaggio_confermato)
      : mancante,
  };
}

export {
  convertWinner,
  toConferma,
  toWinCount,
  toWinTutto,
  creaSiglaDaCodice,
  formatDateEnShort,
  formatDateIta,
  convertAcc,
  arrToStringa,
  toGuest,
  toConfermaMed,
  toRoomingWorkFamily,
  toRoomingAll,
  toRoomingAllFlights,
  toFlightList,
  toFlights,
  toStdPlus,
  toGalaList,
  toGalaSeated,
  toWinners,
};
