<template>
  <v-row no-gutters>
    <v-col>
      <p
        v-if="titolo"
        class="text-sm mb-1 text-capitalize text-body font-weight-bold"
      >
        {{ titolo }}
      </p>
      <v-textarea
        v-bind="$attrs"
        v-on="$listeners"        
        :ref="reference"
        :label="label"
        outlined
        auto-grow
        :hide-details="hide ? true : bhint ? true : false"
        class="text-h6 text-typo font-weight-bolder mb-0"
      />

      <div
        v-if="bhint"
        class="text-end text-capitalize text-xs text-secondary pr-4"
      >
        {{ bhint }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "TextAreaLine",
  props: {
    titolo: {
      type: [String, null],
      default: null,
    },
    label: {
      type: [String, null],
      default: null,
    },
    field: {
      type: [String],
      default: "",
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    bhint: {
      type: [String, null],
      default: null,
    },
    hide: {
      type: [Boolean],
      default: false,
    },
    reference: {
      type: [String, null],
      default: null,
    },
  },
  computed: {},
  mounted() {
  },
  data: () => ({
    text: "",
  }),
  methods: {
    bubbleUp(data) {
      this.$emit("update", data);
    },
  },
};
</script>
