<template>
  <v-card class="card-shadow border-radius-xl py-12">
    <base-btn secondary @click="gohome">Home</base-btn>
    <base-btn @click="goreport">Reports</base-btn>
    <base-btn @click="gopeople">People</base-btn>
    <base-btn @click="goprofile">Profile</base-btn>
    <base-btn @click="goactivities">Activities</base-btn>
    <base-btn @click="logout">Esci</base-btn>
  </v-card>
</template>

<script>
export default {
  name: "Menutest",
  props: {
    cardClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    gohome() {
      if (this.$route.name != "Login") {
        this.$router.push({ name: "Login" }).catch((err) => {
          console.log(err);
        });
      }
    },
    goreport() {
      if (this.$route.name != "Reports") {
        this.$router.push({ name: "Reports" }).catch((err) => {
          console.log(err);
        });
      }
    },
    goprofile() {
      if (this.$route.name != "Profile") {
        this.$router.push({ name: "Profile" }).catch((err) => {
          console.log(err);
        });
      }
    },
    gopeople() {
      if (this.$route.name != "People") {
        this.$router.push({ name: "People" }).catch((err) => {
          console.log(err);
        });
      }
    },
    goactivities() {
      if (this.$route.name != "Activities") {
        this.$router.push({ name: "Activities" }).catch((err) => {
          console.log(err);
        });
      }
    },
    logout() {
      this.$store.dispatch("user/logout");
      // this.$swal.fire({
      //   title: "In uscita!",
      //   timer: 2000,
      //   timerProgressBar: true,
      // });
    },
  },
};
</script>
