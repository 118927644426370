<template>
  <v-row class="px-4 mt-1">
    <v-col cols="12" class="pt-0">
      <v-divider class="mb-2" />
      <p class="mb-0 text-body text-sm">
        <span class="text-success text-sm font-weight-bolder">
          {{ titolo }}
        </span>
        <span class="font-weight-light ms-1">{{ dettagli }}</span>
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseCardBottom",
  props: {
    titolo: {
      type: String,
      default: "Titolo",
    },
    dettagli: {
      type: String,
      default: "Dettagli",
    },
  },
};
</script>
