import { auth } from "@/firebase/init";
import router from "./../../router";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase/init";
import Swal from "sweetalert2/dist/sweetalert2";
import { dynamicSort } from "@/utilities/helpers";

const namespaced = true;
const state = {
  id: null,
  role: "notLogged",
  email: null,
  isLogged: false,
  personalData: {},
  initial: {},
  accompagnatore: {},
};

const mutations = {
  SET_USER_AUTH: (state, payload) => {
    state.id = payload.id;
    state.role = payload.role;
    state.email = payload.email;
    state.isLogged = payload.isLogged;
  },
  UPDATE_PERSONAL_DATA: (state, payload) => {
    state.personalData = payload;
  },
  SET_NEWS_AS_READ: (state, payload) => {
    if (state.personalData.readNews) {
      state.personalData.readNews.push(payload);
    } else {
      state.personalData.readNews = [payload];
    }
  },
  CONFIRM_PARTY: (state) => {
    state.personalData.confirmed_party = new Date().toISOString();
  },
  SET_INITIAL: (state, payload) => {
    state.initial = JSON.parse(JSON.stringify(payload));
  },
  UPDATE_INITIAL: (state) => {
    state.initial = JSON.parse(JSON.stringify(state.personalData));
  },
  RESET_PERSONAL_DATA: (state) => {
    state.personalData = JSON.parse(JSON.stringify(state.initial));
  },
  SET_PERSONAL_DATA: (state, payload) => {
    payload.lista_acc = payload.lista_acc.sort(dynamicSort("nascita_data"));
    state.personalData = payload;
  },
  SET_ACCOMPAGNATORE: (state, payload) => {
    state.accompagnatore = payload;
  },
  ELIMINA_ACCOMPAGNATORE: (state, payload) => {
    state.personalData.lista_acc.splice(payload, 1);
  },
  TOGGLE_ASSICURAZIONE: (state) => {
    if (state.personalData.assicurazione === undefined)
      state.personalData.assicurazione = true;
    state.personalData.assicurazione = !state.personalData.asscurazione;
  },
  RESET_TESTER: (state) => {
    state.personalData.prima_scelta = false;
    state.personalData.verifica_dati_personali = false;
    state.personalData.confirmed_party = false;
    state.personalData.viaggio_confermato = false;
    state.personalData.agenda = [];
    state.personalData.lista_acc = [];
    state.personalData.assicurazione = false;
    state.personalData.quota = 0;
    state.personalData.quotaAss = 0;
    state.personalData.quotaFeeCard = 0;
    state.personalData.pagamento = null;
    state.personalData.viaggio_confermato = false;
  },
  RESET_CONFIRM_PARTY: (state) => {
    state.personalData.confirmed_party = false;
    state.personalData.viaggio_confermato = false;
    state.personalData.assicurazione = false;
    state.personalData.quota = 0;
    state.personalData.quotaAss = 0;
    state.personalData.quotaFeeCard = 0;
    state.personalData.pagamento = null;
    state.personalData.viaggio_confermato = false;
  },
  RESET_CONFIRM_VIAGGIO: (state) => {
    state.personalData.quota = 0;
    state.personalData.quotaAss = 0;
    state.personalData.quotaFeeCard = 0;
    state.personalData.assicurazione = false;
    state.personalData.pagamento = null;
    state.personalData.viaggio_confermato = false;
  },
  RESET_ACCOMPAGNATORE: (state) => {
    state.accompagnatore = {
      id: null,
      agenda: [],
      titolo: null,
      genere: null,
      nome: null,
      cognome: null,
      codice_fiscale: null,
      parentela: null,
      nascita_stato: null,
      nascita_comune: null,
      nascita_provincia: null,
      nascita_data: null,
      cellulare: null,
      doc_numero: null,
      doc_data: null,
      doc_scad: null,
      business: null,
      hotel: null,
      alimentari_allergie: [],
      alimentari_preferenze: [],
      alimentari_note: "",
      volo: null,
    };
  },
};
const actions = {
  authAction(context) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let role;
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            role = "admin";
          } else if (idTokenResult.claims.editor) {
            role = "editor";
          } else role = "user";
          context.commit("SET_USER_AUTH", {
            id: user.uid,
            email: user.email,
            role: role,
            isLogged: true,
          });
          if (role === "admin" || role === "editor") {
            context.dispatch("bindSuperUserData");
            context.dispatch("participants/bindParticipantsData", null, {
              root: true,
            });
            context.dispatch("participants/bindMorePeople", null, {
              root: true,
            });
            context.dispatch("settings/bindCompanions", null, {
              root: true,
            });
            context.dispatch("agenda/bindEventi", null, {
              root: true,
            });
            context.dispatch("gala/bindgalaWA", null, { root: true });
            context.dispatch("gala/bindgalaWB", null, { root: true });
            context.dispatch("gala/bindgalaRA", null, { root: true });
            context.dispatch("gala/bindgalaRB", null, { root: true });
            context.dispatch("gala/bindgalaTWA", null, { root: true });
            context.dispatch("gala/bindgalaTWB", null, { root: true });
            context.dispatch("gala/bindgalaLWA", null, { root: true });
            context.dispatch("gala/bindgalaLWB", null, { root: true });
            context.dispatch("gala/bindalreadySeated", null, { root: true });
          } else {
            // serve per impostare la data del login
            const usersRef = db
              .collection("projects")
              .doc("winners24")
              .collection("users")
              .doc(context.state.email);
            // Fetch the user's data from Firestore
            usersRef.get().then((doc) => {
              const userData = doc.data();
              // Update the login date
              userData.loginDate = new Date().toISOString();
              // Update the Firestore record
              usersRef.update({
                loginDate: userData.loginDate,
              });
            });
            // Commit the user data to the Vuex store
            context.commit("SET_USER_AUTH", {
              id: user.uid,
              email: user.email,
              role: role,
              isLogged: true,
            });
            context.dispatch("bindUserData");
            context.dispatch("settings/bindCompanions", null, {
              root: true,
            });
            context.dispatch("agenda/bindEventi", null, {
              root: true,
            });
            context.dispatch("gala/bindgalaWA", null, { root: true });
            context.dispatch("gala/bindgalaWB", null, { root: true });
            context.dispatch("gala/bindgalaRA", null, { root: true });
            context.dispatch("gala/bindgalaRB", null, { root: true });
            context.dispatch("gala/bindgalaTWA", null, { root: true });
            context.dispatch("gala/bindgalaTWB", null, { root: true });
            context.dispatch("gala/bindgalaLWA", null, { root: true });
            context.dispatch("gala/bindgalaLWB", null, { root: true });
            context.dispatch("gala/bindalreadySeated", null, { root: true });
          }
        });
      } else {
        context.commit("SET_USER_AUTH", {
          id: null,
          email: null,
          role: "notLogged",
          isLogged: false,
          personalData: {},
        });
        context.dispatch("participants/unbindParticipantsData", null, {
          root: true,
        });
        context.dispatch("participants/unbindMorePeople", null, {
          root: true,
        });
        context.dispatch("agenda/unbindEventi", null, {
          root: true,
        });
        context.dispatch("unbindUserData");
        context.dispatch("unbindSuperUserData");
        context.dispatch("settings/unbindCompanions", null, {
          root: true,
        });
        context.dispatch("gala/unbindGala", null, { root: true });
      }
    });
  },
  setUserAuth: (context, userAuthData) => {
    context.commit("SET_USER_AUTH", userAuthData);
  },
  bindUserData: firestoreAction((context) => {
    return Promise.all([
      context.bindFirestoreRef(
        "personalData",
        db
          .collection("projects")
          .doc("winners24")
          .collection("users")
          .doc(context.state.email)
      ),
      context.bindFirestoreRef(
        "initial",
        db
          .collection("projects")
          .doc("winners24")
          .collection("users")
          .doc(context.state.email)
      ),
    ]);
  }),
  unbindUserData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef(["personalData", "initial"]);
  }),
  bindSuperUserData: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "personalData",
      db
        .collection("projects")
        .doc("winners24")
        .collection("superusers")
        .doc(context.state.email)
    );
  }),
  unbindSuperUserData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("personalData");
  }),
  login: (context, user) => {
    const finance = [
      "fabio.adamo@mutika.it",
      "rita.giaquinta@mutika.it",
      "antonio.dirosa@mutika.it",
      "samuele.scivoletto@mutika.it",
    ];
    return auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredentials) => {
        userCredentials.user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            router.push("/admin/reports");
          } else if (idTokenResult.claims.editor) {
            if (finance.includes(userCredentials.user.email)) {
              router.push("/admin/invialink");
            } else {
              router.push("/admin/reports");
            }
          } else {
            router.push("/user/home");
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            err.code === "auth/wrong-password"
              ? "Password errata"
              : err.code === "auth/too-many-requests"
              ? "Troppi tentativi non riusciti, riprova tra qualche minuto"
              : "Errore generico: " + err.code,
          confirmButtonText: "Riprova",
          footer:
            'Se il problema persiste,&nbsp;<a href="mailto:viaggi@mediolanum.it?subject=Mail%20non%20riconosciuta%20Winners2024">scrivi a viaggi@mediolanum.it</a>',
        });
      });
  },
  logout: () => {
    auth.signOut().then(() => {
      router.push("/");
    });
  },
  setUser: ({ commit }, payload) => {
    commit("SET_PERSONAL_DATA", payload);
  },
  setAccompagnatore: ({ commit }, payload) => {
    commit("SET_ACCOMPAGNATORE", payload);
  },
  resetAccompagnatore: ({ commit }) => {
    commit("RESET_ACCOMPAGNATORE");
  },
  eliminaAccompagnatore: ({ commit, dispatch }, payload) => {
    commit("ELIMINA_ACCOMPAGNATORE", payload);
    dispatch("updateUser");
  },
  inserisciAcc: ({ commit, dispatch, state, rootGetters }) => {
    const accId = db.collection("projects/winners24/users").doc();
    const age_class = state.accompagnatore.nascita_data
      ? rootGetters["settings/getAgeClass"](
          state.personalData.turno,
          state.accompagnatore.nascita_data
        )
      : "no data";
    const acc = {
      ...state.accompagnatore,
      id: accId.id,
      agenda: [],
      age_class: age_class,
    };
    let winner = state.personalData;
    if (winner.lista_acc) {
      winner.lista_acc.push(acc);
    } else winner.lista_acc = [acc];
    commit("SET_PERSONAL_DATA", winner);
    dispatch("updateUser");
    commit("RESET_ACCOMPAGNATORE");
  },
  salvaAccompagnatore: ({ commit, dispatch, state, rootGetters }, payload) => {
    let winner = state.personalData;
    const age_class = state.accompagnatore.nascita_data
      ? rootGetters["settings/getAgeClass"](
          state.personalData.turno,
          state.accompagnatore.nascita_data
        )
      : "no data";
    winner.lista_acc[payload] = {
      ...state.accompagnatore,
      age_class: age_class,
    };
    commit("SET_PERSONAL_DATA", winner);
    dispatch("updateUser");
    commit("RESET_ACCOMPAGNATORE");
  },
  toggleAssicurazione: ({ commit }) => {
    commit("TOGGLE_ASSICURAZIONE");
  },
  resetTester: ({ commit, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    commit("RESET_TESTER");
    dispatch("updateUser");
    dispatch("userInterface/stopWorking", null, { root: true });
  },
  resetConfirmParty: ({ commit, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    commit("RESET_CONFIRM_PARTY");
    dispatch("updateUser");
    dispatch("userInterface/stopWorking", null, { root: true });
  },
  resetConfirmViaggio: ({ commit, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    commit("RESET_CONFIRM_VIAGGIO");
    dispatch("updateUser");
    dispatch("userInterface/stopWorking", null, { root: true });
  },
  resetUser: ({ commit, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    commit("RESET_PERSONAL_DATA");
    dispatch("userInterface/stopWorking", null, { root: true });
  },
  setAsRead: ({ commit, dispatch }, payload) => {
    let updating = {
      id: payload,
      data: new Date().toISOString(),
    };
    commit("SET_NEWS_AS_READ", updating);
    dispatch("updateUser");
  },
  updateUser: async ({ state, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db
        .doc(`projects/winners24/users/${state.email}`)
        .set(state.personalData);
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  confirmParty: ({ commit, dispatch }) => {
    commit("CONFIRM_PARTY");
    dispatch("updateUser");
  },
  forceRemoveFromAgenda: async ({ state, dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    let user = state.personalData;
    if (payload.id === user.email) {
      user.agenda = user.agenda.filter(
        (evento) => evento.id !== payload.evento
      );
    } else {
      user.lista_acc.forEach((acc) => {
        if (acc.id === payload.id) {
          acc.agenda = acc.agenda.filter(
            (evento) => evento.id !== payload.evento
          );
        }
      });
    }
    dispatch("setUser", user);
    dispatch("updateUser");
    try {
      await db.doc(`projects/winners24/users/${state.email}`).set(user);
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  forceClearAgenda: async ({ state, dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    let user = state.personalData;
    if (payload.id === user.email) {
      user.agenda = [];
    } else {
      user.lista_acc.forEach((acc) => {
        if (acc.id === payload.id) {
          acc.agenda = [];
        }
      });
    }
    dispatch("setUser", user);
    dispatch("updateUser");
    try {
      await db.doc(`projects/winners24/users/${state.email}`).set(user);
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
};
// function isObjEmpty(obj) {
//   return Object.keys(obj).length === 0;
// }
const getters = {
  user: (state) => {
    return state.personalData;
  },
  isBoss: (state) => {
    if (state.email === "andrea.disanto@gmail.com") return true;
    return false;
  },
  readNews: (state) => {
    if (state.personalData) {
      if (state.personalData.readNews) {
        let read = [];
        state.personalData.readNews.forEach((news) => {
          read.push(news.id);
        });
        return read;
      } else return [];
    } else return [];
  },
  myTable: (state) => {
    return state.personalData.galaTable ? state.personalData.galaTable : "";
  },
  listaGala: (state, getters) => {
    let user = getters.user;
    let gala = {
      nominativo: "",
      email: "",
      pax: 0,
      lista: [],
    };
    if (Object.keys(user).length === 0) {
      return gala;
    }
    gala.nominativo = (user.nome + " " + user.cognome).toUpperCase();
    gala.email = user.email;
    gala.pax = 1 + user.lista_acc.length;
    gala.lista.push({
      nominativo: (user.nome + " " + user.cognome).toUpperCase(),
      email: user.email,
      type: "win",
      idGuest: user.email,
      note: user.alimentari_note ? user.alimentari_note : "",
      allergie: user.alimentari_allergie
        ? user.alimentari_allergie.join(", ") === "no allergy"
          ? ""
          : user.alimentari_allergie.join(", ")
        : "",
      preferenze: user.alimentari_preferenze ? user.alimentari_preferenze : "",
    });
    user.lista_acc
      .filter((acc) => !acc.rinuncia)
      .forEach((acc) => {
        gala.lista.push({
          nominativo: (acc.nome + " " + acc.cognome).toUpperCase(),
          email: user.email,
          type: "acc",
          idGuest: acc.id,
          note: acc.alimentari_note ? acc.alimentari_note : "",
          allergie: acc.alimentari_allergie
            ? acc.alimentari_allergie.join(", ") === "no allergy"
              ? ""
              : acc.alimentari_allergie.join(", ")
            : "",
          preferenze: acc.alimentari_preferenze
            ? acc.alimentari_preferenze
            : "",
        });
      });
    return gala;
  },
  getGalaGuests: (state, getters) => {
    let galaLista = getters.listaGala;
    if (galaLista.lista === undefined) {
      return [];
    }
    return galaLista.lista;
  },
  newsToRead: (state, getters, rootState, rootGetters) => {
    let news = rootGetters["settings/getNews"];
    let readNews = getters.readNews;
    if (state.personalData) {
      if (news.length === 0) return [];
      return news.filter((news) => !readNews.includes(news.id));
    } else return [];
  },
  winFlights: (state, getters, rootState, rootGetters) => {
    let user = getters.user;
    let flights = rootGetters["settings/getFlights"];
    let voli = [];
    let andata = {};
    let ritorno = {};
    if (Object.keys(user).length === 0) {
      return { andata, ritorno };
    }
    if (user.volo_andata) {
      if (flights[user.volo_andata]) {
        andata = flights[user.volo_andata];
      } else {
        andata = {
          airline: "",
          cod_volo: user.volo_andata,
          convoc_apt: "",
          data: "",
          dest: "",
          eta_htl: "",
          hr_par: "",
          hr_arr: "",
          orig: "",
          pickup_htl: "",
        };
      }
    } else if (user.turno === "C") {
      andata = flights["WY14422"];
    } else {
      if (user.sigla_aeroporto === "MXP") {
        andata = flights[`NO9532${user.turno}`];
      } else if (user.sigla_aeroporto === "FCO") {
        andata = flights[`QS4397${user.turno}`];
      }
    }
    if (user.volo_ritorno) {
      if (flights[user.volo_ritorno]) {
        ritorno = flights[user.volo_ritorno];
      } else {
        ritorno = {
          airline: "",
          cod_volo: user.volo_ritorno,
          convoc_apt: "",
          data: "",
          dest: "",
          eta_htl: "",
          hr_par: "",
          hr_arr: "",
          orig: "",
          pickup_htl: "",
        };
      }
    } else if (user.turno === "C") {
      ritorno = flights["WY14329"];
    } else {
      if (user.sigla_aeroporto === "MXP") {
        ritorno = flights[`NO9533${user.turno}`];
      } else if (user.sigla_aeroporto === "FCO") {
        ritorno = flights[`QS4398${user.turno}`];
      }
    }
    voli.push({
      nome: user.nome + " " + user.cognome,
      andata: andata,
      ritorno: ritorno,
    });
    user.lista_acc.forEach((acc) => {
      let andata = {};
      let ritorno = {};
      if (acc.volo_andata) {
        if (flights[acc.volo_andata]) {
          andata = flights[acc.volo_andata];
        } else {
          andata = {
            airline: "",
            cod_volo: acc.volo_andata,
            convoc_apt: "",
            data: "",
            dest: "",
            eta_htl: "",
            hr_par: "",
            hr_arr: "",
            orig: "",
            pickup_htl: "",
          };
        }
      } else if (user.volo_andata) {
        if (flights[user.volo_andata]) {
          andata = flights[user.volo_andata];
        } else {
          andata = {
            airline: "",
            cod_volo: user.volo_andata,
            convoc_apt: "",
            data: "",
            dest: "",
            eta_htl: "",
            hr_par: "",
            hr_arr: "",
            orig: "",
            pickup_htl: "",
          };
        }
      } else if (user.turno === "C") {
        andata = flights["WY14422"];
      } else {
        if (user.sigla_aeroporto === "MXP") {
          andata = flights[`NO9532${user.turno}`];
        } else if (user.sigla_aeroporto === "FCO") {
          andata = flights[`QS4397${user.turno}`];
        }
      }
      if (acc.volo_ritorno) {
        if (flights[acc.volo_ritorno]) {
          ritorno = flights[acc.volo_ritorno];
        } else {
          ritorno = {
            airline: "",
            cod_volo: acc.volo_ritorno,
            convoc_apt: "",
            data: "",
            dest: "",
            eta_htl: "",
            hr_par: "",
            hr_arr: "",
            orig: "",
            pickup_htl: "",
            endTime: "",
            endDate: "",
          };
        }
      } else if (user.volo_ritorno) {
        if (flights[user.volo_ritorno]) {
          ritorno = flights[user.volo_ritorno];
        } else {
          ritorno = {
            airline: "",
            cod_volo: user.volo_ritorno,
            convoc_apt: "",
            data: "",
            dest: "",
            eta_htl: "",
            hr_par: "",
            hr_arr: "",
            orig: "",
            pickup_htl: "",
            endTime: "",
            endDate: "",
          };
        }
      } else if (user.turno === "C") {
        ritorno = flights["WY14329"];
      } else {
        if (user.sigla_aeroporto === "MXP") {
          ritorno = flights[`NO9533${user.turno}`];
        } else if (user.sigla_aeroporto === "FCO") {
          ritorno = flights[`QS4398${user.turno}`];
        }
      }
      voli.push({
        nome: acc.nome + " " + acc.cognome,
        andata: andata,
        ritorno: ritorno,
      });
    });
    let andataRed = [
      {
        nomi: [voli[0].nome],
        andata: voli[0].andata,
      },
    ];
    let ritornoRed = [
      {
        nomi: [voli[0].nome],
        ritorno: voli[0].ritorno,
      },
    ];
    voli.slice(1).forEach((acc) => {
      let found = false;
      andataRed.forEach((red) => {
        if (red.andata === acc.andata) {
          red.nomi.push(acc.nome);
          found = true;
        }
      });
      if (!found) {
        andataRed.push({
          nomi: [acc.nome],
          andata: acc.andata,
        });
      }
      found = false;
      ritornoRed.forEach((red) => {
        if (red.ritorno === acc.ritorno) {
          red.nomi.push(acc.nome);
          found = true;
        }
      });
      if (!found) {
        ritornoRed.push({
          nomi: [acc.nome],
          ritorno: acc.ritorno,
        });
      }
    });
    return { andataRed, ritornoRed };
  },
  getAccompagnatore: (state) => {
    return state.accompagnatore;
  },
  getParty: (state, getters) => {
    let user = getters.user;
    let party = [];
    if (Object.keys(user).length === 0) {
      return party;
    }
    party.push({
      parentela: "Winner",
      nome: user.nome,
      cognome: user.cognome,
      email: user.email,
      cellulare: user.cellulare,
      codice_agente: user.codice_agente,
      nascita_data: user.nascita_data,
      genere: user.genere,
      agenda: user.agenda,
      iscrizioni: user.agenda.map((evento) => evento.id),
      id: user.email,
      type: "win",
    });
    user.lista_acc.forEach((acc) => {
      party.push({
        parentela: acc.parentela ? acc.parentela : "",
        nome: acc.nome ? acc.nome : "",
        cognome: acc.cognome ? acc.cognome : "",
        email: user.email ? user.email : "",
        cellulare: acc.cellulare ? acc.cellulare : user.cellulare,
        codice_agente: user.codice_agente ? user.codice_agente : "",
        nascita_data: acc.nascita_data ? acc.nascita_data : "",
        genere: acc.genere ? acc.genere : "",
        agenda: acc.agenda,
        iscrizioni: acc.agenda.map((evento) => evento.id),
        id: acc.id,
        type: "acc",
      });
    });
    return party;
  },
  email: (state) => {
    return state.email;
  },
  role: (state) => {
    return state.role;
  },
  isLogged: (state) => {
    return state.isLogged;
  },
  initials: (state) => {
    if (state.personalData) {
      if (state.personalData.nome && state.personalData.cognome) {
        return state.personalData.nome[0] + state.personalData.cognome[0];
      } else return "ND";
    } else return "ND";
  },
  isFinance: (state) => {
    if (state.personalData) {
      if (state.personalData.isFinance || state.role === "admin") {
        return true;
      } else return false;
    } else return false;
  },
  isGiovanni: (state) => {
    if (state.personalData) {
      if (state.personalData.isGiovanni || state.role === "admin") {
        return true;
      } else return false;
    } else return false;
  },
  turno: (state, getters) => {
    const user = getters.user;
    if (Object.keys(user).length === 0) {
      return null;
    }
    return user.turno ? user.turno : null;
  },
  premio: (state, getters) => {
    const user = getters.user;
    if (Object.keys(user).length === 0) {
      return null;
    }
    return user.premio ? user.premio : null;
  },
  assicurazione: (state, getters) => {
    const user = getters.user;
    if (Object.keys(user).length === 0) {
      return false;
    }
    return user.assicurazione ? user.assicurazione : false;
  },
  nominativo: (state) => {
    let nominativo = "";
    if (state.personalData) {
      if (state.personalData.nome) nominativo = state.personalData.nome;
      if (state.personalData.cognome)
        nominativo += " " + state.personalData.cognome;
    }
    if (state.role) {
      if (state.role === "admin") {
        return nominativo ? nominativo : "Admin";
      }
      if (state.role === "editor") {
        return nominativo ? nominativo : "Suoervisor";
      } else {
        return nominativo ? nominativo : "Utente";
      }
    } else return "unknown";
  },
  privacyGranted: (state) => {
    if (state.personalData) {
      if (state.personalData.prima_scelta) {
        return state.personalData.prima_scelta;
      } else return false;
    } else return false;
  },
  verificaDatiWin: (state) => {
    if (state.personalData) {
      if (state.personalData.verifica_dati_personali) {
        return state.personalData.verifica_dati_personali;
      } else return false;
    } else return false;
  },
  confirmedParty: (state) => {
    if (state.personalData) {
      if (state.personalData.confirmed_party) {
        return state.personalData.confirmed_party;
      } else return false;
    } else return false;
  },
  confermaFinale: (state) => {
    if (state.personalData) {
      if (state.personalData.viaggio_confermato) {
        return state.personalData.viaggio_confermato;
      } else return false;
    } else return false;
  },
  canSeeActs: (state, getters) => {
    return (
      getters.privacyGranted &&
      getters.verificaDatiWin &&
      getters.confirmedParty
    );
  },
  quotaPartecipazione: (state, getters, rootState, rootGetters) => {
    function reducer(totale, quota) {
      return totale + quota;
    }
    let quote = rootGetters["settings/getQuote"];
    let user = getters.user;
    let primo = quote.qstandard ? quote.qstandard : 0;
    let quota = 0;
    if (Object.keys(user).length === 0) {
      return quota;
    }
    if (user.premio === "standard + 1") {
      primo = 0;
    }
    let accompagnatori = JSON.parse(JSON.stringify(user.lista_acc));
    if (accompagnatori.length === 0) {
      return 0;
    }
    if (accompagnatori.length === 1) {
      return primo;
    }
    quota = accompagnatori
      .slice(1)
      .map((acc) => {
        return rootGetters["settings/getQuotaViaggio"](
          user.turno,
          acc.nascita_data
        );
      })
      .reduce(reducer);
    quota += primo;
    return quota;
  },
  quotaTotale: (state, getters, rootState, rootGetters) => {
    function reducer(totale, quota) {
      return totale + quota;
    }
    let quote = rootGetters["settings/getQuote"];
    let user = getters.user;
    let qactivities = 0;
    if (user.agenda) {
      user.agenda.forEach((evento) => {
        qactivities += evento.quota;
      });
    }
    if (user.lista_acc.length !== undefined && user.lista_acc.length > 0) {
      user.lista_acc.forEach((acc) => {
        if (acc.agenda) {
          acc.agenda.forEach((evento) => {
            qactivities += evento.quota;
          });
        }
      });
    }
    let primo = quote.qstandard ? quote.qstandard : 0;
    let quota = 0;
    // if (Object.keys(user).length === 0) {
    //   return quota;
    // }
    if (user.premio === "standard + 1") {
      primo = 0;
    }
    let accompagnatori = JSON.parse(JSON.stringify(user.lista_acc));
    if (accompagnatori.length === 0) {
      primo = 0;
    }
    // if (accompagnatori.length === 1) {
    //   return primo;
    // }
    if (accompagnatori.length > 1) {
      quota = accompagnatori
        .slice(1)
        .map((acc) => {
          return rootGetters["settings/getQuotaViaggio"](
            user.turno,
            acc.nascita_data
          );
        })
        .reduce(reducer);
    }
    quota += primo;
    quota += qactivities;
    if (user.modificatore_quota) {
      quota = quota + user.modificatore_quota;
    }
    let quotaAss = 0;
    if (user.assicurazione) {
      quotaAss = (quota * quote.qass) / 100;
    }
    let feeCard = 0;
    if (user.pagamento && user.pagamento === "carta") {
      feeCard = ((quota + quotaAss) * quote.feeCard) / 100;
    }
    if (user.modificatore_quota_out_ass) {
      quota = quota + user.modificatore_quota_out_ass;
    }
    return { quota, quotaAss, feeCard };
  },
  quotaAttivita: (state, getters) => {
    let user = getters.user;
    let quota = 0;
    if (user.agenda === undefined || user.agenda.length === 0) {
      return quota;
    }
    quota = user.agenda
      .map((evento) => {
        return evento.quota;
      })
      .reduce((totale, quota) => {
        return totale + quota;
      });
    return quota;
  },
  getQuotaAdmin: (state, getters, rootState, rootGetters) => (user) => {
    function reducer(totale, quota) {
      return totale + quota;
    }
    let quote = rootGetters["settings/getQuote"];
    let qactivities = 0;
    if (user.agenda) {
      user.agenda.forEach((evento) => {
        qactivities += evento.quota;
      });
    }
    if (user.lista_acc.length !== undefined && user.lista_acc.length > 0) {
      user.lista_acc.forEach((acc) => {
        if (acc.agenda) {
          acc.agenda.forEach((evento) => {
            qactivities += evento.quota;
          });
        }
      });
    }
    let primo = quote.qstandard ? quote.qstandard : 0;
    let quota = 0;
    // if (Object.keys(user).length === 0) {
    //   return quota;
    // }
    if (user.premio === "standard + 1") {
      primo = 0;
    }
    let accompagnatori = JSON.parse(JSON.stringify(user.lista_acc));
    if (accompagnatori.length === 0) {
      primo = 0;
    }
    if (accompagnatori.length > 1) {
      quota = accompagnatori
        .slice(1)
        .map((acc) => {
          return rootGetters["settings/getQuotaViaggio"](
            user.turno,
            acc.nascita_data
          );
        })
        .reduce(reducer);
    }
    quota += primo;
    quota += qactivities;
    if (user.modificatore_quota) {
      quota = quota + user.modificatore_quota;
    }
    let quotaAss = 0;
    if (user.assicurazione) {
      quotaAss = (quota * quote.qass) / 100;
    }
    let feeCard = 0;
    if (user.pagamento && user.pagamento === "carta") {
      feeCard = ((quota + quotaAss) * quote.feeCard) / 100;
    }
    if (user.modificatore_quota_out_ass) {
      quota = quota + user.modificatore_quota_out_ass;
    }
    return quota + quotaAss + feeCard;
  },
  getQuotaAdminSeparata: (state, getters, rootState, rootGetters) => (user) => {
    function reducer(totale, quota) {
      return totale + quota;
    }
    let quote = rootGetters["settings/getQuote"];
    let qactivities = 0;
    if (user.agenda) {
      user.agenda.forEach((evento) => {
        qactivities += evento.quota;
      });
    }
    if (user.lista_acc.length !== undefined && user.lista_acc.length > 0) {
      user.lista_acc.forEach((acc) => {
        if (acc.agenda) {
          acc.agenda.forEach((evento) => {
            qactivities += evento.quota;
          });
        }
      });
    }
    let primo = quote.qstandard ? quote.qstandard : 0;
    let quota = 0;
    if (user.premio === "standard + 1") {
      primo = 0;
    }
    let accompagnatori = JSON.parse(JSON.stringify(user.lista_acc));
    if (accompagnatori.length === 0) {
      primo = 0;
    }
    if (accompagnatori.length > 1) {
      quota = accompagnatori
        .slice(1)
        .map((acc) => {
          return rootGetters["settings/getQuotaViaggio"](
            user.turno,
            acc.nascita_data
          );
        })
        .reduce(reducer);
    }
    quota += primo;
    // quota += qactivities;
    let scontoPreAss = 0;
    let scontoOutAss = 0;
    if (user.modificatore_quota) {
      quota = quota + user.modificatore_quota;
    }
    let quotaAss = 0;
    if (user.assicurazione) {
      quotaAss = ((quota + qactivities) * quote.qass) / 100;
      if (user.modificatore_quota) {
        scontoPreAss = (quote.qass * user.modificatore_quota) / 100;
      }
    }
    let feeCard = 0;
    if (user.pagamento && user.pagamento === "carta") {
      feeCard = ((quota + qactivities + quotaAss) * quote.feeCard) / 100;
    }
    if (user.modificatore_quota_out_ass) {
      quota = quota + user.modificatore_quota_out_ass;
      scontoOutAss = user.modificatore_quota_out_ass;
    }
    let sconti = scontoPreAss + scontoOutAss;
    return {
      totale: quota + qactivities + quotaAss + feeCard,
      qAccompagnatore: quota,
      qActivities: qactivities,
      assicurazione: quotaAss,
      feeCard,
      sconti,
    };
  },
  getQuotaAdminAccompagnatori:
    (state, getters, rootState, rootGetters) => (user) => {
      function reducer(totale, quota) {
        return totale + quota;
      }
      let quote = rootGetters["settings/getQuote"];
      let quotaTour = 0;
      if (user.agenda) {
        user.agenda.forEach((evento) => {
          quotaTour += evento.quota;
        });
      }
      if (user.lista_acc.length !== undefined && user.lista_acc.length > 0) {
        user.lista_acc.forEach((acc) => {
          if (acc.agenda) {
            acc.agenda.forEach((evento) => {
              quotaTour += evento.quota;
            });
          }
        });
      }
      let primo = quote.qstandard ? quote.qstandard : 0;
      let quota = 0;
      if (user.premio === "standard + 1") {
        primo = 0;
      }
      let accompagnatori = JSON.parse(JSON.stringify(user.lista_acc));
      if (accompagnatori.length === 0) {
        primo = 0;
      }
      if (accompagnatori.length > 1) {
        quota = accompagnatori
          .slice(1)
          .map((acc) => {
            return rootGetters["settings/getQuotaViaggio"](
              user.turno,
              acc.nascita_data
            );
          })
          .reduce(reducer);
      }
      quota += primo;
      if (user.modificatore_quota) {
        quota = quota + user.modificatore_quota;
      }
      let quotaAccAss = 0;
      let quotaTourAss = 0;
      if (user.assicurazione) {
        quotaAccAss = (quota * quote.qass) / 100;
        quotaTourAss = (quotaTour * quote.qass) / 100;
      }
      let feeCardAcc = 0;
      let feeCardTour = 0;
      if (user.pagamento && user.pagamento === "carta") {
        feeCardAcc = ((quota + quotaAccAss) * quote.feeCard) / 100;
        feeCardTour = ((quotaTour + quotaTourAss) * quote.feeCard) / 100;
      }
      return {
        quota,
        quotaTour,
        quotaAccAss,
        quotaTourAss,
        feeCardAcc,
        feeCardTour,
      };
    },
  quotaAttivitaAdmin: () => (user) => {
    let quota = 0;
    if (user.agenda === undefined || user.agenda.length === 0) {
      return quota;
    }
    quota = user.agenda
      .map((evento) => {
        return evento.quota;
      })
      .reduce((totale, quota) => {
        return totale + quota;
      });
    return quota;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
