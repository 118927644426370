<template>
  <div>
    <div v-if="editor" class="editor-bar mb-2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
          >
            <v-icon :color="editor.isActive('bold') ? 'white' : ''"
              >mdi-format-bold</v-icon
            >
          </v-btn>
        </template>
        <span>Bold</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
          >
            <v-icon :color="editor.isActive('italic') ? 'white' : ''"
              >mdi-format-italic</v-icon
            >
          </v-btn>
        </template>
        <span>Italic</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }"
          >
            <v-icon :color="editor.isActive('strike') ? 'white' : ''"
              >mdi-format-strikethrough</v-icon
            >
          </v-btn>
        </template>
        <span>Strike</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ 'is-active': editor.isActive('underline') }"
          >
            <v-icon :color="editor.isActive('underline') ? 'white' : ''"
              >mdi-format-underline</v-icon
            >
          </v-btn>
        </template>
        <span>Underline</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-1"
            icon
            @click="editor.chain().focus().toggleCode().run()"
            :class="{ 'is-active': editor.isActive('code') }"
          >
            <v-icon :color="editor.isActive('code') ? 'white' : ''"
              >mdi-code-tags</v-icon
            >
          </v-btn>
        </template>
        <span>Codice</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().unsetAllMarks().run()"
          >
            <v-icon>mdi-format-clear</v-icon>
          </v-btn>
        </template>
        <span>Elimina formattazione</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().setParagraph().run()"
            :class="{ 'is-active': editor.isActive('paragraph') }"
          >
            <v-icon :color="editor.isActive('paragraph') ? 'white' : ''"
              >mdi-format-paragraph</v-icon
            >
          </v-btn>
        </template>
        <span>Paragrafo</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          >
            <v-icon
              :color="editor.isActive('heading', { level: 1 }) ? 'white' : ''"
              >mdi-format-header-1</v-icon
            >
          </v-btn>
        </template>
        <span>Titolo intestazione 1</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          >
            <v-icon
              :color="editor.isActive('heading', { level: 2 }) ? 'white' : ''"
              >mdi-format-header-2</v-icon
            >
          </v-btn>
        </template>
        <span>Titolo intestazione 2</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          >
            <v-icon
              :color="editor.isActive('heading', { level: 3 }) ? 'white' : ''"
              >mdi-format-header-3</v-icon
            >
          </v-btn>
        </template>
        <span>Titolo intestazione 3</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
          >
            <v-icon
              :color="editor.isActive('heading', { level: 4 }) ? 'white' : ''"
              >mdi-format-header-4</v-icon
            >
          </v-btn>
        </template>
        <span>Titolo intestazione 4</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
          >
            <v-icon
              :color="editor.isActive('heading', { level: 5 }) ? 'white' : ''"
              >mdi-format-header-5</v-icon
            >
          </v-btn>
        </template>
        <span>Titolo intestazione 5</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
          >
            <v-icon
              :color="editor.isActive('heading', { level: 6 }) ? 'white' : ''"
              >mdi-format-header-6</v-icon
            >
          </v-btn>
        </template>
        <span>Titolo intestazione 6</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            <v-icon :color="editor.isActive('bulletList') ? 'white' : ''"
              >mdi-format-list-bulleted</v-icon
            >
          </v-btn>
        </template>
        <span>Lista non ordinata</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
          >
            <v-icon :color="editor.isActive('orderedList') ? 'white' : ''"
              >mdi-format-list-numbered</v-icon
            >
          </v-btn>
        </template>
        <span>Lista numerata</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="setLink"
            :class="{ 'is-active': editor.isActive('link') }"
          >
            <v-icon :color="editor.isActive('link') ? 'white' : ''"
              >mdi-link-variant-plus</v-icon
            >
          </v-btn>
        </template>
        <span>Imposta link</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            class="mr-1"
            @click="editor.chain().focus().unsetLink().run()"
            :disabled="!editor.isActive('link')"
          >
            <v-icon :color="editor.isActive('link') ? '' : ''"
              >mdi-link-variant-remove</v-icon
            >
          </v-btn>
        </template>
        <span>Rimuovi link</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleCodeBlock().run()"
            :class="{ 'is-active': editor.isActive('codeBlock') }"
          >
            <v-icon :color="editor.isActive('codeBlock') ? 'white' : ''"
              >mdi-file-code</v-icon
            >
          </v-btn>
        </template>
        <span>Blocco di codice</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().toggleBlockquote().run()"
            :class="{ 'is-active': editor.isActive('blockquote') }"
          >
            <v-icon :color="editor.isActive('blockquote') ? 'white' : ''"
              >mdi-format-quote-open</v-icon
            >
          </v-btn>
        </template>
        <span>Citazione</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().setHorizontalRule().run()"
          >
            <v-icon>mdi-format-page-break</v-icon>
          </v-btn>
        </template>
        <span>Separatore orizzontale</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            icon
            @click="editor.chain().focus().clearNodes().run()"
          >
            <v-icon>mdi-information-off-outline</v-icon>
          </v-btn>
        </template>
        <span>Elimina tags dalla selezione</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-1"
            icon
            @click="editor.chain().focus().setHardBreak().run()"
          >
            <v-icon>mdi-arrow-left-bottom</v-icon>
          </v-btn>
        </template>
        <span>A capo (mantiene il paragrafo)</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().undo().run()"
          >
            <v-icon>mdi-undo</v-icon>
          </v-btn>
        </template>
        <span>Annulla</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="editor.chain().focus().redo().run()"
          >
            <v-icon>mdi-redo</v-icon>
          </v-btn>
        </template>
        <span>Ripristina</span>
      </v-tooltip>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
export default {
  name: "BaseContentEditor",
  components: {
    EditorContent,
  },
  data: () => ({
    editor: null,
  }),
  computed: {
    color() {
      return this.$vuetify.isDark ? "white" : "grey lighten-5";
    },
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);
      // cancelled
      if (url === null) {
        return;
      }
      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
/* Basic editor styles */
.editor-bar {
  button {
    border: 1px solid #ddd !important;
    background: #f5f5f5;
    border-radius: 5px;
  }
  button.is-active {
    border: 1px solid rgb(0, 0, 0) !important;
    background: #1f1f1f;
    border-radius: 5px;
  }
}
.ProseMirror {
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  padding: 21px;
  overflow-y: scroll;
  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
